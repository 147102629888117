import { LoadingButton } from '@mui/lab'
import {
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material'
import { MAX_DESC_NOTES_LENGTH } from 'constants/common'
import { useState } from 'react'

interface ConfirmDialogProps {
  isOpen: boolean
  handleClose: () => void
  title: string
  content?: string | React.ReactNode
  onCancel: () => void
  cancelText?: string
  onConfirm: (note?: string) => void
  confirmText?: string
  confirmType?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
  isLoading?: boolean
  maxWidth?: false | Breakpoint
  showNotes?: boolean
  notesPlaceholder?: string
  dataTestId?: string
}

const ConfirmDialog = ({
  isOpen,
  handleClose,
  title,
  content,
  onCancel,
  cancelText = 'Cancel',
  onConfirm,
  confirmText = 'Confirm',
  confirmType = 'error',
  isLoading = false,
  maxWidth = 'sm',
  showNotes = false,
  notesPlaceholder = 'Add a note',
  dataTestId,
}: ConfirmDialogProps) => {
  const [notes, setNotes] = useState('')

  return (
    <Dialog onClose={handleClose} open={isOpen} fullWidth={true} maxWidth={maxWidth}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent sx={{ px: 3, pb: 1, '&.MuiDialogContent-root': { pt: 1 } }}>
        <Stack gap={3}>
          {content}

          {showNotes ? (
            <TextField
              multiline
              rows={4}
              label='Note (optional)'
              placeholder={notesPlaceholder}
              fullWidth
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              helperText={`${notes.length}/${MAX_DESC_NOTES_LENGTH}`}
              inputProps={{ maxLength: MAX_DESC_NOTES_LENGTH }}
              FormHelperTextProps={{
                sx: { textAlign: 'right' },
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          ) : null}
        </Stack>
      </DialogContent>

      <DialogActions sx={{ px: 3, py: 2 }} data-testid={dataTestId}>
        <Button
          variant='outlined'
          onClick={onCancel}
          disabled={isLoading}
          data-testid='cancel-button'
        >
          {cancelText}
        </Button>
        <LoadingButton
          variant='contained'
          onClick={() => (showNotes ? onConfirm(notes) : onConfirm())}
          color={confirmType}
          loading={isLoading}
          data-testid='confirm-button'
        >
          {confirmText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
export default ConfirmDialog

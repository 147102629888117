import { useMemo, useState } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { ProfitShareHolderForDownload, TokenHolder, UserStatus } from 'types/user'
import StatusChip from 'components/status-chip/StatusChip'
import Avatar from 'components/Avatar'
import { MRT_PaginationState, type MRT_ColumnDef } from 'material-react-table'
import { NumericFormat } from 'react-number-format'
import { useProfitShareHoldersList } from 'services/api/profitShare'
import { useTokenDetails, useAllTokenHoldersList } from 'services/api/token'
import KeeprTable from 'components/KeeprTable'
import { useOutletContext } from 'react-router-dom'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import LoadingButton from '@mui/lab/LoadingButton'
import { convertDataToCSV } from 'utils/csv'
import { downloadFile } from 'utils/file'

const ProfitShareHolders = () => {
  const tokenId = useOutletContext<string>()
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    //material-react-table and tanstack table under the hood use zero based index, our back-end uses 1 based index
    pageIndex: 0,
    pageSize: 10,
  })

  const { data: tokenDetails } = useTokenDetails({ tokenId })

  const { data: tokenHoldersListForDownload, isLoading: isHoldersListLoading } =
    useAllTokenHoldersList({
      tokenId,
    })

  const { data: tokenHolders, isLoading } = useProfitShareHoldersList({
    tokenId,
    pagination: {
      page: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
    },
    options: {
      placeholderData: (previousData) => previousData,
    },
  })

  const data = useMemo(() => tokenHolders?.results, [tokenHolders]) || []

  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'user.full_name',
          header: 'Name',
          size: 250,
          enableSorting: false,
          Cell: ({ cell, row }) => {
            const fullName = cell.getValue<string>()
            return (
              <Stack direction='row' alignItems='center' gap={1}>
                <Avatar name={fullName} src={row.original.user.avatar_thumbnail} size={40} />
                <Stack overflow='hidden'>
                  <Typography variant='body2' noWrap={true}>
                    {fullName}
                  </Typography>
                  <Typography variant='caption' color='text.secondary' noWrap={true}>
                    {row.original.user.email}
                  </Typography>
                </Stack>
              </Stack>
            )
          },
        },
        {
          accessorKey: 'amount',
          header: 'Balance',
          enableSorting: false,
          muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ cell }) => {
            const amount = cell.getValue<string>()
            return <NumericFormat thousandSeparator displayType='text' value={amount} />
          },
        },
        {
          accessorKey: 'user.status',
          header: 'Status',
          enableSorting: false,
          Cell: ({ cell }) => {
            const status = cell.getValue<UserStatus>()
            return <StatusChip status={status} />
          },
        },
        {
          accessorKey: 'user.user_job.company_tier',
          header: 'Organization tier',
          enableSorting: false,
        },
        {
          accessorKey: 'user.user_job.employment_type',
          header: 'Employment type',
          size: 100,
          enableSorting: false,
          muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
            align: 'right',
          },
        },
      ] as MRT_ColumnDef<TokenHolder>[],
    [],
  )

  const downloadHoldersCSV = () => {
    if (tokenHoldersListForDownload) {
      const csvContent =
        convertDataToCSV<ProfitShareHolderForDownload>({
          data: tokenHoldersListForDownload,
          columnNames: ['email', 'status', 'employment_type', 'company_tier', 'amount'],
        }) || ''

      downloadFile({ file: csvContent, name: `${tokenDetails?.name} - holders list` })
    }
  }

  return (
    <Box mt={2}>
      <KeeprTable
        columns={columns}
        data={data}
        onPaginationChange={setPagination}
        rowCount={tokenHolders?.count || 0}
        state={{
          showSkeletons: isLoading,
          showProgressBars: isLoading,
          pagination,
        }}
        renderTopToolbarCustomActions={() => (
          <Box
            display='flex'
            width='100%'
            justifyContent={{ xs: 'center', sm: 'flex-end' }}
            gap={2}
          >
            <LoadingButton
              loading={isHoldersListLoading}
              disabled={!tokenHolders || !tokenHoldersListForDownload}
              onClick={downloadHoldersCSV}
              loadingPosition='start'
              startIcon={<FileDownloadIcon />}
            >
              csv export
            </LoadingButton>
          </Box>
        )}
        muiTableBodyProps={{
          title: 'Asset holders table',
        }}
      />
    </Box>
  )
}

export default ProfitShareHolders

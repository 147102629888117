import { SvgIcon } from '@mui/material'
import { SvgIconProps } from '@mui/material'

const ExercisedOptionsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox='0 0 78 78'>
      <path
        d='M65 62.8334V65.7739L41.4762 73.125L20.8929 67.4205V71.6548H3.25V39.3096H26.6856L44.7989 46.0727C48.0923 47.3077 50.2976 50.4834 50.2976 54.012H56.1786C61.0598 54.012 65 57.9522 65 62.8334ZM15.0119 65.7739V45.1905H9.13095V65.7739H15.0119ZM58.825 61.569C58.3545 60.5986 57.3254 59.9223 56.1786 59.9223H40.447C38.8592 59.9223 37.3007 59.6577 35.8011 59.1578L28.8027 56.8348L30.6552 51.2479L37.6536 53.5709C38.5651 53.8649 44.4167 54.012 44.4167 54.012C44.4167 52.924 43.7404 51.9536 42.7406 51.5714L25.627 45.1905H20.8929V61.3043L41.388 66.9795L58.825 61.569Z'
        fill='currentColor'
      />
      <path
        d='M50.7812 3.25H36.1562C34.1453 3.25 32.5 4.89531 32.5 6.90625V36.1562C32.5 38.1672 34.1453 39.8125 36.1562 39.8125H58.0938C60.1047 39.8125 61.75 38.1672 61.75 36.1562V14.2188L50.7812 3.25ZM36.1562 36.1562V6.90625H48.9531V14.2188H58.0938V36.1562H36.1562ZM45.2969 34.3281H48.9531V32.5H50.7812C51.7867 32.5 52.6094 31.6773 52.6094 30.6719V25.1875C52.6094 24.182 51.7867 23.3594 50.7812 23.3594H45.2969V21.5312H52.6094V17.875H48.9531V16.0469H45.2969V17.875H43.4688C42.4633 17.875 41.6406 18.6977 41.6406 19.7031V25.1875C41.6406 26.193 42.4633 27.0156 43.4688 27.0156H48.9531V28.8438H41.6406V32.5H45.2969V34.3281Z'
        fill='currentColor'
      />
    </SvgIcon>
  )
}

export default ExercisedOptionsIcon

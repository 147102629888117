import { Typography, Button } from '@mui/material'
import StatusChip from 'components/status-chip/StatusChip'
import { MRT_ColumnDef, MRT_PaginationState } from 'material-react-table'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { NumericFormat } from 'react-number-format'
import { CURRENCY_SYMBOL } from 'types/common'
import { PayoutStatus } from 'types/token/profitShare'
import { formatDate } from 'utils/dates'
import { CustomTokenPayout } from 'types/token/customToken'
import KeeprTable from 'components/KeeprTable'
import { useCustomTokenPayoutList } from 'services/api/customToken'

interface IndividualPayoutsTableProps {
  tokenId: string
}

const SelectiveCashSharePayoutsTable = ({ tokenId }: IndividualPayoutsTableProps) => {
  const navigate = useNavigate()

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    //material-react-table and tanstack table under the hood use zero based index, our back-end uses 1 based index
    pageIndex: 0,
    pageSize: 10,
  })
  const { data: payouts, isLoading } = useCustomTokenPayoutList({
    tokenId,
    isPreview: false,
    pagination: {
      page: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
    },
    options: {
      placeholderData: (previousData) => previousData,
    },
  })

  const data = useMemo(() => payouts?.results, [payouts]) || []

  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'name',
          header: 'Title',
          Cell: ({ cell }) => {
            const title = cell.getValue<string>()
            return <Typography variant='body2'>{title}</Typography>
          },
        },
        {
          accessorKey: 'payout_dt',
          header: 'Payout date',
          Cell: ({ cell }) => {
            const payoutDate = cell.getValue<string>()
            return (
              <Typography variant='body2'>{formatDate(payoutDate, 'MMM. dd, yyyy')}</Typography>
            )
          },
        },
        {
          accessorKey: 'status',
          header: 'Status',
          Cell: ({ cell }) => {
            const status = cell.getValue<PayoutStatus>()
            return <StatusChip status={status} />
          },
        },
        {
          accessorKey: 'token_amount',
          header: 'Assets',
          muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ cell }) => {
            const tokenAmount = cell.getValue<number>()
            return <NumericFormat value={tokenAmount} displayType='text' thousandSeparator />
          },
        },
        {
          accessorKey: 'payout_value',
          header: 'Payout value',
          muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ cell, row }) => {
            const payoutValue = cell.getValue<number>()
            return (
              <NumericFormat
                value={payoutValue}
                displayType='text'
                thousandSeparator
                prefix={CURRENCY_SYMBOL[row.original.currency]}
              />
            )
          },
        },
        {
          accessorKey: 'users_count',
          header: 'Payees',
          Cell: ({ cell }) => {
            const users = cell.getValue<number>()
            return (
              <Typography variant='body2'>
                {users > 1 ? `${users} payees` : users === 1 ? `${users} payee` : 'No payees'}
              </Typography>
            )
          },
        },
        {
          id: 'action',
          header: 'Actions',
          size: 100,
          muiTableHeadCellProps: {
            align: 'center',
          },
          muiTableBodyCellProps: {
            align: 'center',
          },
          Cell: ({ row }) => {
            return (
              <Button
                variant='text'
                onClick={() =>
                  navigate(`/assets/custom-asset/${tokenId}/payouts/${row.original.id}`)
                }
              >
                View
              </Button>
            )
          },
        },
      ] as MRT_ColumnDef<CustomTokenPayout>[],
    [navigate, tokenId],
  )

  return (
    <KeeprTable
      columns={columns}
      data={data}
      onPaginationChange={setPagination}
      rowCount={payouts?.count || 0}
      enableSorting={false}
      state={{
        pagination,
        showSkeletons: isLoading,
      }}
      muiTableBodyProps={{
        title: 'Asset payouts table',
      }}
    />
  )
}

export default SelectiveCashSharePayoutsTable

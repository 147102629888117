import axios, { AxiosHeaders } from 'axios'
import { QueryClient } from '@tanstack/react-query'
import { useUserAuthStore } from 'hooks/useUserAuthStore'
import { API_ENDPOINTS as API } from './endpoints'

export interface ErrorResponse {
  statusCode: number
  detail: string
  message?: string
}

export function isApiErrorResponse(error: unknown): error is ErrorResponse {
  if (typeof error === 'object' && error !== null) {
    return 'detail' in error
  }
  return false
}

const {
  actions: { setAccessToken, clearAuthTokens: clearTokens },
} = useUserAuthStore.getState()

const queryClient = new QueryClient()

const PUBLIC_ROUTES = [API.auth.login(), API.auth.register()]

const MOCK_API_URL = ''
const { NODE_ENV, REACT_APP_API_URL } = process.env

const baseURL = NODE_ENV !== 'test' ? REACT_APP_API_URL : MOCK_API_URL

const axiosDefaultOptions = {
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
}

export const baseApiClient = axios.create(axiosDefaultOptions)

baseApiClient.interceptors.request.use(
  (config) => {
    const accessToken =
      JSON.parse(localStorage.getItem('user-auth-store') || '')?.state.accessToken || ''

    if (accessToken) {
      ;(config.headers as AxiosHeaders).set('Authorization', 'Bearer ' + accessToken)
    }
    return config
  },
  (error) => {
    return Promise.reject(error.response)
  },
)

baseApiClient.interceptors.response.use(
  (res) => {
    return res
  },
  async (err) => {
    const originalConfig = err.config

    if (err.response && !PUBLIC_ROUTES.includes(originalConfig.url)) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true
        try {
          const refreshToken =
            JSON.parse(localStorage.getItem('user-auth-store') || '')?.state.refreshToken || ''

          const res = await axios.post(
            API.auth.tokenRefresh(),
            {
              refresh: refreshToken,
            },
            axiosDefaultOptions,
          )
          const newAccessToken = res.data.access
          setAccessToken(newAccessToken)
          return baseApiClient(originalConfig)
        } catch (error) {
          clearTokens()
          queryClient.removeQueries()
          // return Promise.reject(error.response.data);
          return Promise.reject({
            message: 'Your session has expired',
          })
        }
      }
    }

    if (!err.response) {
      return Promise.reject({
        message: 'An error occurred. Please try again later.',
      })
    }

    return Promise.reject({
      statusCode: err.response.status,
      ...err.response.data,
    })
  },
)

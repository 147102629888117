import { CURRENCY_OPTIONS, CURRENCY_SYMBOL } from 'types/common'

export const currenciesOptions = Object.keys(CURRENCY_OPTIONS).map((currency) => {
  const currencyValue = CURRENCY_OPTIONS[currency as keyof typeof CURRENCY_OPTIONS]

  return {
    value: currencyValue,
    label: CURRENCY_SYMBOL[currencyValue],
  }
})

export const MAX_NOTES_TITLE_LENGTH = 100

export const MAX_DESC_NOTES_LENGTH = 1024

export const MAX_TIER_NAME_LENGTH = 50

export const MAX_EMPLOYMENT_TYPE_NAME_LENGTH = 50

export const MAX_ORG_NAME_LENGTH = 100

export const MAX_TOKEN_NAME_LENGTH = 50

export const MAX_NAME_LENGTH = 100

export const RESTRICTED_USE_ALLOWED_ROUTE = '/settings/plans-and-billing'

import React, { useEffect } from 'react'

interface TitledPage {
  title: string
  component: React.ReactNode
}

const TitledPage = ({ title, component }: TitledPage) => {
  useEffect(() => {
    document.title = title
  }, [title])

  return component
}

export default TitledPage

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import { MRT_ColumnDef, MRT_RowSelectionState } from 'material-react-table'
import { ReassignUser, TierUser } from 'types/organization'
import { useEffect, useMemo, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { API_ENDPOINTS as API } from 'services/endpoints'
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material'
import { ArrowRightAlt as ArrowRightAltIcon } from '@mui/icons-material'
import Avatar from 'components/Avatar'
import { SelectOption } from 'types/common'
import { Tier } from 'types/organization'
import { baseApiClient } from 'services/axiosConfig'
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar'
import { useTierDetails } from 'services/api/organization'
import { useTiers } from 'services/api/organization'
import KeeprTable from 'components/KeeprTable'

interface ReassignTierDialogProps {
  open: boolean
  handleClose: () => void
  tier: Tier | null
}

const ReassignTierDialog = ({ open, handleClose, tier }: ReassignTierDialogProps) => {
  const queryClient = useQueryClient()
  const enqueueSnackbar = useEnqueueSnackbar()

  const [groupSelection, setGroupSelection] = useState<number | string>('')
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({})

  const { data: tierDetails = null } = useTierDetails({
    tierId: tier?.id || '',
    options: {
      placeholderData: (previousData) => previousData,
      enabled: Boolean(tier?.id),
    },
  })

  const { data: tiers = [] } = useTiers<SelectOption[]>({
    options: {
      select: (tiers) =>
        tiers
          .map((item) => {
            return {
              label: item.name,
              value: item.id,
            }
          })
          .filter((item) => item.label !== tierDetails?.name),
    },
  })

  const { mutate: reassignUsersTier } = useMutation<unknown, unknown, ReassignUser>({
    mutationFn: (data) =>
      baseApiClient.patch(API.org.reassignTier(), data).then((response) => response.data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tiers'] })
    },
    onError: () => {
      enqueueSnackbar('Something went wrong. Please try Again.', { variant: 'error' })
    },
  })

  useEffect(() => {
    if (tierDetails?.jobs_count !== 0) {
      return
    }

    handleClose()
  }, [handleClose, tierDetails])

  const handleChangeUsersTier = () => {
    reassignUsersTier({
      values: Object.keys(rowSelection).map((userUuid) => {
        return {
          user_uuid: userUuid,
          obj_id: Number(groupSelection),
        }
      }),
    })
  }

  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'full_name',
          header: 'User',
          size: 150,
          Cell: ({ cell, row }) => {
            const fullName = cell.getValue<string>()
            return (
              <Stack direction='row' alignItems='center' gap={1}>
                <Avatar name={fullName} src={row.original.avatar_thumbnail} size={40} />
                <Stack overflow='hidden'>
                  <Typography variant='body2' noWrap={true}>
                    {fullName}
                  </Typography>
                  <Typography variant='caption' color='text.secondary' noWrap={true}>
                    {row.original.email}
                  </Typography>
                </Stack>
              </Stack>
            )
          },
        },
        {
          id: 'arrow',
          header: null,
          size: 50,
          muiTableBodyCellProps: {
            align: 'center',
          },
          Cell: () => {
            return <ArrowRightAltIcon sx={{ fontSize: '24px', verticalAlign: 'middle' }} />
          },
        },
        {
          id: 'reassign',
          header: 'Reassign',
          size: 150,
          Cell: ({ row }) => {
            return (
              <Select
                size='small'
                fullWidth
                displayEmpty
                defaultValue=''
                onChange={(event) => {
                  reassignUsersTier({
                    values: [
                      {
                        user_uuid: row.original.uuid,
                        obj_id: Number(event.target.value),
                      },
                    ],
                  })
                }}
              >
                <MenuItem disabled value=''>
                  Select tier
                </MenuItem>
                {tiers.map((tier) => (
                  <MenuItem key={tier.value} value={tier.value}>
                    {tier.label}
                  </MenuItem>
                ))}
              </Select>
            )
          },
        },
      ] as MRT_ColumnDef<TierUser>[],
    [reassignUsersTier, tiers],
  )

  const data = useMemo(() => {
    if (!tierDetails) {
      return []
    }

    const usersList = tierDetails.userjob_set.map((item) => item.user)

    // we need to create false pagination to preserve current page (avoid switching to the first page in the table)
    // after each row selection change - bug inside material react table
    return usersList
  }, [tierDetails])

  const numbOfSelectedRows = Object.keys(rowSelection).length

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='Reassign tier'
      open={open}
      fullWidth={true}
      data-testid='reassign-tier-dialog'
    >
      <DialogTitle>
        <Link
          component='button'
          data-testid='back-button'
          variant='h6'
          color='text.primary'
          onClick={handleClose}
          underline='none'
          type='button'
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <ArrowBackIcon sx={{ fontSize: '20px' }} />
          <span>Reassign users to a new tier</span>
        </Link>
        <Typography variant='body2' color='text.secondary'>
          The {tier?.name} tier you want to remove currently has {tier?.jobs_count}{' '}
          {tier?.jobs_count === 1 ? 'user' : 'users'}.
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ px: 3, pb: 1, '&.MuiDialogContent-root': { pt: 1 } }}>
        {numbOfSelectedRows > 0 ? (
          <>
            <Stack direction='row' justifyContent='space-between' alignItems='center' pb={1}>
              <Typography variant='body2' color='text.secondary' pl={2}>
                {numbOfSelectedRows} selected
              </Typography>

              <Box>
                <Select
                  size='small'
                  sx={{ width: '180px', mr: 2 }}
                  displayEmpty
                  value={groupSelection}
                  onChange={(event) => setGroupSelection(event.target.value)}
                >
                  <MenuItem disabled value=''>
                    Select tier
                  </MenuItem>
                  {tiers.map((tier) => (
                    <MenuItem key={tier.value} value={tier.value}>
                      {tier.label}
                    </MenuItem>
                  ))}
                </Select>

                <Button variant='text' onClick={handleChangeUsersTier} disabled={!groupSelection}>
                  Apply
                </Button>
              </Box>
            </Stack>
            <Divider />
          </>
        ) : null}

        <KeeprTable
          columns={columns}
          data={data}
          rowCount={tierDetails?.jobs_count ?? 0}
          enableRowSelection={true}
          enablePagination={false}
          getRowId={(row) => row.uuid}
          onRowSelectionChange={setRowSelection}
          state={{ rowSelection }}
          muiTablePaperProps={{
            elevation: 0,
          }}
          muiTableHeadCellProps={{
            sx: {
              '&.MuiTableCell-root:first-of-type': {
                width: '30px',
              },
            },
          }}
          muiTableBodyProps={{
            title: 'Reassign users - tiers',
          }}
        />
      </DialogContent>

      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button variant='outlined' onClick={handleClose} data-testid='cancel'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default ReassignTierDialog

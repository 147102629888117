import { Box, Button, Dialog, Slide, Stack, Typography } from '@mui/material'
import { useWorkspaceList } from 'services/api/workspace'
import AuthFormLayout from 'auth/components/AuthFormLayout'
import { CheckCircleOutline } from '@mui/icons-material'
import ConfirmYourOrganizationForm from 'auth/login/ConfirmYourOrganizationForm'
import { LoginCredentials } from 'types/auth'
import LoginForm from 'auth/login/LoginForm'
import { OrganizationTheme } from 'types/organization'
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar'
import { useProfileUUID } from 'hooks/useUserProfileStore'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import { API_ENDPOINTS as API } from 'services/endpoints'

interface LinkWorkspaceDialogProps {
  isOpen: boolean
  handleLinkWorkspaceDialogClose: () => void
}

const LinkWorkspaceDialog = ({
  isOpen,
  handleLinkWorkspaceDialogClose,
}: LinkWorkspaceDialogProps) => {
  const queryClient = useQueryClient()
  const enqueueSnackbar = useEnqueueSnackbar()
  const loggedInUUID = useProfileUUID()

  const [linkWorkspaceStep, setLinkWorkspaceStep] = useState<number>(0)
  const [organization, setOrganization] = useState<OrganizationTheme>()

  const { data: workspaces } = useWorkspaceList({ userId: loggedInUUID })
  const { mutate: linkWorkspace, isPending: isLinkWorkspacePending } = useMutation<
    unknown,
    ErrorResponse,
    LoginCredentials
  >({
    mutationFn: async (credentials) => baseApiClient.post(API.workspace.link(), credentials),
    onSuccess: () => {
      setLinkWorkspaceStep(2)
      queryClient.invalidateQueries({ queryKey: ['workspaces'] })
      queryClient.invalidateQueries({ queryKey: ['current-user-balance'] })
    },
    onError: (error) => {
      if (error?.detail) {
        enqueueSnackbar(error.detail, { variant: 'error' })
      } else {
        enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' })
      }
    },
  })

  const onSubmit = (data: LoginCredentials) => {
    linkWorkspace(data)
  }

  const handleConfirmOrganization = (organization: OrganizationTheme) => {
    if (workspaces?.some((w) => organization.subdomain === w.subdomain)) {
      enqueueSnackbar('Another user from your organization is already connected.', {
        variant: 'error',
      })
    } else {
      setOrganization(organization)
      setLinkWorkspaceStep(1)
    }
  }

  return (
    <Dialog
      aria-labelledby='Link workspace'
      open={isOpen}
      onClose={handleLinkWorkspaceDialogClose}
      disableEscapeKeyDown
      maxWidth='md'
      data-testid='link-workspace-dialog'
    >
      <Slide direction='right' in={linkWorkspaceStep === 0} mountOnEnter unmountOnExit>
        <Box padding={2} display='flex' flexDirection='column'>
          {linkWorkspaceStep === 0 ? (
            <AuthFormLayout isDefaultSubdomain={true}>
              <ConfirmYourOrganizationForm onConfirmOrganization={handleConfirmOrganization} />
            </AuthFormLayout>
          ) : null}
        </Box>
      </Slide>
      <Slide direction='left' in={linkWorkspaceStep === 1} mountOnEnter unmountOnExit>
        <Box padding={2} display='flex' flexDirection='column'>
          {linkWorkspaceStep === 1 ? (
            <AuthFormLayout organization={organization}>
              <LoginForm
                organizationName={organization?.name || ''}
                isLoginLoading={isLinkWorkspacePending}
                onSubmit={onSubmit}
                onChangeOrganization={() => {
                  setLinkWorkspaceStep(0)
                }}
              />
            </AuthFormLayout>
          ) : null}
        </Box>
      </Slide>
      <Slide direction='left' in={linkWorkspaceStep === 2} mountOnEnter unmountOnExit>
        <Box padding={2} display='flex' flexDirection='column'>
          {linkWorkspaceStep === 2 ? (
            <Stack display='inline-flex' gap={2} justifyContent='center'>
              <Stack alignItems='center' sx={{ fontSize: '128px', padding: 3 }} gap={1}>
                <CheckCircleOutline fontSize='inherit' color='success' />
                <Typography variant='h5' color='text.secondary'>
                  Your workspace has been linked successfully
                </Typography>
              </Stack>
              <Button
                variant='contained'
                size='large'
                sx={{ mt: 2 }}
                onClick={handleLinkWorkspaceDialogClose}
              >
                Return to workspace
              </Button>
            </Stack>
          ) : null}
        </Box>
      </Slide>
    </Dialog>
  )
}

export default LinkWorkspaceDialog

import { SvgIcon } from '@mui/material'
import { SvgIconProps } from '@mui/material'

const AddUsersIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg viewBox='0 0 106 106' fill='none'>
        <path d='M77 56V70V84' stroke='currentColor' strokeWidth='6' strokeLinecap='round' />
        <path d='M63 70H77H91' stroke='currentColor' strokeWidth='6' strokeLinecap='round' />
        <path
          d='M39.0375 43.05C39.0375 44.8095 37.6095 46.2375 35.85 46.2375C34.0905 46.2375 32.6625 44.8095 32.6625 43.05C32.6625 41.2905 34.0905 39.8625 35.85 39.8625C37.6095 39.8625 39.0375 41.2905 39.0375 43.05ZM51.15 39.8625C49.3905 39.8625 47.9625 41.2905 47.9625 43.05C47.9625 44.8095 49.3905 46.2375 51.15 46.2375C52.9095 46.2375 54.3375 44.8095 54.3375 43.05C54.3375 41.2905 52.9095 39.8625 51.15 39.8625ZM69 40.5C69 54.576 57.576 66 43.5 66C29.424 66 18 54.576 18 40.5C18 26.424 29.424 15 43.5 15C57.576 15 69 26.424 69 40.5ZM40.083 20.406C43.653 26.322 50.13 30.3 57.525 30.3C58.698 30.3 59.8455 30.1725 60.942 29.994C57.372 24.078 50.895 20.1 43.5 20.1C42.327 20.1 41.1795 20.2275 40.083 20.406ZM24.171 34.0485C28.5315 31.575 31.8975 27.546 33.504 22.7265C29.1435 25.2 25.7775 29.229 24.171 34.0485ZM63.9 40.5C63.9 38.511 63.594 36.5985 63.0585 34.788C61.2735 35.1705 59.4375 35.4 57.525 35.4C49.5435 35.4 42.429 31.728 37.737 25.9905C35.0595 32.5185 29.73 37.644 23.1 40.143C23.1255 40.245 23.1 40.3725 23.1 40.5C23.1 51.7455 32.2545 60.9 43.5 60.9C54.7455 60.9 63.9 51.7455 63.9 40.5Z'
          fill='currentColor'
        />
      </svg>
    </SvgIcon>
  )
}

export default AddUsersIcon

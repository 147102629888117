import { useForm, Controller } from 'react-hook-form'
import { Stack, TextField, Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { z } from 'zod'
import { baseApiClient, ErrorResponse } from 'services/axiosConfig'
import { API_ENDPOINTS as API } from 'services/endpoints'
import { useMutation } from '@tanstack/react-query'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar'
import { emailRegexExpression } from 'utils/validations'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

const getStartedFormSchema = z.object({
  email: z.string().regex(emailRegexExpression, {
    message: 'Email is invalid.',
  }),
})

type GetStartedForm = z.infer<typeof getStartedFormSchema>

interface GetStartedProps {
  changeRegistrationStep: (step: string) => void
  changeCurrentEmail: (email: string) => void
  currentEmail: string
}

const GetStarted = ({
  changeRegistrationStep,
  currentEmail,
  changeCurrentEmail,
}: GetStartedProps) => {
  const enqueueSnackbar = useEnqueueSnackbar()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const { mutate: registerUser, isPending: isRegisterUserPending } = useMutation<
    { email: string },
    ErrorResponse,
    { email: string; recaptchaToken?: string }
  >({
    mutationFn: ({ email, recaptchaToken }) =>
      baseApiClient
        .post(API.auth.register(), { email, recaptchaToken })
        .then((response) => response.data),
    onSuccess: (data) => {
      changeCurrentEmail(data.email)
      changeRegistrationStep('verify-email')
    },
    onError: (error) => {
      enqueueSnackbar(error.detail, { variant: 'error' })
    },
  })

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<GetStartedForm>({
    mode: 'onBlur',
    resolver: zodResolver(getStartedFormSchema),
    defaultValues: {
      email: currentEmail,
    },
  })

  const onSubmit = async (data: GetStartedForm) => {
    const recaptchaToken = await executeRecaptcha?.('KEEPR_REGISTER')
    registerUser({ email: data.email, recaptchaToken })
  }

  return (
    <div data-testid='get-started-form'>
      <Typography variant='h5'>Get Started</Typography>
      <Typography variant='body2' color='text.secondary'>
        Tell us more about yourself
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack gap={3.5} mt={4}>
          <Controller
            name='email'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                label='Work email'
                type='email'
                variant='outlined'
                {...field}
                error={!!error}
                helperText={error?.message}
                data-testid='work-email'
              />
            )}
          />

          <LoadingButton
            variant='contained'
            size='large'
            type='submit'
            disabled={!isValid}
            loading={isRegisterUserPending}
            data-testid='get-started-button'
          >
            Get started
          </LoadingButton>
        </Stack>
      </form>

      <Box mt={3} display='flex' alignItems='center' justifyContent='flex-end' gap={1}>
        <Typography variant='body2' color='text.secondary'>
          Already registered?{' '}
          <Typography
            component={Link}
            to='/login'
            variant='body2'
            color='primary.main'
            data-testid='log-in-link'
          >
            Log in here.
          </Typography>
        </Typography>
      </Box>
    </div>
  )
}

export default GetStarted

import { Box, Typography } from '@mui/material'
import { MRT_PaginationState, type MRT_ColumnDef } from 'material-react-table'
import { NumericFormat } from 'react-number-format'
import { formatDate } from 'utils/dates'
import { useMemo, useState } from 'react'
import { TokenDistribution as Distributions } from 'types/token/tokenDistribution'
import { useTokenDistributionList } from 'services/api/tokenDistribution'
import KeeprTable from 'components/KeeprTable'
import { useOutletContext } from 'react-router-dom'
import CustomTokenDistributionDetailsDialog from './dialogs/CustomTokenDistributionDetailsDialog'

const CustomTokenDistributions = () => {
  const tokenId = useOutletContext<string>()
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    //material-react-table and tanstack table under the hood use zero based index, our back-end uses 1 based index
    pageIndex: 0,
    pageSize: 10,
  })

  const [selectedDistributionId, setSelectedDistributionId] = useState<string>('')
  const [selectedPayeesCount, setSelectedPayeesCount] = useState<number | null>(null)

  const [
    isCustomTokenDistributionDetailsDialogOpen,
    setIsCustomTokenDistributionDetailsDialogOpen,
  ] = useState(false)

  const { data: tokenDistributions, isLoading } = useTokenDistributionList({
    tokenId,
    pagination: {
      page: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
    },
    options: {
      placeholderData: (previousData) => previousData,
    },
  })

  const data = useMemo(() => tokenDistributions?.results, [tokenDistributions]) || []

  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'created_at',
          header: 'Date',
          id: 'date',
          Cell: ({ row }) => {
            return (
              <>
                <Typography
                  variant='body2'
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setIsCustomTokenDistributionDetailsDialogOpen(true)
                    setSelectedDistributionId(row.original.id.toString())
                    setSelectedPayeesCount(row.original.payees_count)
                  }}
                >
                  {formatDate(row.original.created_at, 'MMM. dd, yyyy')}
                </Typography>
              </>
            )
          },
        },
        {
          accessorKey: 'amount',
          header: 'Amount',
          muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ row }) => {
            return (
              <Typography
                variant='body2'
                color={
                  row.original.distribution_amount > 0
                    ? 'success.main'
                    : row.original.distribution_amount < 0
                    ? 'error.main'
                    : 'inherit'
                }
              >
                {row.original.distribution_amount > 0 ? '+' : ''}
                <NumericFormat
                  value={row.original.distribution_amount || 0}
                  displayType={'text'}
                  thousandSeparator
                />
              </Typography>
            )
          },
        },
        {
          accessorKey: 'payees_count',
          header: 'Payees',
          Cell: ({ row }) => {
            return (
              <Typography variant='body2'>
                {row.original.payees_count > 1
                  ? `${row.original.payees_count} payees`
                  : row.original.payees_count === 1
                  ? `${row.original.payees_count} payee`
                  : 'No payees'}
              </Typography>
            )
          },
        },
        {
          accessorKey: 'process_type',
          header: 'Method',
          muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ row }) => {
            return (
              <Typography
                variant='body2'
                sx={{
                  textTransform: 'capitalize',
                }}
              >
                {row.original.process_type}
              </Typography>
            )
          },
        },
      ] as MRT_ColumnDef<Distributions>[],
    [],
  )

  return (
    <Box mt={2} data-testid='custom-token-distributions-table'>
      <KeeprTable
        columns={columns}
        data={data}
        globalFilterPlaceholder='Name, email, etc...'
        onPaginationChange={setPagination}
        rowCount={tokenDistributions?.count || 0}
        enableSorting={false}
        state={{
          showSkeletons: isLoading,
          showProgressBars: isLoading,
          pagination,
        }}
        muiTableBodyProps={{
          title: 'Asset distributions table',
        }}
      />
      {selectedDistributionId && (
        <CustomTokenDistributionDetailsDialog
          handleClose={() => setIsCustomTokenDistributionDetailsDialogOpen(false)}
          open={isCustomTokenDistributionDetailsDialogOpen}
          tokenId={tokenId}
          distributionId={selectedDistributionId}
          payeesCount={selectedPayeesCount}
        />
      )}
    </Box>
  )
}

export default CustomTokenDistributions

import { forwardRef, useState } from 'react'
import { TextField, InputAdornment, IconButton, OutlinedTextFieldProps } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import PasswordRequirements from './PasswordRequirements'
import { FieldError } from 'react-hook-form'

export interface SetPasswordFormInputs {
  password: string
}

export interface PasswordFieldProps extends Partial<Omit<OutlinedTextFieldProps, 'error'>> {
  showRequirements?: boolean
  error?: FieldError | string
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  'data-testid'?: string
}

const PasswordField = forwardRef<HTMLInputElement, PasswordFieldProps>(
  (
    {
      id = 'password',
      label = 'Password',
      showRequirements = true,
      error,
      value,
      'data-testid': dataTestId,
      ...rest
    },
    ref,
  ) => {
    const [showPassword, setShowPassword] = useState<boolean>(false)

    return (
      <>
        <TextField
          {...rest}
          ref={ref}
          id={id}
          type={showPassword ? 'text' : 'password'}
          fullWidth
          label={label}
          error={!!error}
          value={value}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton type='button' onClick={() => setShowPassword((prev) => !prev)}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          data-testid={dataTestId}
        />

        {showRequirements ? <PasswordRequirements password={value} /> : null}
      </>
    )
  },
)

PasswordField.displayName = 'PasswordField'
export default PasswordField

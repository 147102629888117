import { useState } from 'react'
import {
  AppBar as MuiAppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Icon,
  Stack,
  Divider,
  // Badge,
  // Tooltip,
} from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
// import tinycolor2 from 'tinycolor2'
import { useUserAuthActions } from 'hooks/useUserAuthStore'

import {
  ExpandMore as ExpandMoreIcon,
  // DarkMode as DarkModeIcon,
  // LightMode as LightModeIcon,
} from '@mui/icons-material'
import { SIDEBAR_WIDTH } from 'components/sidebar/Sidebar'
import Avatar from 'components/Avatar'
import {
  useProfileFullName,
  useUserProfileActions,
  useProfileAvatar,
  useProfileEmail,
} from 'hooks/useUserProfileStore'
// import {
//   useOrganizationActions,
//   useOrganizationIsDarkTheme,
//   useOrganizationThemeColor,
// } from 'hooks/useOrganizationStore'
import UserSettings from 'features/user/settings/UserSettings'
import AppBreadcrumbs from 'components/breadcrumbs/Breadcrumbs'
import { useRestrictedUse } from 'hooks/useOrganizationStore'

const Navbar = () => {
  const queryClient = useQueryClient()

  const { clearAuthTokens: clearTokens } = useUserAuthActions()
  const { clearProfile } = useUserProfileActions()
  // const { toggleDarkTheme } = useOrganizationActions()

  const profileFullName = useProfileFullName()
  const profileAvatar = useProfileAvatar()
  const profileEmail = useProfileEmail()

  const restricted_use = useRestrictedUse()

  // const orgColor = useOrganizationThemeColor()
  // const isDarkTheme = useOrganizationIsDarkTheme()

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)

  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState<boolean>(false)

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleMenuActions = () => {
    setIsSettingsDialogOpen(true)
    handleCloseUserMenu()
  }

  //Dark mode related, disabled for now
  // const showThemeNotification =
  //   (tinycolor2(orgColor).isDark() && isDarkTheme) ||
  //   (!tinycolor2(orgColor).isDark() && !isDarkTheme)

  return (
    <div>
      <MuiAppBar
        position='fixed'
        sx={{
          width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
          ml: `${SIDEBAR_WIDTH}px`,
          boxShadow: 'none',
        }}
        data-testid='header-navigation'
      >
        <Toolbar variant='dense' sx={{ height: 56 }}>
          <Box display='flex' alignItems='center' gap={1}>
            <AppBreadcrumbs />
          </Box>
          <Box flexGrow={0} marginLeft='auto' gap={3} display='flex'>
            {/* Dark mode icon is disabled for now */}
            {/* <IconButton
              onClick={() => toggleDarkTheme()}
              sx={{ color: isDarkTheme ? 'text.primary' : 'primary.contrastText' }}
              data-testid='change-theme'
            >
              <Tooltip
                placement='top'
                title={
                  <>
                    {showThemeNotification ? 'We recommend you to use the' : 'Switch to'}{' '}
                    {isDarkTheme ? 'Light' : 'Dark'} mode
                  </>
                }
              >
                <Badge variant='dot' invisible={!showThemeNotification} color='warning'>
                  <Icon component={isDarkTheme ? LightModeIcon : DarkModeIcon} />
                </Badge>
              </Tooltip>
            </IconButton> */}

            <IconButton
              onClick={handleOpenUserMenu}
              sx={{ p: 0, color: 'grey.50' }}
              disableRipple={true}
              data-testid='user-avatar'
            >
              <Avatar name={profileFullName} src={profileAvatar} size={32} sx={{ fontSize: 16 }} />

              <Icon component={ExpandMoreIcon} />
            </IconButton>

            <Menu
              sx={{ mt: '45px' }}
              id='menu-appbar'
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              data-testid='user-menu'
            >
              <MenuItem>
                <Stack width='auto' justifyContent='flex-start'>
                  <Typography data-testid='full-name'>{profileFullName}</Typography>
                  <Typography variant='body2' color='text.secondary' data-testid='email'>
                    {profileEmail}
                  </Typography>
                </Stack>
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={handleMenuActions}
                disabled={restricted_use}
                data-testid='settings'
              >
                {/* There is a flexbox bug that prevents text-overflow: ellipsis from working in a
                flexbox layout. You can use the Typography component with noWrap to workaround this
                issue: https://mui.com/material-ui/react-menu/#limitations */}
                <Typography noWrap>Settings</Typography>
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  clearTokens()
                  clearProfile()
                  queryClient.removeQueries()
                }}
                data-testid='logout'
              >
                <Typography noWrap>Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </MuiAppBar>
      {isSettingsDialogOpen ? (
        <UserSettings isOpen={true} handleClose={() => setIsSettingsDialogOpen(false)} />
      ) : null}
    </div>
  )
}
export default Navbar

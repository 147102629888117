import { Stack, Typography, Button, Paper, Box, CircularProgress } from '@mui/material'
import KeeprTable from 'components/KeeprTable'
import { CreatePayoutIcon } from 'icons'
import { useMemo, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { useTokenPayoutList } from 'services/api/token'
import { MRT_PaginationState, type MRT_ColumnDef } from 'material-react-table'
import { PayoutStatus, ProfitSharePayout } from 'types/token/profitShare'
import { formatDate } from 'utils/dates'
import StatusChip from 'components/status-chip/StatusChip'
import { NumericFormat } from 'react-number-format'
import { CURRENCY_SYMBOL } from 'types/common'
import NoData from 'components/no-data/NoData'

const ProfitSharePayouts = () => {
  const tokenId = useOutletContext<string>()
  const navigate = useNavigate()
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    //material-react-table and tanstack table under the hood use zero based index, our back-end uses 1 based index
    pageIndex: 0,
    pageSize: 10,
  })

  const { data: payouts, isLoading } = useTokenPayoutList({
    tokenId,
    isPreview: false,
    pagination: {
      page: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
    },
    options: {
      placeholderData: (previousData) => previousData,
    },
  })

  const data = useMemo(() => payouts?.results, [payouts]) || []

  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'name',
          header: 'Title',
          Cell: ({ cell }) => {
            const title = cell.getValue<string>()
            return <Typography variant='body2'>{title}</Typography>
          },
        },
        {
          accessorKey: 'payout_dt',
          header: 'Payout date',
          Cell: ({ cell }) => {
            const payoutDate = cell.getValue<string>()
            return (
              <Typography variant='body2'>{formatDate(payoutDate, 'MMM. dd, yyyy')}</Typography>
            )
          },
        },
        {
          accessorKey: 'status',
          header: 'Status',
          Cell: ({ cell }) => {
            const status = cell.getValue<PayoutStatus>()
            return <StatusChip status={status} />
          },
        },
        {
          accessorKey: 'token_amount',
          header: 'Assets',
          muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ cell }) => {
            const tokenAmount = cell.getValue<number>()
            return <NumericFormat value={tokenAmount} displayType='text' thousandSeparator />
          },
        },
        {
          accessorKey: 'payout_value',
          header: 'Payout value',
          muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ cell, row }) => {
            const payoutValue = cell.getValue<number>()
            return (
              <NumericFormat
                value={payoutValue}
                displayType='text'
                thousandSeparator
                prefix={CURRENCY_SYMBOL[row.original.currency]}
              />
            )
          },
        },
        {
          accessorKey: 'users_count',
          header: 'Payees',
          Cell: ({ cell }) => {
            const users = cell.getValue<number>()
            return (
              <Typography variant='body2'>
                {users > 1 ? `${users} payees` : users === 1 ? `${users} payee` : 'No payees'}
              </Typography>
            )
          },
        },
        {
          id: 'action',
          header: 'Actions',
          size: 100,
          muiTableHeadCellProps: {
            align: 'center',
          },
          muiTableBodyCellProps: {
            align: 'center',
          },
          Cell: ({ row }) => {
            return (
              <Button
                variant='text'
                onClick={() =>
                  navigate(`/assets/profit-share/${tokenId}/payouts/${row.original.id}`)
                }
              >
                View
              </Button>
            )
          },
        },
      ] as MRT_ColumnDef<ProfitSharePayout>[],
    [navigate, tokenId],
  )

  return (
    <Box data-testid='profit-share-payout-tab'>
      {isLoading ? (
        <Paper sx={{ mt: 2, p: 4 }}>
          <Stack height='270px' alignItems='center' justifyContent='center'>
            <CircularProgress size={30} disableShrink />
          </Stack>
        </Paper>
      ) : payouts?.count ? (
        <Box mt={2}>
          <KeeprTable
            columns={columns}
            data={data}
            onPaginationChange={setPagination}
            rowCount={payouts.count}
            enableSorting={false}
            state={{
              pagination,
            }}
            muiTableBodyProps={{
              title: 'Asset payouts table',
            }}
          />
        </Box>
      ) : (
        <Paper sx={{ mt: 2, p: 4 }}>
          <Stack direction='column' alignItems='center' flexWrap='wrap' gap={2}>
            <NoData
              headline='No payouts yet'
              content='Create a payout to allow your team to cash in on their holdings'
              buttonText='Create payout'
              action={() => navigate(`/assets/profit-share/${tokenId}/payouts/create`)}
            >
              <Stack
                width={106}
                height={106}
                alignItems='center'
                justifyContent='center'
                borderRadius='50%'
                sx={{ backgroundColor: 'grey.100' }}
              >
                <CreatePayoutIcon sx={{ fontSize: '64px', color: 'grey.500' }} />
              </Stack>
            </NoData>
          </Stack>
        </Paper>
      )}
    </Box>
  )
}

export default ProfitSharePayouts

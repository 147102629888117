import { LoadingButton } from '@mui/lab'
import { Stack } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { enqueueSnackbar } from 'notistack'

import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import { API_ENDPOINTS as API } from 'services/endpoints'

const StripePortal = () => {
  const { mutate: getCustomerPortal, isPending: isGetCustomerPortalPending } = useMutation<
    { url: string },
    ErrorResponse
  >({
    mutationFn: () =>
      baseApiClient.post(API.stripe.customerPortal()).then((response) => response.data),
    onSuccess: ({ url }) => {
      window.open(url, '_self')
    },

    onError: (error) => {
      if (error.detail) {
        enqueueSnackbar(error.detail, { variant: 'error' })
        return
      }
    },
  })

  return (
    <Stack gap={2}>
      <LoadingButton
        variant='contained'
        loading={isGetCustomerPortalPending}
        onClick={() => getCustomerPortal()}
      >
        Stripe Portal
      </LoadingButton>
    </Stack>
  )
}

export default StripePortal

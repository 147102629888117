import { useState } from 'react'
import { ArrowDropDown } from '@mui/icons-material'
import { Typography, Box, List, ListItemButton, Stack } from '@mui/material'
import Avatar from 'components/Avatar'
import { useOrganizationLogoThumbnail, useOrganizationStore } from 'hooks/useOrganizationStore'
import { useProfileUUID } from 'hooks/useUserProfileStore'
import WorkspaceList from './WorkspaceList'
import TourStep from 'components/tour-step/TourStep'

interface OrganizationMenuProps {
  onLinkWorkspace: () => void
  onUnlinkWorkspace: () => void
}

const OrganizationMenu = ({ onLinkWorkspace, onUnlinkWorkspace }: OrganizationMenuProps) => {
  const [anchorElOrganization, setAnchorElOrganization] = useState<null | HTMLElement>(null)

  const currentOrganization = useOrganizationStore()
  const organizationLogoThumbnail = useOrganizationLogoThumbnail()
  const loggedInUUID = useProfileUUID()

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElOrganization(event.currentTarget)
  }

  const handleCloseOrganizationMenu = () => {
    setAnchorElOrganization(null)
  }

  return (
    <>
      <List component='nav' aria-label='Workspaces' disablePadding>
        <TourStep
          order={3}
          outlineOffset={-2}
          title='Connect organizations'
          description='Play a role in multiple teams? Connect to and switch between your organizations seamlessly here.'
          transformOrigin={{
            vertical: 'top',
            horizontal: -10,
          }}
        >
          <ListItemButton onClick={handleOpenUserMenu} sx={{ padding: 0 }}>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              width='100%'
              gap={1}
              padding={2}
            >
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='flex-start'
                width='80%'
                gap={1}
              >
                <Box>
                  <Avatar
                    name={currentOrganization.name}
                    src={organizationLogoThumbnail || ''}
                    size={24}
                    sx={{
                      fontSize: 12,
                    }}
                    data-testid='organization-avatar'
                  />
                </Box>

                <Typography
                  variant='h6'
                  noWrap={true}
                  sx={{ lineHeight: '20px' }}
                  data-testid='organization-name'
                >
                  {currentOrganization.name}
                </Typography>
              </Stack>
              <ArrowDropDown />
            </Stack>
          </ListItemButton>
        </TourStep>
      </List>
      {anchorElOrganization ? (
        <WorkspaceList
          currentOrganization={currentOrganization}
          anchorElOrganization={anchorElOrganization}
          loggedInUUID={loggedInUUID}
          handleClose={handleCloseOrganizationMenu}
          onLinkWorkspace={onLinkWorkspace}
          onUnlinkWorkspace={onUnlinkWorkspace}
        />
      ) : null}
    </>
  )
}

export default OrganizationMenu

import { Chip } from '@mui/material'
import { PAYOUT_STATUS, PayoutStatus } from 'types/token/profitShare'
import { USER_STATUS, UserStatus } from 'types/user'

export interface StatusChipProps {
  status: UserStatus | PayoutStatus
  size?: 'medium' | 'small' | undefined
  isDisabled?: boolean
  'data-testid'?: string
}

const StatusChip = ({
  status,
  size = 'medium',
  isDisabled = false,
  'data-testid': dataTestId,
}: StatusChipProps) => {
  const getStatusColor = (status: string) => {
    if (status === USER_STATUS.Created || status === PAYOUT_STATUS.Pending) {
      return 'warning.main'
    }
    if (status === USER_STATUS.Invited) {
      return 'info.main'
    }
    if (status === USER_STATUS.Active || status === PAYOUT_STATUS.Completed) {
      return 'success.main'
    }

    return 'error.main'
  }

  return (
    <Chip
      label={status}
      size={size}
      sx={{
        textTransform: 'capitalize',
        backgroundColor: isDisabled ? '#1F1919' : getStatusColor(status),
        color: 'white',
        opacity: isDisabled ? 0.38 : 1,
      }}
      data-testid={dataTestId}
    />
  )
}

export default StatusChip

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { Tier, TierForm, organizationTierFormSchema } from 'types/organization'

import { LoadingButton } from '@mui/lab'
import { useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'

import { isOverLimit } from 'utils/numbers'
import { MAX_TIER_NAME_LENGTH, MAX_DESC_NOTES_LENGTH } from 'constants/common'

const TIER_FORM_DEFAULT_VALUES: TierForm = {
  name: '',
  description: '',
}

interface AddTierDialogProps {
  open: boolean
  handleClose: () => void
  handleAddTier: (tier: Partial<TierForm>) => void
  isLoading: boolean
  tier: Tier | null
}

const AddTierDialog = ({
  open,
  handleClose,
  handleAddTier,
  isLoading,
  tier,
}: AddTierDialogProps) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { dirtyFields, isValid },
  } = useForm<TierForm>({
    mode: 'onBlur',
    resolver: zodResolver(organizationTierFormSchema),
    defaultValues: TIER_FORM_DEFAULT_VALUES,
  })

  useEffect(() => {
    if (!tier) {
      return
    }

    reset({
      name: tier.name,
      description: tier.description,
    })
  }, [reset, tier])

  const onSubmit = (values: TierForm) => {
    const changedValues = Object.keys(values).reduce((acc: Partial<TierForm>, field) => {
      const fieldKey = field as keyof TierForm
      if (dirtyFields[fieldKey]) {
        return { [fieldKey]: values[fieldKey], ...acc }
      }
      return { ...acc }
    }, {})

    handleAddTier(changedValues)
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='Add Tier'
      open={open}
      data-testid='add-tier-modal'
    >
      <DialogTitle>{tier ? 'Edit tier' : 'Add tier'}</DialogTitle>

      <DialogContent sx={{ px: 3, py: 1, '&.MuiDialogContent-root': { pt: 1 } }}>
        <form id='add-tier-form' onSubmit={handleSubmit(onSubmit)} noValidate>
          <Controller
            name='name'
            control={control}
            render={({ field, fieldState: { error } }) => {
              const charCount = field.value.length || 0
              const isOverNameLimit = isOverLimit(charCount, MAX_TIER_NAME_LENGTH)

              return (
                <TextField
                  label='Tier name'
                  {...field}
                  fullWidth
                  error={!!error || isOverNameLimit}
                  helperText={`${charCount}/${MAX_TIER_NAME_LENGTH}` || error?.message}
                  FormHelperTextProps={{
                    sx: { textAlign: 'right' },
                  }}
                  inputProps={{
                    maxLength: MAX_TIER_NAME_LENGTH,
                  }}
                  sx={{ mb: 2 }}
                  data-testid='tier-name'
                />
              )
            }}
          />

          <Controller
            name='description'
            control={control}
            render={({ field, fieldState: { error } }) => {
              const charCount = field.value.length || 0
              const isOverDescriptionLimit = isOverLimit(charCount, MAX_DESC_NOTES_LENGTH)
              return (
                <TextField
                  multiline
                  label='Description (Optional)'
                  placeholder='Describe this tier to your team'
                  {...field}
                  fullWidth
                  error={!!error || isOverDescriptionLimit}
                  helperText={`${charCount}/${MAX_DESC_NOTES_LENGTH}` || error?.message}
                  FormHelperTextProps={{
                    sx: { textAlign: 'right' },
                  }}
                  inputProps={{
                    maxLength: MAX_DESC_NOTES_LENGTH,
                  }}
                  id='description'
                  data-testid='tier-description'
                />
              )
            }}
          />
        </form>
      </DialogContent>

      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button variant='outlined' onClick={handleClose} data-testid='cancel'>
          Cancel
        </Button>
        <LoadingButton
          variant='contained'
          type='submit'
          form='add-tier-form'
          disabled={!isValid}
          loading={isLoading}
          data-testid='add-tier'
        >
          {tier ? 'Update' : 'Add tier'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
export default AddTierDialog

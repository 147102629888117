import countries from 'i18n-iso-countries'
import enLocale from 'i18n-iso-countries/langs/en.json'

type Country = {
  label: string
  value: string
  group: 'favorite' | 'normal'
}
countries.registerLocale(enLocale)
const countryObj = countries.getNames('en', { select: 'official' })
const countryList: Country[] = Object.entries(countryObj).map(([key, value]) => {
  const favorites = ['IE', 'US'] //countries at the top
  if (favorites.includes(key)) {
    return {
      label: value,
      value: key,
      group: 'favorite',
    }
  }
  return {
    label: value,
    value: key,
    group: 'normal',
  }
})

const useCountries = (): Country[] => {
  return countryList
}

export default useCountries

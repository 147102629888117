import { Paper, Box, Fab, Typography, Stack, IconButton, InputBase, Divider } from '@mui/material'
import {
  QuestionAnswerOutlined as QuestionAnswerOutlinedIcon,
  SouthEastOutlined as SouthEastOutlinedIcon,
  Send as SendIcon,
  Close as CloseIcon,
} from '@mui/icons-material'
import { Fragment, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import { UserRole } from 'types/user'
import { useProfileRole } from 'hooks/useUserProfileStore'
import { useAccessToken } from 'hooks/useUserAuthStore'
import { useProfileUUID } from 'hooks/useUserProfileStore'

const chatBoxExpanded = {
  visibility: 'visible',
  transform: 'translate(-270px, -500px) scale(1, 1)',
  transition: 'transform 0.3s ease 0s, visibility 0.3s ease 0s',
}

const chatBoxCollapsed = {
  visibility: 'hidden',
  transform: 'translate(-300px, -460px) scale(0.01, 0.01)',
  transition: 'transform 0.3s ease 0s, visibility 0.3s ease 0s',
}

interface ChatBotPrompt {
  query: string
  location: string
  user_role: UserRole
  user_id: string
  authorization: string
}

interface ChatBotResponse {
  response: string
}

type Message = ChatBotPrompt | ChatBotResponse

function isPrompt(data: Message): data is ChatBotPrompt {
  return 'query' in data
}

const ChatBox = () => {
  const location = useLocation()
  const profileRole = useProfileRole()
  const accessToken = useAccessToken()
  const userId = useProfileUUID()

  const [openChatBox, setOpenChatBox] = useState<boolean>(false)
  const [prompt, setPrompt] = useState<string>('')
  const [conversation, setConversation] = useState<Message[]>([])

  const { mutate: sendMessage, isPending: isWaitingForResponse } = useMutation<
    ChatBotResponse,
    unknown,
    ChatBotPrompt
  >({
    mutationFn: (data) =>
      axios
        .post('https://keepr-platform-openai-assistant-33hvbqh3ya-uc.a.run.app/chat', data)
        .then((response) => response.data),
    onSuccess: (chatBoxResponse) => {
      setConversation((conversation) => [...conversation, chatBoxResponse])
    },
    onError: () => {
      const errorMessage = {
        response:
          'Sorry, I was not able to answer that question. I’ll notify customer support about this.',
      }
      setConversation((conversation) => [...conversation, errorMessage])
    },
  })

  const askChatBot = (query: string) => {
    if (!userId || !profileRole || !accessToken) return

    const newPrompt = {
      query: query,
      location: location.pathname + location.hash,
      user_role: profileRole,
      user_id: userId,
      authorization: accessToken,
    }

    setConversation((conversation) => [...conversation, newPrompt])

    setPrompt('')

    sendMessage(newPrompt)
  }

  return (
    <Box position='fixed' right='24px' bottom='32px' zIndex='10000'>
      <Paper
        elevation={1}
        sx={{
          position: 'absolute',
          transformOrigin: 'right bottom',
          ...(openChatBox ? chatBoxExpanded : chatBoxCollapsed),
        }}
      >
        <Stack height='480px' width='320px' bgcolor='white'>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            bgcolor='keepr.main'
            height='50px'
            p={2}
            color='#fff'
          >
            <Typography noWrap={true}>Keepr Chat (Beta)</Typography>
            <IconButton color='inherit' size='small' onClick={() => setOpenChatBox(false)}>
              <SouthEastOutlinedIcon fontSize='inherit' />
            </IconButton>
          </Stack>

          <Box
            display='flex'
            flex={1}
            flexWrap='wrap'
            flexDirection='column-reverse'
            sx={{ overflowY: 'auto' }}
          >
            <Stack px={2} py={1} gap={1} justifyContent='flex-end'>
              <Box maxWidth='240px'>
                <Typography
                  variant='body2'
                  px={1}
                  py={0.5}
                  bgcolor='keepr.light'
                  color='keepr.contrastText'
                  borderRadius={1.5}
                  display='inline-block'
                  sx={{ wordBreak: 'break-word' }}
                >
                  Welcome to the Keepr Platform! I am your AI Assistant for any questions about how
                  to use the Keepr Platform, as well as any general explanations about concepts
                  you're not quite familiar with (eg. What are assets? What is the worth of an
                  asset? What is a Distribution?)
                </Typography>
              </Box>

              {conversation.map((item, index) => {
                const isItemPrompt = isPrompt(item)

                return (
                  <Fragment key={index}>
                    <Box ml={isItemPrompt ? 'auto' : 'initial'} maxWidth='240px'>
                      <Typography
                        variant='body2'
                        px={2}
                        py={0.5}
                        bgcolor={isItemPrompt ? 'grey.300' : 'keepr.light'}
                        color={isItemPrompt ? 'text.primary' : 'keepr.contrastText'}
                        borderRadius={1.5}
                        display='inline-block'
                        sx={{ wordBreak: 'break-word' }}
                      >
                        {isItemPrompt ? item.query : item.response}
                      </Typography>
                    </Box>
                  </Fragment>
                )
              })}

              {isWaitingForResponse ? (
                <Box>
                  <Typography
                    variant='body2'
                    px={1}
                    py={0.5}
                    bgcolor='keepr.light'
                    color='keepr.contrastText'
                    borderRadius={1.5}
                    display='inline-block'
                  >
                    ...
                  </Typography>
                </Box>
              ) : null}
            </Stack>
          </Box>

          <Divider />

          <Stack direction='row' alignItems='flex-end' gap={1} px={2} py={1}>
            <InputBase
              multiline
              maxRows={4}
              fullWidth={true}
              sx={{ py: 1 }}
              placeholder="Let's chat..."
              value={prompt}
              onChange={(ev) => setPrompt(ev.target.value)}
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  ev.preventDefault()
                  prompt && askChatBot(prompt)
                }
              }}
            />
            <Box mb={0.5}>
              <IconButton
                size='small'
                sx={{ color: 'keepr.main' }}
                onClick={() => askChatBot(prompt)}
                disabled={!prompt}
              >
                <SendIcon fontSize='inherit' />
              </IconButton>
            </Box>
          </Stack>
        </Stack>
      </Paper>

      <Fab
        aria-label='open-chat'
        color='keepr'
        sx={{ ml: 'auto', width: '50px', height: '50px' }}
        onClick={() => setOpenChatBox((openChatBox) => !openChatBox)}
      >
        {openChatBox ? <CloseIcon /> : <QuestionAnswerOutlinedIcon />}
      </Fab>
    </Box>
  )
}

export default ChatBox

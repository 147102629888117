import { Alert, Box, Button, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useProfileFullName, useProfile } from 'hooks/useUserProfileStore'
import { API_ENDPOINTS as API } from 'services/endpoints'
import Avatar from 'components/Avatar'
import AvatarUpload from 'components/avatar-upload/AvatarUpload'
import { LoadingButton } from '@mui/lab'
import { UserProfile, USER_STATUS, UserJob } from 'types/user'
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar'
import { useEffect, useState } from 'react'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useProfileRole } from 'hooks/useUserProfileStore'
import { USER_ROLE } from 'types/user'
import { useEmploymentTypes, useTiers } from 'services/api/organization'
import { useUserList } from 'services/api/user'

interface ProfileProps {
  handleClose: () => void
}

const userSettingsFormSchema = z.object({
  first_name: z.string().min(1, 'First name is required'),
  last_name: z.string().min(1, 'Last name is required'),
  avatar: z.string().nullable().optional(),
  company_tier: z.number().or(z.string()).optional(),
  employment_type: z.number().or(z.string()).optional(),
})

type UserSettingsFormValues = z.infer<typeof userSettingsFormSchema>
type UserJobFormData = Pick<UserSettingsFormValues, 'company_tier' | 'employment_type'>
type PartialUserProfile = Partial<UserProfile>

const USER_PROFILE_DEFAULT_VALUES = {
  first_name: '',
  last_name: '',
  avatar: '',
  company_tier: '',
  employment_type: '',
}

const Profile = ({ handleClose }: ProfileProps) => {
  const queryClient = useQueryClient()
  const enqueueSnackbar = useEnqueueSnackbar()
  const profileFullName = useProfileFullName()
  const userProfile = useProfile()
  const profileRole = useProfileRole()

  const { data: employmentTypeList = [] } = useEmploymentTypes({
    options: {
      enabled: profileRole === USER_ROLE.Admin,
    },
  })
  const { data: organizationTierList = [] } = useTiers({
    options: {
      enabled: profileRole === USER_ROLE.Admin,
    },
  })

  const { data: admins } = useUserList({
    accessLevel: USER_ROLE.Admin,
    status: USER_STATUS.Active,
    options: {
      enabled: profileRole === USER_ROLE.Admin,
    },
  })

  const convertUserToFormValues = (user: PartialUserProfile): UserSettingsFormValues => {
    return {
      first_name: user.first_name || '',
      last_name: user.last_name || '',
      avatar: user.avatar,
      company_tier:
        profileRole === USER_ROLE.Admin
          ? organizationTierList.find((eT) => eT.name === user.user_job?.company_tier)?.id || ''
          : user.user_job?.company_tier || '',
      employment_type:
        profileRole === USER_ROLE.Admin
          ? employmentTypeList.find((eT) => eT.name === user.user_job?.employment_type)?.id || ''
          : user.user_job?.employment_type || '',
    }
  }

  const adminList = admins?.results || []

  const isOnlyAdmin = adminList.length === 1 && adminList[0].uuid === userProfile.uuid

  const [isAvatarUploadDialogOpen, setIsAvatarUploadDialogOpen] = useState<boolean>(false)

  //UPDATE AVATAR, FIRST AND LAST NAME
  const { mutate: userProfileUpdate, isPending: isUserProfileUpdatePending } = useMutation<
    UserSettingsFormValues,
    ErrorResponse & { avatar: string[] },
    Partial<UserSettingsFormValues>
  >({
    mutationFn: async (data) => {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      const { avatar, ...otherFields } = data

      const formData = new FormData()

      Object.keys(otherFields).forEach((field) => {
        formData.append(field, data[field as keyof UserSettingsFormValues]?.toString() || '')
      })

      let blobImage = null
      if (avatar) {
        blobImage = await fetch(avatar).then((r) => r.blob())
        formData.append('avatar', blobImage, 'avatar.png')
      }

      if (avatar === '') {
        formData.append('avatar', '')
      }
      return baseApiClient
        .patch(API.user.profile(), formData, config)
        .then((response) => response.data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['profile'] })
    },
    onError: (error) => {
      if (error?.avatar) {
        enqueueSnackbar(error.avatar[0], { variant: 'error' })
        return
      }
      if (error?.detail) {
        enqueueSnackbar(error.detail, { variant: 'error' })
        return
      }
      enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' })
    },
  })

  //UPDATE COMPANY TIER, EMPLOYMENT TYPE
  const { mutateAsync: userJobUpdate } = useMutation<
    UserJob,
    ErrorResponse,
    Partial<UserJobFormData>
  >({
    mutationFn: async (data) => {
      return baseApiClient
        .patch(API.user.job(userProfile.uuid), data)
        .then((response) => response.data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['profile'] })
    },
    onError: (error) => {
      if (error?.detail) {
        enqueueSnackbar(error.detail, { variant: 'error' })
        return
      }
      enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' })
    },
  })

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isValid, dirtyFields, isDirty },
  } = useForm<UserSettingsFormValues>({
    resolver: zodResolver(userSettingsFormSchema),
    defaultValues: userProfile ? convertUserToFormValues(userProfile) : USER_PROFILE_DEFAULT_VALUES,
    mode: 'onBlur',
  })

  const watchAvatar = watch('avatar')

  const onAvatarChange = (avatar: string) => {
    setValue('avatar', avatar, { shouldDirty: true })
    setIsAvatarUploadDialogOpen(false)
  }

  const onSubmit = (data: UserSettingsFormValues) => {
    const changedValues = Object.keys(data).reduce(
      (acc: Partial<UserSettingsFormValues>, field) => {
        const fieldKey = field as keyof UserSettingsFormValues

        if (dirtyFields[fieldKey]) {
          return { [fieldKey]: data[fieldKey], ...acc }
        }
        return acc
      },
      {},
    )

    const { company_tier, employment_type, ...rest } = changedValues
    try {
      if (company_tier || employment_type) {
        userJobUpdate({ company_tier, employment_type })
      }
      if (Object.keys(rest).length > 0) {
        userProfileUpdate(rest)
      }
      enqueueSnackbar('User information updated.', { variant: 'success' })
      handleClose()
    } catch (error) {
      enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' })
    }
  }

  useEffect(() => {
    const employmentType = employmentTypeList.find(
      (eT) => eT.name === userProfile.user_job.employment_type,
    )

    if (!employmentType) {
      return
    }

    setValue('employment_type', employmentType.id)
  }, [employmentTypeList, setValue, userProfile.user_job.employment_type])

  useEffect(() => {
    const tier = organizationTierList.find((t) => t.name === userProfile.user_job.company_tier)

    if (!tier) {
      return
    }
    setValue('company_tier', tier.id)
  }, [organizationTierList, setValue, userProfile.user_job.company_tier])

  return (
    <Stack gap={3} width='auto'>
      {adminList.length > 1 ? (
        <Alert severity='info'>
          Other organization admin manages some of your account details. Please contact them to
          request changes.
        </Alert>
      ) : null}
      {profileRole !== USER_ROLE.Admin ? (
        <Alert severity='info'>
          Your admin manages some of your account details. Please contact them to request changes.
        </Alert>
      ) : null}

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box display='flex' flexDirection='column' gap={2}>
          <Box display='flex' flexDirection='row' gap={3} alignItems='center'>
            <Avatar
              name={profileFullName}
              src={watchAvatar}
              size={80}
              sx={{
                fontSize: 40,
              }}
            />

            <Button
              variant='outlined'
              sx={{
                width: 70,
                height: 30,
              }}
              onClick={() => setIsAvatarUploadDialogOpen(true)}
              data-testid='upload-avatar'
            >
              Change
            </Button>
            {watchAvatar !== null && watchAvatar !== 'removed' ? (
              <Button
                variant='text'
                sx={{
                  width: 70,
                  height: 30,
                }}
                onClick={() => setValue('avatar', '', { shouldDirty: true })}
              >
                Remove
              </Button>
            ) : null}
          </Box>
          <Typography variant='caption'>
            We recommend minimum dimensions of 256px * 256px.
          </Typography>

          <Stack direction='row' gap={2}>
            <Controller
              name='first_name'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label='First name'
                  variant='outlined'
                  error={!!error}
                  helperText={error ? error.message : null}
                  {...field}
                  fullWidth
                  required
                  data-testid='first-name'
                />
              )}
            />
            <Controller
              name='last_name'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label='Last name'
                  variant='outlined'
                  error={!!error}
                  helperText={error ? error.message : null}
                  {...field}
                  fullWidth
                  required
                  data-testid='last-name'
                />
              )}
            />
          </Stack>
          <Stack direction='row' gap={2}>
            <Controller
              name='company_tier'
              control={control}
              render={({ field: { onChange, value, ...rest }, fieldState: { error } }) =>
                profileRole === USER_ROLE.Admin && organizationTierList.length > 0 ? (
                  <TextField
                    select
                    fullWidth
                    helperText={error ? error.message : null}
                    label='Tier level'
                    error={!!error}
                    onChange={onChange}
                    value={value}
                    {...rest}
                    id='tier-level'
                    data-testid='tier-level'
                    disabled={!isOnlyAdmin || userProfile.user_job?.company_tier !== 'N/A'}
                  >
                    {organizationTierList.map((tier) => (
                      <MenuItem key={tier.id} value={tier.id} data-testid='tier-level-select'>
                        {tier.name}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <TextField
                    label='Tier level'
                    variant='outlined'
                    error={!!error}
                    helperText={error ? error.message : null}
                    value={value}
                    {...rest}
                    fullWidth
                    disabled
                    data-testid='tier-level'
                  />
                )
              }
            />

            <Controller
              name='employment_type'
              control={control}
              render={({ field: { onChange, value, ...rest }, fieldState: { error } }) =>
                profileRole === USER_ROLE.Admin && employmentTypeList.length > 0 ? (
                  <TextField
                    select
                    fullWidth
                    helperText={error ? error.message : null}
                    label='Employment type'
                    error={!!error}
                    onChange={onChange}
                    value={value}
                    {...rest}
                    id='employment-type'
                    data-testid='employment-type'
                    disabled={!isOnlyAdmin || userProfile.user_job?.employment_type !== 'N/A'}
                  >
                    {employmentTypeList.map((eT) => (
                      <MenuItem key={eT.id} value={eT.id} data-testid='employment-type-select'>
                        {eT.name}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <TextField
                    label='Employment status'
                    variant='outlined'
                    error={!!error}
                    helperText={error ? error.message : null}
                    value={value}
                    {...rest}
                    fullWidth
                    disabled
                    data-testid='employment-status'
                  />
                )
              }
            />
          </Stack>
          <Stack direction='row' gap={2} justifyContent='flex-end' py={1}>
            <Button onClick={handleClose} data-testid='cancel-changes'>
              Cancel
            </Button>
            <LoadingButton
              variant='contained'
              color='primary'
              type='submit'
              loading={isUserProfileUpdatePending}
              disabled={!isDirty || !isValid}
              data-testid='profile-save-changes'
            >
              Save Changes
            </LoadingButton>
          </Stack>
        </Box>
        {isAvatarUploadDialogOpen ? (
          <AvatarUpload
            uploadTitle='Upload your profile picture'
            isOpen={isAvatarUploadDialogOpen}
            handleClose={() => setIsAvatarUploadDialogOpen(false)}
            handleSaveAvatar={onAvatarChange}
            isUserAvatar={true}
          />
        ) : null}
      </form>
    </Stack>
  )
}
export default Profile

import { Stack, Tooltip, Typography } from '@mui/material'
import {
  LooksOneOutlined as LooksOneOutlinedIcon,
  RequestPageOutlined as RequestPageOutlinedIcon,
} from '@mui/icons-material'
import { UserJob } from 'types/user'

interface UserJobBulletsProps {
  userJob: UserJob
}

const UserJobBullets = ({ userJob }: UserJobBulletsProps) => (
  <>
    <Stack direction='row' alignItems='center' gap={1}>
      <Tooltip title='Employment type' placement='top'>
        <LooksOneOutlinedIcon fontSize='small' sx={{ color: 'primary.main' }} />
      </Tooltip>
      <Typography
        variant='body2'
        color='text.secondary'
        noWrap={true}
        data-testid='employment-type'
      >
        {userJob.employment_type}
      </Typography>
    </Stack>
    <Stack direction='row' alignItems='center' gap={1}>
      <Tooltip title='Organization tier' placement='bottom'>
        <RequestPageOutlinedIcon fontSize='small' sx={{ color: 'primary.main' }} />
      </Tooltip>
      <Typography variant='body2' color='text.secondary' noWrap={true} data-testid='company-tier'>
        {userJob.company_tier}
      </Typography>
    </Stack>
  </>
)

export default UserJobBullets

import { useMemo, useState } from 'react'
import {
  Box,
  Select,
  MenuItem,
  Paper,
  SelectChangeEvent,
  Stack,
  Typography,
  IconButton,
  LinearProgress,
} from '@mui/material'
import { MRT_PaginationState, type MRT_ColumnDef } from 'material-react-table'
import { Close as CloseIcon } from '@mui/icons-material'
import { NumericFormat } from 'react-number-format'
import {
  StockOptionHolder,
  PaginatedStockOptionHolders as StockOptionHoldersType,
  StockOptionStatus,
  STOCK_OPTION_STATUS,
} from 'types/token/stockOption'
import StockOptionStatusChip from 'components/status-chip/StockOptionStatusChip'
import Avatar from 'components/Avatar'
import { formatDate } from 'utils/dates'
import { useStockOptionHoldersList } from 'services/api/stockOption'
import StockOptionHolderPanel from './stock-option-holder-details/StockOptionHolderPanel'
import StockOptionHolderDialogs from './stock-option-holder-details/dialogs/StockOptionHolderDialogs'
import { differenceInMonths } from 'date-fns'
import KeeprTable from 'components/KeeprTable'
import { useOutletContext } from 'react-router-dom'

const initialTokenHoldersData: StockOptionHoldersType = {
  count: 0,
  next: null,
  previous: null,
  results: [],
}

const StockOptionHolders = () => {
  const tokenId = useOutletContext<string>()
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    //material-react-table and tanstack table under the hood use zero based index, our back-end uses 1 based index
    pageIndex: 0,
    pageSize: 5,
  })
  const [globalFilter, setGlobalFilter] = useState<string>('')
  const [tokenStatusFilter, setTokenStatusFilter] = useState<StockOptionStatus | ''>('')

  const { data: tokenHolders = initialTokenHoldersData, isLoading } = useStockOptionHoldersList({
    tokenId,
    pagination: {
      page: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
    },
    searchTerm: globalFilter,
    status: tokenStatusFilter,
    options: {
      placeholderData: (previousData) => previousData,
    },
  })

  const data = useMemo(() => tokenHolders?.results, [tokenHolders]) || []

  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'user_full_name',
          header: 'Name',
          size: 250,
          enableSorting: false,
          Cell: ({ cell, row }) => {
            const fullName = cell.getValue<string>()
            return (
              <Stack direction='row' alignItems='center' gap={1}>
                <Avatar name={fullName} src={row.original.user_avatar_url} size={40} />
                <Stack overflow='hidden'>
                  <Typography variant='body2' noWrap={true}>
                    {fullName}
                  </Typography>
                  <Typography variant='caption' color='text.secondary' noWrap={true}>
                    {row.original.user_email}
                  </Typography>
                </Stack>
              </Stack>
            )
          },
        },
        {
          accessorKey: 'status',
          header: 'Status',
          enableSorting: false,
          Cell: ({ cell }) => {
            const status = cell.getValue<StockOptionStatus>()
            return <StockOptionStatusChip status={status} />
          },
        },
        {
          accessorKey: 'total_options_amount',
          header: 'Granted',
          muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
            align: 'right',
          },
          enableSorting: false,
          Cell: ({ cell }) => {
            const totalOptionsAmount = cell.getValue<number>() || 0
            return (
              <NumericFormat value={totalOptionsAmount} displayType={'text'} thousandSeparator />
            )
          },
        },
        {
          accessorKey: 'grant_date',
          header: 'Grant Date',
          enableSorting: false,
          Cell: ({ row }) => {
            return <>{formatDate(row.original.grant_date, 'MMM. dd, yyyy')}</>
          },
        },
        {
          accessorKey: 'vested',
          header: 'Vested',
          enableSorting: false,
          Cell: ({ row }) => {
            const progress = (row.original.vested_amount * 100) / row.original.total_options_amount
            return (
              <Stack direction='row' gap={1} alignItems='center'>
                <LinearProgress
                  sx={{
                    height: '16px',
                    width: '50px',
                    borderRadius: '4px',
                    backgroundColor: 'divider',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: 'success.main',
                    },
                  }}
                  variant='determinate'
                  value={progress}
                />
                <NumericFormat
                  value={row.original.vested_amount}
                  displayType={'text'}
                  thousandSeparator
                />
              </Stack>
            )
          },
        },
        {
          accessorKey: 'duration',
          header: 'Duration',
          enableSorting: false,
          Cell: ({ row }) => {
            return (
              <>
                {differenceInMonths(new Date(), new Date(row.original.grant_date))} /{' '}
                {row.original.vesting_period} Months
              </>
            )
          },
        },
        {
          header: 'Actions',
          id: 'action',
          enableSorting: false,
          size: 75,
          Cell: ({ row }) => {
            return <StockOptionHolderDialogs holder={row.original} />
          },
        },
      ] as MRT_ColumnDef<StockOptionHolder>[],
    [],
  )

  return (
    <>
      <Box display='flex' flexDirection='column' gap={4} pt={2}>
        <Paper elevation={1}>
          <KeeprTable
            columns={columns}
            data={data}
            globalFilterPlaceholder='Holder name'
            onGlobalFilterChange={setGlobalFilter}
            renderTopToolbarCustomActions={() => (
              <Stack direction='row' flex={1} gap={2} sx={{ px: 1, py: 2 }}>
                <Select
                  value={tokenStatusFilter}
                  displayEmpty
                  inputProps={{
                    'aria-label': 'Status filter',
                    sx: { pr: '0px !important' },
                  }}
                  onChange={(event: SelectChangeEvent) =>
                    setTokenStatusFilter(event.target.value as StockOptionStatus)
                  }
                  size='small'
                  fullWidth={true}
                  sx={{ height: '40px', maxWidth: '220px', minWidth: '160px' }}
                  renderValue={(selected) => {
                    if (!selected?.length) {
                      return <Typography color='text.secondary'>Holder status</Typography>
                    }

                    return (
                      <Typography noWrap={true} sx={{ textTransform: 'capitalize' }}>
                        {selected}
                      </Typography>
                    )
                  }}
                  endAdornment={
                    tokenStatusFilter ? (
                      <IconButton
                        size='small'
                        sx={{ position: 'absolute', right: '30px' }}
                        onClick={() => setTokenStatusFilter('')}
                      >
                        <CloseIcon fontSize='small' />
                      </IconButton>
                    ) : null
                  }
                  data-testid='status-filter'
                >
                  {Object.keys(STOCK_OPTION_STATUS).map((key) => {
                    const stockOptionStatus =
                      STOCK_OPTION_STATUS[key as keyof typeof STOCK_OPTION_STATUS]

                    return (
                      <MenuItem
                        value={stockOptionStatus}
                        key={key}
                        data-testid={`status-filter-option-${key.toLowerCase()}`}
                      >
                        <Typography sx={{ textTransform: 'capitalize' }}>
                          {stockOptionStatus}
                        </Typography>
                      </MenuItem>
                    )
                  })}
                </Select>
              </Stack>
            )}
            onPaginationChange={setPagination}
            rowCount={tokenHolders?.count || 0}
            state={{
              showSkeletons: isLoading,
              showProgressBars: isLoading,
              pagination,
            }}
            renderDetailPanel={({ row }) => <StockOptionHolderPanel holder={row.original} />}
          />
        </Paper>
      </Box>
    </>
  )
}

export default StockOptionHolders

import { useState } from 'react'
import { Box, IconButton, Menu, MenuItem } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { CUSTOM_TOKEN_TYPE_OPTIONS, Token } from 'types/token/basicToken'
import CreateIndividualPayoutDialog from './dialogs/CreateIndividualPayoutDialog'

interface CustomTokenMenuProps {
  rowId: number
  tokenDetails: Token
  availableUserBalance: number
  setIsRemoveUserDialogOpen: (isOpen: boolean) => void
  setSelectedUser: (userId: number) => void
}

const CustomTokenMenu = ({
  rowId,
  tokenDetails,
  availableUserBalance,
  setSelectedUser,
  setIsRemoveUserDialogOpen,
}: CustomTokenMenuProps) => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null)
  const [isIndividualPayoutDialogOpen, setIsIndividualPayoutDialogOpen] = useState<boolean>(false)
  const isOpen = Boolean(anchorElement)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorElement(null)
  }

  return (
    <>
      <Box>
        <IconButton
          id='icon-button'
          aria-controls={isOpen ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={isOpen || undefined}
          onClick={handleClick}
        >
          <MoreVertIcon fontSize='small' />
        </IconButton>

        <Menu
          id='token-menu'
          anchorEl={anchorElement}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={isOpen}
          onClose={handleClose}
          sx={{
            '& .MuiMenu-paper': {
              minWidth: '112px',
            },
          }}
        >
          {tokenDetails.custom_token_type !== CUSTOM_TOKEN_TYPE_OPTIONS.SelectiveCashShare && (
            <MenuItem
              onClick={() => {
                setIsIndividualPayoutDialogOpen(true)
                handleClose()
              }}
              disabled={availableUserBalance === 0}
            >
              Create payout
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              setSelectedUser(rowId)
              setIsRemoveUserDialogOpen(true)
              handleClose()
            }}
          >
            Remove user
          </MenuItem>
        </Menu>
      </Box>
      {isIndividualPayoutDialogOpen ? (
        <CreateIndividualPayoutDialog
          tokenId={tokenDetails.uuid}
          userId={rowId}
          open={true}
          handleClose={() => setIsIndividualPayoutDialogOpen(false)}
        />
      ) : null}
    </>
  )
}

export default CustomTokenMenu

import { Button, Stack, Typography } from '@mui/material'

type NoDataProps = {
  headlineSize?: 'h4' | 'h5' | 'h6'
  headline?: string
  content: string
  buttonText?: string
  action?: () => void
  children?: React.ReactNode
  align?: 'start' | 'center' | 'end'
  maxWidth?: string
}

const NoData = ({
  headline,
  headlineSize = 'h6',
  content,
  buttonText,
  action,
  children,
  align,
  maxWidth = '450px',
}: NoDataProps) => {
  return (
    <Stack
      gap={2}
      alignItems={align || 'center'}
      textAlign={align || 'center'}
      maxWidth={maxWidth}
      width='100%'
    >
      {children}
      <Typography variant={headlineSize}>{headline}</Typography>
      <Typography variant='body1' color='text.primary'>
        {content}
      </Typography>
      {buttonText && action && (
        <Button variant='contained' color='primary' onClick={action}>
          {buttonText}
        </Button>
      )}
    </Stack>
  )
}

export default NoData

import { useEffect } from 'react'
import { Stack, Typography, Box } from '@mui/material'
import LinearProgressWithLabel from '../LinearProgressWithLabel/LinearProgressWithLabel'
import { useUserProfileActions } from 'hooks/useUserProfileStore'

type OnboardingStepsBlockProps = {
  onBoardingStepCompleted: number | null
  nextStep: (onBoardingStepCompleted: number) => string
}

const OnboardingStepsBlock = ({ nextStep, onBoardingStepCompleted }: OnboardingStepsBlockProps) => {
  const { setOnboardingStepCompleted } = useUserProfileActions()

  //remove Onboarding complete message after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      if (onBoardingStepCompleted === 5) {
        setOnboardingStepCompleted(null)
      }
    }, 3000)

    return () => clearTimeout(timer)
  }, [onBoardingStepCompleted, setOnboardingStepCompleted])

  return (
    <Stack gap={1} py={0} px={3.5}>
      <Typography variant='body2'>Onboarding</Typography>
      <LinearProgressWithLabel
        value={onBoardingStepCompleted ? onBoardingStepCompleted * 20 : 0}
        defaultValue={0}
      />
      <Box>
        {onBoardingStepCompleted !== 5 && onBoardingStepCompleted !== null ? (
          <>
            <Typography variant='body2' color='primary'>
              Next step:
            </Typography>
            <Typography variant='body2' data-testid='onboarding-next-step'>
              {onBoardingStepCompleted ? nextStep(onBoardingStepCompleted) : null}
            </Typography>
          </>
        ) : null}
      </Box>
    </Stack>
  )
}

export default OnboardingStepsBlock

import { Stack, Paper, Box, CircularProgress } from '@mui/material'
import { useOutletContext } from 'react-router-dom'
import { useTokenDetails } from 'services/api/token'
import { CUSTOM_TOKEN_TYPE_OPTIONS } from 'types/token/basicToken'
import IndividualPayoutsTable from './tables/IndividualPayoutsTable'
import SelectiveCashSharePayoutsTable from './tables/SelectiveCashSharePayoutsTable'

const CustomTokenPayouts = () => {
  const tokenId = useOutletContext<string>()

  const { data: token, isLoading: isTokenLoading } = useTokenDetails({ tokenId })

  const isTokenPayoutIndividual =
    token?.custom_token_type === CUSTOM_TOKEN_TYPE_OPTIONS.IndividualRedemption ||
    token?.custom_token_type === CUSTOM_TOKEN_TYPE_OPTIONS.FixedPool

  return (
    <Box data-testid='selective-cash-share-payout-tab'>
      {isTokenLoading ? (
        <Paper sx={{ mt: 2, p: 4 }}>
          <Stack height='270px' alignItems='center' justifyContent='center'>
            <CircularProgress size={30} disableShrink />
          </Stack>
        </Paper>
      ) : (
        <Box mt={2}>
          {isTokenPayoutIndividual ? (
            <IndividualPayoutsTable tokenId={tokenId} />
          ) : (
            <SelectiveCashSharePayoutsTable tokenId={tokenId} />
          )}
        </Box>
      )}
    </Box>
  )
}

export default CustomTokenPayouts

import { SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { create } from 'zustand'

export interface Breadcrumbs {
  icon:
    | (OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
        muiName: string
      })
    | null
  list: { text: string; href?: string }[]
}
export interface BreadcrumbsStore {
  breadcrumbs: Breadcrumbs
  actions: {
    setBreadcrumbs: (breadcrumbs: Breadcrumbs) => void
  }
}

const useBreadcrumbsStore = create<BreadcrumbsStore>((set) => ({
  breadcrumbs: {
    icon: null,
    list: [],
  },
  actions: {
    setBreadcrumbs: (breadcrumbs: Breadcrumbs) =>
      set(() => ({
        breadcrumbs,
      })),
  },
}))

export const useBreadcrumbs = () => useBreadcrumbsStore((state) => state.breadcrumbs)

export const useBreadcrumbsActions = () => useBreadcrumbsStore((state) => state.actions)

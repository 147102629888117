import { Button, Stack, Typography } from '@mui/material'
import { useForm, Controller, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import PasswordField from 'auth/components/PasswordField'
import PasswordRequirements from 'auth/components/PasswordRequirements'
import { ChangePasswordFormValues, changePasswordFormSchema } from 'types/auth'
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar'
import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import { useMutation } from '@tanstack/react-query'
import { API_ENDPOINTS as API } from 'services/endpoints'

interface ChangePasswordProps {
  onReturnToAccount: () => void
}

const ChangePassword = ({ onReturnToAccount }: ChangePasswordProps) => {
  const enqueueSnackbar = useEnqueueSnackbar()

  const { mutate: changePassword, isPending: isChangePasswordPending } = useMutation<
    unknown,
    ErrorResponse & { password: string },
    ChangePasswordFormValues
  >({
    mutationFn: async (data) => baseApiClient.post(API.auth.passwordChange(), data),
    onSuccess: () => {
      enqueueSnackbar('Password changed successfully', { variant: 'success' })
      onReturnToAccount()
    },
    onError: (error) => {
      if (error.password) {
        enqueueSnackbar(error.password, { variant: 'error' })
        return
      }

      if (error.detail) {
        enqueueSnackbar(error.detail, { variant: 'error' })
        return
      }

      enqueueSnackbar('Something went wrong. Please try Again.', { variant: 'error' })
    },
  })

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<ChangePasswordFormValues>({
    resolver: zodResolver(changePasswordFormSchema),
    defaultValues: {
      old_password: '',
      password: '',
      confirm_password: '',
    },
    mode: 'onBlur',
  })

  const onSubmit = (data: ChangePasswordFormValues) => {
    changePassword(data)
  }

  const watchPassword = useWatch({
    control,
    name: 'password',
  })

  return (
    <Stack gap={2}>
      <Typography color='text.secondary'>Create a new password</Typography>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack gap={2}>
          <Controller
            name='old_password'
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <PasswordField
                {...field}
                id='old_password'
                label='Enter your current password'
                placeholder='Current password'
                showRequirements={false}
                error={error}
                data-testid='current-password'
              />
            )}
          />
          <Controller
            name='password'
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <PasswordField
                {...field}
                id='password'
                label='Enter your new password'
                placeholder='New password'
                showRequirements={false}
                error={error}
                helperText={error?.message}
                data-testid='new-password'
              />
            )}
          />
          <Controller
            name='confirm_password'
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <PasswordField
                {...field}
                id='confirm_password'
                label='Confirm your new password'
                placeholder='Confirm password'
                showRequirements={false}
                error={error}
                data-testid='confirm-password'
                helperText={error?.message}
              />
            )}
          />

          <PasswordRequirements password={watchPassword} />
        </Stack>
        <Stack direction='row' gap={2} justifyContent='flex-end' py={1}>
          <Button onClick={onReturnToAccount} data-testid='cancel-changes'>
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            color='primary'
            type='submit'
            disabled={isChangePasswordPending || !isValid}
            data-testid='password-save-changes'
          >
            Save Changes
          </LoadingButton>
        </Stack>
      </form>
    </Stack>
  )
}

export default ChangePassword

import {
  PaginatedProfitShareTransactionList,
  PaginatedCurrentUserPayoutList,
  profitShareTransactionSchema,
  currentUserPayoutSchema,
  userTokenBalanceSchema,
  UserTokenBalance,
} from 'types/token/profitShare'
import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import {
  PaginatedTokenTransactionList,
  TokenBalance,
  tokenTransactionSchema,
  tokenBalanceSchema,
} from 'types/token/profitShare'
import {
  PaginatedUserList,
  User,
  UserTotalPayout,
  UserProfile,
  userTotalPayoutSchema,
  userProfileSchema,
  userSchema,
} from 'types/user'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { API_ENDPOINTS as API } from 'services/endpoints'
import { QueryPagination } from 'types/common'
import { validateSchema } from 'utils/zod'

interface UseProfile<TData> {
  options?: Omit<UseQueryOptions<UserProfile, ErrorResponse, TData>, 'queryKey'>
}

export const useProfile = <TData = UserProfile>({ options }: UseProfile<TData>) => {
  return useQuery<UserProfile, ErrorResponse, TData>({
    queryKey: ['profile'],
    queryFn: async () => {
      const response = await baseApiClient.get(API.user.profile())
      const data = response.data
      return validateSchema(userProfileSchema, data, 'useProfile')
    },
    ...options,
  })
}

interface UseUserListProps<TData> {
  pagination?: QueryPagination
  searchTerm?: string
  status?: string
  accessLevel?: string
  organizationTier?: string
  employmentType?: string
  options?: Omit<UseQueryOptions<PaginatedUserList, ErrorResponse, TData>, 'queryKey'>
}

export const useUserList = <TData = PaginatedUserList>({
  pagination,
  searchTerm,
  status,
  accessLevel,
  organizationTier,
  employmentType,
  options,
}: UseUserListProps<TData>) => {
  return useQuery<PaginatedUserList, ErrorResponse, TData>({
    queryKey: [
      'users',
      pagination?.page,
      pagination?.pageSize,
      searchTerm,
      status,
      accessLevel,
      organizationTier,
      employmentType,
    ],
    queryFn: async () => {
      const response = await baseApiClient.get(API.user.list(), {
        params: {
          page: pagination?.page,
          page_size: pagination?.pageSize,
          q: searchTerm,
          s: status,
          role: accessLevel,
          ot: organizationTier,
          et: employmentType,
        },
      })
      const data = response.data
      return validateSchema(userSchema.array(), data, 'useUserList')
    },
    ...options,
  })
}

interface UseCurrentUserBalanceProps<TData> {
  options?: Omit<UseQueryOptions<TokenBalance[], ErrorResponse, TData>, 'queryKey'>
}

export const useCurrentUserBalance = <TData = TokenBalance[]>({
  options,
}: UseCurrentUserBalanceProps<TData>) => {
  return useQuery<TokenBalance[], ErrorResponse, TData>({
    queryKey: ['current-user-balance'],
    queryFn: async () => {
      const response = await baseApiClient.get(API.user.tokenBalance())
      const data = response.data
      return validateSchema(tokenBalanceSchema.array(), data, 'useCurrentUserBalance')
    },
    ...options,
  })
}

interface UseUserBalanceProps<TData> {
  userId: string
  options?: Omit<UseQueryOptions<UserTokenBalance[], ErrorResponse, TData>, 'queryKey'>
}

export const useUserBalance = <TData = UserTokenBalance[]>({
  userId,
  options,
}: UseUserBalanceProps<TData>) => {
  return useQuery<UserTokenBalance[], ErrorResponse, TData>({
    queryKey: ['user-balance', userId],
    queryFn: async () => {
      const response = await baseApiClient.get(API.user.userTokenBalance(userId))
      const data = response.data
      return validateSchema(userTokenBalanceSchema.array(), data, 'useUserBalance')
    },
    ...options,
  })
}

interface UseCurrentUserTransactionProps<TData> {
  searchTerm?: string
  tokenType?: string
  eventType?: 'exercised' | 'vested'
  pagination?: QueryPagination
  options?: Omit<UseQueryOptions<PaginatedTokenTransactionList, ErrorResponse, TData>, 'queryKey'>
}

export const useCurrentUserTransactions = <TData = PaginatedTokenTransactionList>({
  searchTerm,
  tokenType,
  eventType,
  pagination,
  options,
}: UseCurrentUserTransactionProps<TData>) => {
  return useQuery<PaginatedTokenTransactionList, ErrorResponse, TData>({
    queryKey: [
      'current-user-transactions',
      searchTerm,
      tokenType,
      eventType,
      pagination?.page,
      pagination?.pageSize,
    ],
    queryFn: async () => {
      const response = await baseApiClient.get(API.user.transaction(), {
        params: {
          q: searchTerm,
          t: tokenType ? tokenType.replace(/ /g, '') : undefined,
          p: eventType,
          page: pagination?.page,
          page_size: pagination?.pageSize,
        },
      })
      const data = response.data
      return validateSchema(tokenTransactionSchema.array(), data, 'useCurrentUserTransactions')
    },
    ...options,
  })
}

interface useCurrentUserProfitShareTransactionsProps<TData> {
  tokenId: string
  searchTerm?: string
  pagination?: QueryPagination
  options?: Omit<
    UseQueryOptions<PaginatedProfitShareTransactionList, ErrorResponse, TData>,
    'queryKey'
  >
}

export const useCurrentUserProfitShareTransactions = <TData = PaginatedProfitShareTransactionList>({
  tokenId,
  searchTerm,
  pagination,
  options,
}: useCurrentUserProfitShareTransactionsProps<TData>) => {
  return useQuery<PaginatedProfitShareTransactionList, ErrorResponse, TData>({
    queryKey: [
      'current-user-profit-share-transactions',
      tokenId,
      searchTerm,
      pagination?.page,
      pagination?.pageSize,
    ],
    queryFn: async () => {
      const response = await baseApiClient.get(API.user.profitShareTransactions(tokenId), {
        params: {
          q: searchTerm,
          page: pagination?.page,
          page_size: pagination?.pageSize,
        },
      })
      const data = response.data
      return validateSchema(
        profitShareTransactionSchema.array(),
        data,
        'useCurrentUserProfitShareTransactions',
      )
    },
    ...options,
  })
}

interface useCurrentUserPayoutsProps<TData> {
  tokenId: string
  searchTerm?: string
  pagination?: QueryPagination
  options?: Omit<UseQueryOptions<PaginatedCurrentUserPayoutList, ErrorResponse, TData>, 'queryKey'>
}

export const useCurrentUserPayouts = <TData = PaginatedCurrentUserPayoutList>({
  tokenId,
  searchTerm,
  pagination,
  options,
}: useCurrentUserPayoutsProps<TData>) => {
  return useQuery<PaginatedCurrentUserPayoutList, ErrorResponse, TData>({
    queryKey: ['current-user-payouts', tokenId, searchTerm, pagination?.page, pagination?.pageSize],
    queryFn: async () => {
      const response = await baseApiClient.get(API.user.payoutList(tokenId), {
        params: {
          q: searchTerm,
          page: pagination?.page,
          page_size: pagination?.pageSize,
        },
      })
      const data = response.data
      return validateSchema(currentUserPayoutSchema.array(), data, 'useCurrentUserPayouts')
    },
    ...options,
  })
}

interface UseTransactionProps<TData> {
  userId: string
  searchTerm?: string
  tokenType?: string
  pagination?: QueryPagination
  options?: Omit<UseQueryOptions<PaginatedTokenTransactionList, ErrorResponse, TData>, 'queryKey'>
}

export const useTransactions = <TData = PaginatedTokenTransactionList>({
  userId,
  searchTerm,
  tokenType,
  pagination,
  options,
}: UseTransactionProps<TData>) => {
  return useQuery<PaginatedTokenTransactionList, ErrorResponse, TData>({
    queryKey: [
      'user-transactions',
      userId,
      searchTerm,
      tokenType,
      pagination?.page,
      pagination?.pageSize,
    ],
    queryFn: async () => {
      const response = await baseApiClient.get(API.user.userTokenTransaction(userId), {
        params: {
          name: searchTerm,
          type: tokenType ? tokenType.replace(/ /g, '') : undefined,
          page: pagination?.page,
          page_size: pagination?.pageSize,
        },
      })
      const data = response.data
      return validateSchema(tokenTransactionSchema.array(), data, 'useTransactions')
    },
    ...options,
  })
}

interface UseUserDetailsProps<TData> {
  userId: string
  options?: Omit<UseQueryOptions<User, ErrorResponse, TData>, 'queryKey'>
}

export const useUserDetails = <TData = User>({ userId, options }: UseUserDetailsProps<TData>) => {
  return useQuery<User, ErrorResponse, TData>({
    queryKey: ['user', userId],
    queryFn: async () => {
      const response = await baseApiClient.get(API.user.details(userId))
      const data = response.data
      return validateSchema(userSchema, data, 'useUserDetails')
    },
    ...options,
  })
}

interface UseUserTotalPayoutsProps<TData> {
  options?: Omit<UseQueryOptions<UserTotalPayout[], ErrorResponse, TData>, 'queryKey'>
}

export const useUserTotalPayouts = <TData = UserTotalPayout[]>({
  options,
}: UseUserTotalPayoutsProps<TData>) => {
  return useQuery<UserTotalPayout[], ErrorResponse, TData>({
    queryKey: ['user-payouts'],
    queryFn: async () => {
      const response = await baseApiClient.get(API.user.totalPayouts())
      const data = response.data
      return validateSchema(userTotalPayoutSchema.array(), data, 'useUserTotalPayouts')
    },
    ...options,
  })
}

import { Typography, Button, Card, CardMedia, CardContent, CardActions } from '@mui/material'
import {
  useOnboardingTourCurrentStep,
  useOnboardingTourActions,
  NUMBER_OF_ONBOARDING_TOUR_STEPS,
} from 'hooks/useOnboardingTourStore'

interface TourCardProps {
  title: string
  description: string
  image?: string
}

const TourCard = ({ title, description, image }: TourCardProps) => {
  const tourCurrentStep = useOnboardingTourCurrentStep()
  const { nextTourStep, skipTour } = useOnboardingTourActions()
  return (
    <Card elevation={0}>
      {image ? (
        <CardMedia component='img' src={image} sx={{ width: 'auto', height: '162px' }} />
      ) : null}
      <CardContent sx={{ px: 3, pb: 1 }}>
        <Typography variant='h6'>{title}</Typography>
        <Typography variant='body2' mt={1}>
          {description}
        </Typography>
      </CardContent>
      <CardActions sx={{ px: 3, py: 2, justifyContent: 'space-between' }}>
        <Button size='small' sx={{ color: 'grey.600', fontSize: '12px' }} onClick={skipTour}>
          Skip tour
        </Button>
        <Typography variant='caption' color='text.secondary'>
          {tourCurrentStep} of {NUMBER_OF_ONBOARDING_TOUR_STEPS}
        </Typography>
        {tourCurrentStep === NUMBER_OF_ONBOARDING_TOUR_STEPS ? (
          <Button variant='contained' size='small' sx={{ fontSize: '12px' }} onClick={skipTour}>
            Finish
          </Button>
        ) : (
          <Button variant='contained' size='small' sx={{ fontSize: '12px' }} onClick={nextTourStep}>
            Next
          </Button>
        )}
      </CardActions>
    </Card>
  )
}

export default TourCard

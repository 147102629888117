import { useMemo } from 'react'
import { Stack, Typography, LinearProgress } from '@mui/material'
import { type MRT_ColumnDef } from 'material-react-table'
import { NumericFormat } from 'react-number-format'
import { StockOptionStatus } from 'types/token/stockOption'
import StockOptionStatusChip from 'components/status-chip/StockOptionStatusChip'
import Avatar from 'components/Avatar'
import { formatDate } from 'utils/dates'
import { UserTokenBalance } from 'types/token/profitShare'
import { GenericTokenIcon } from 'icons'
import KeeprTable from 'components/KeeprTable'

interface PeopleDetailsStockOptionsAssetsProps {
  userBalance: UserTokenBalance[]
}

const PeopleDetailsStockOptionsAssets = ({ userBalance }: PeopleDetailsStockOptionsAssetsProps) => {
  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'token.name',
          header: 'Name',
          Cell: ({ cell, row }) => {
            const tokenName = cell.getValue<string>()
            return (
              <Stack direction='row' alignItems='center' gap={1}>
                {row.original.token.logo_thumbnail ? (
                  <Avatar
                    type='round'
                    src={row.original.token.logo_thumbnail}
                    size={32}
                    name='Token Logo'
                  />
                ) : (
                  <GenericTokenIcon
                    sx={{ fontSize: 32, verticalAlign: 'middle', color: 'text.primary' }}
                  />
                )}
                <Stack overflow='hidden'>
                  <Typography variant='body2' noWrap={true}>
                    {tokenName}
                  </Typography>
                  <Typography variant='caption' color='text.secondary' noWrap={true}>
                    {row.original.token.abbreviation}
                  </Typography>
                </Stack>
              </Stack>
            )
          },
        },
        {
          accessorKey: 'stock_option.status',
          header: 'Status',
          Cell: ({ cell }) => {
            const status = cell.getValue<StockOptionStatus>()
            return <StockOptionStatusChip status={status} />
          },
        },
        {
          accessorKey: 'stock_option.total_options_amount',
          header: 'Granted',
          muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ cell }) => {
            const totalOptionsAmount = cell.getValue<number>() || 0
            return (
              <NumericFormat value={totalOptionsAmount} displayType={'text'} thousandSeparator />
            )
          },
        },
        {
          accessorKey: 'stock_option.grant_date',
          header: 'Grant Date',
          Cell: ({ cell }) => {
            const grantDate = cell.getValue<string>() || ''
            return <>{formatDate(grantDate, 'MMM. dd, yyyy')}</>
          },
        },
        {
          accessorKey: 'stock_option.vested_amount',
          header: 'Vested',
          Cell: ({ row, cell }) => {
            const vestedAmount = cell.getValue<number>() || 0
            const progress =
              (vestedAmount * 100) / (row.original.stock_option?.total_options_amount || 1)
            return (
              <Stack direction='row' gap={1} alignItems='center'>
                <LinearProgress
                  sx={{
                    height: '16px',
                    width: '50px',
                    borderRadius: '4px',
                    backgroundColor: 'divider',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: 'success.main',
                    },
                  }}
                  variant='determinate'
                  value={progress}
                />
                <NumericFormat value={vestedAmount} displayType={'text'} thousandSeparator />
              </Stack>
            )
          },
        },
        {
          accessorKey: 'stock_option.exercised_amount',
          header: 'Exercised',
          muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ cell }) => {
            const exercisedAmount = cell.getValue<number>() || 0
            return <NumericFormat value={exercisedAmount} displayType={'text'} thousandSeparator />
          },
        },
        {
          accessorKey: 'stock_option.strike_price',
          header: 'Exercise price',
          muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ row, cell }) => {
            const strikePrice = cell.getValue<number>() || 0
            return (
              <NumericFormat
                value={strikePrice}
                displayType='text'
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={row.original.stock_option?.currency === 'dollar' ? '$' : '€'}
              />
            )
          },
        },
      ] as MRT_ColumnDef<UserTokenBalance>[],
    [],
  )

  return (
    <KeeprTable
      columns={columns}
      data={userBalance}
      enableSorting={false}
      muiTablePaperProps={{
        elevation: 0,
      }}
    />
  )
}

export default PeopleDetailsStockOptionsAssets

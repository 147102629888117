import { Box } from '@mui/material'
import { HexColorPicker } from 'react-colorful'

type colorPickerProps = {
  color: string
  handleColor: (color: string) => void
}

const ColorPicker = ({ color, handleColor }: colorPickerProps) => {
  return (
    <Box
      sx={{
        '& .react-colorful': {
          width: '100%',
        },
      }}
    >
      <HexColorPicker color={color} onChange={handleColor} />
    </Box>
  )
}

export default ColorPicker

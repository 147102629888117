import React from 'react'
import ReactDOM from 'react-dom/client'
import App from 'App'
import { SnackbarProvider } from 'notistack'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ReactQueryProviderWrapper from 'components/ReactQueryProviderWrapper'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      autoHideDuration={3000}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ReactQueryProviderWrapper>
          <App />
        </ReactQueryProviderWrapper>
      </LocalizationProvider>
    </SnackbarProvider>
  </React.StrictMode>,
)

import { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import AddUsers from './AddUsers'
import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import { CustomTokenUsersSearch } from 'types/token/customToken'
import { API_ENDPOINTS as API } from 'services/endpoints'
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface AddUsersDialogProps {
  open: boolean
  handleClose: () => void
  tokenId: string
}

const AddUsersDialog = ({ open, handleClose, tokenId }: AddUsersDialogProps) => {
  const queryClient = useQueryClient()
  const enqueueSnackbar = useEnqueueSnackbar()

  const [selectedUsers, setSelectedUsers] = useState<CustomTokenUsersSearch[]>([])

  const { mutate: addUsers, isPending: isAddUsersLoading } = useMutation<
    { uuids: string[] },
    ErrorResponse,
    string[]
  >({
    mutationFn: (selectedUsers) =>
      baseApiClient
        .post(API.token.customTokenAddUsers(tokenId), { uuids: selectedUsers })
        .then((response) => response.data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['custom-token-user-list', tokenId] })
      queryClient.invalidateQueries({ queryKey: ['custom-token-users-search', tokenId] })
      handleClose()
    },
    onError: (error) => {
      if (error.detail) {
        enqueueSnackbar(error.detail, { variant: 'error' })
        return
      }
      enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' })
    },
  })

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='Add users'
      open={open}
      fullWidth={true}
      maxWidth='xs'
    >
      <DialogTitle>Add users</DialogTitle>

      <DialogContent sx={{ px: 3, py: 1, '&.MuiDialogContent-root': { pt: 1 } }}>
        <AddUsers
          tokenId={tokenId}
          selectedUsers={selectedUsers}
          onChange={(users) => setSelectedUsers(users)}
        />
      </DialogContent>

      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button variant='outlined' onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          variant='contained'
          loading={isAddUsersLoading}
          disabled={!selectedUsers.length}
          onClick={() => addUsers(selectedUsers.map((user) => user.uuid))}
        >
          Add users
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default AddUsersDialog

import { Add, CheckCircleOutline } from '@mui/icons-material'
import { Box, Link, List, ListItem, ListItemButton, Popover, Typography } from '@mui/material'

import Avatar from 'components/Avatar'
import { OrganizationTheme } from 'types/organization'
import { Stack } from '@mui/system'
import { redirectToSubdomain } from 'utils/domain'
import { useWorkspaceList } from 'services/api/workspace'
import { useRestrictedUse } from 'hooks/useOrganizationStore'

interface WorkspaceListProps {
  currentOrganization: OrganizationTheme
  anchorElOrganization: HTMLElement
  loggedInUUID: string
  onLinkWorkspace: () => void
  onUnlinkWorkspace: () => void
  handleClose: () => void
}

const WorkspaceList = ({
  currentOrganization,
  anchorElOrganization,
  loggedInUUID,
  onLinkWorkspace,
  onUnlinkWorkspace,
  handleClose,
}: WorkspaceListProps) => {
  const { data: workspaces } = useWorkspaceList({ userId: loggedInUUID })

  const restricted_use = useRestrictedUse()

  const handleConnectAccount = () => {
    onLinkWorkspace()
    handleClose()
  }

  const handleUnlinkAccount = () => {
    onUnlinkWorkspace()
    handleClose()
  }

  const onChangeWorkspace = (subdomain: string) => {
    if (subdomain !== currentOrganization.subdomain) {
      redirectToSubdomain(subdomain)
    }
  }

  return (
    <Popover
      open={Boolean(anchorElOrganization)}
      anchorEl={anchorElOrganization}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      marginThreshold={0}
    >
      <List component='nav' aria-label='Workspaces list' sx={{ minWidth: '300px' }}>
        <ListItem sx={{ marginBottom: '10px' }}>
          <Typography>
            <strong>Workspaces</strong>
          </Typography>
        </ListItem>
        {workspaces?.map((workspace) => (
          <ListItemButton
            key={workspace.subdomain}
            onClick={() => onChangeWorkspace(workspace.subdomain)}
          >
            <Box display='flex' alignItems='center' gap={1} flex={1}>
              <Avatar
                name={workspace.name}
                src={workspace.logo_thumbnail}
                size={48}
                sx={{ fontSize: 16 }}
                data-testid='organization-avatar'
              />
              <Stack flex={1}>
                <Stack justifyContent='space-between' direction='row'>
                  <Typography noWrap={true} data-testid='organization-name'>
                    {workspace.name}{' '}
                  </Typography>
                  {workspace.subdomain === currentOrganization.subdomain &&
                  workspaces.length > 1 ? (
                    <Link
                      role='button'
                      component='button'
                      variant='body2'
                      onClick={(e) => {
                        e.stopPropagation()
                        handleUnlinkAccount()
                      }}
                    >
                      Unlink
                    </Link>
                  ) : null}
                </Stack>
                <Typography noWrap={true} data-testid='organization-name'>
                  {workspace.email}
                </Typography>
              </Stack>
              {workspace.subdomain === currentOrganization.subdomain ? (
                <CheckCircleOutline color='success' />
              ) : (
                <Box width={36}></Box>
              )}
            </Box>
          </ListItemButton>
        ))}
        <ListItemButton
          sx={{ color: 'primary.main', marginTop: '10px' }}
          onClick={handleConnectAccount}
          disabled={restricted_use}
        >
          <Add /> <strong>Connect Account</strong>
        </ListItemButton>
      </List>
    </Popover>
  )
}

export default WorkspaceList

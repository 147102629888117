import { Stack, Typography } from '@mui/material'
import KeeprLogo from 'assets/keepr-logo.svg'

const OrganizationFreeUse = () => {
  return (
    <Stack width='100%' direction='row' gap={4} alignItems='center'>
      <img src={KeeprLogo} alt='Keepr logo' style={{ width: 120 }} />
      <Stack justifyContent='center' pt={0.5}>
        <Typography variant='body2' fontWeight='bold'>
          Premium for Causes
        </Typography>
        <Typography variant='caption'>All features, no cost</Typography>
      </Stack>
    </Stack>
  )
}

export default OrganizationFreeUse

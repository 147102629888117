import { PaletteOptions, SimplePaletteColorOptions, Color } from '@mui/material'
import { green } from '@mui/material/colors'

interface KeeperPaletteOptions extends PaletteOptions {
  green: Partial<Color>
  keepr: SimplePaletteColorOptions
}

const keeprColors = {
  main: '#007695',
  light: '#3f9ab2',
  dark: '#085265',
  contrastText: '#ffffff',
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    keepr: true
  }
}

declare module '@mui/material/Fab' {
  interface FabPropsColorOverrides {
    keepr: true
  }
}

export const lightPalette: KeeperPaletteOptions = {
  mode: 'light',
  green,
  primary: {
    main: keeprColors.main,
  },
  secondary: {
    main: '#003399',
  },
  error: {
    main: '#D32F2F',
  },
  warning: {
    main: '#ED6C02',
  },
  info: {
    main: '#0288D1',
  },
  success: {
    main: '#2E7D32',
  },
  text: {
    primary: '#1F1919',
    secondary: '#605858',
  },
  background: {
    default: '#fff',
  },
  divider: '#0000001f',
  keepr: keeprColors,
}

export const darkPalette: KeeperPaletteOptions = {
  mode: 'dark',
  green,
  primary: {
    main: keeprColors.main,
  },
  text: {
    primary: '#E9E9E9',
    secondary: '#ffffffb3',
  },
  background: {
    default: '#121212',
  },
  keepr: keeprColors,
}

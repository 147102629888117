import { useState } from 'react'
import GetStarted from './GetStarted'
import VerifyYourEmail from './VerifyYourEmail'
import AuthLayout from 'auth/components/AuthLayout'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

const reCaptchaKey =
  process.env.REACT_APP_GOOGLE_RECAPTCHA || '6LeDYFopAAAAAMf2Dbx7jnB-_iTGmnR3ksJTQX-d'

const Register = () => {
  const [registrationStep, setRegistrationStep] = useState<string>('get-started')
  const [currentEmail, setCurrentEmail] = useState<string>('')

  const changeRegistrationStep = (step: string) => {
    setRegistrationStep(step)
  }

  const changeCurrentEmail = (email: string) => {
    setCurrentEmail(email)
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey} useEnterprise={true}>
      <AuthLayout>
        {registrationStep === 'verify-email' ? (
          <VerifyYourEmail
            changeRegistrationStep={changeRegistrationStep}
            currentEmail={currentEmail}
          />
        ) : (
          <GetStarted
            changeRegistrationStep={changeRegistrationStep}
            currentEmail={currentEmail}
            changeCurrentEmail={changeCurrentEmail}
          />
        )}
      </AuthLayout>
    </GoogleReCaptchaProvider>
  )
}

export default Register

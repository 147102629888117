import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { StockOptionHolder } from 'types/token/stockOption'
import { Close as CloseIcon } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { startOfTomorrow } from 'date-fns'
import { formatDate } from 'utils/dates'
import { API_ENDPOINTS as API } from 'services/endpoints'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar'
import { isOverLimit } from 'utils/numbers'
import { MAX_DESC_NOTES_LENGTH } from 'constants/common'

export interface CompleteStockOptionDialogProps {
  open: boolean
  handleDialogClose: () => void
  holder: StockOptionHolder
}

const tomorrow = startOfTomorrow()

export const stockOptionsCompleteFormSchema = z.object({
  text: z.string(),
  expiration_date: z.coerce
    .date()
    .nullable()
    .refine(
      (val) => {
        if (!val) return true
        return val >= tomorrow
      },
      {
        message: 'Date must be in the future',
      },
    ),
})

type StockOptionsCompleteForm = z.infer<typeof stockOptionsCompleteFormSchema>

const DEFAULT_FORM_VALUES: StockOptionsCompleteForm = {
  text: '',
  expiration_date: null,
}

const CompeteStockOptionsDialog = ({
  open,
  handleDialogClose: onClose,
  holder,
}: CompleteStockOptionDialogProps) => {
  const queryClient = useQueryClient()
  const enqueueSnackbar = useEnqueueSnackbar()

  const { id: user_iso, user_full_name: fullName } = holder

  const { mutate: completeStockOption, isPending: isCompleteStockOptionPending } = useMutation<
    unknown,
    ErrorResponse,
    { text?: string; expiration_date?: string; user_iso: number }
  >({
    mutationFn: (data) => baseApiClient.post(API.token.stockOptionComplete(), data),
    onSuccess: () => {
      enqueueSnackbar('Stock option completed successfully.', { variant: 'success' })
      queryClient.invalidateQueries({ queryKey: ['stock-option-holders'] })
      onClose()
    },
    onError: (error) => {
      if (error.detail) {
        enqueueSnackbar(error.detail, { variant: 'error' })
        return
      }

      enqueueSnackbar('Something went wrong. Please try Again.', { variant: 'error' })
    },
  })

  const {
    control,
    handleSubmit,
    formState: { dirtyFields, isValid },
  } = useForm({
    mode: 'onBlur',
    defaultValues: DEFAULT_FORM_VALUES,
    resolver: zodResolver(stockOptionsCompleteFormSchema),
  })

  const onSubmit = (values: StockOptionsCompleteForm) => {
    const changedValues = Object.keys(values).reduce(
      (acc: Partial<StockOptionsCompleteForm>, field) => {
        const fieldKey = field as keyof StockOptionsCompleteForm
        if (dirtyFields[fieldKey]) {
          return { [fieldKey]: values[fieldKey], ...acc }
        }
        return { ...acc }
      },
      {},
    )

    const { expiration_date, ...rest } = changedValues

    if (expiration_date) {
      const formatExpirationDate = formatDate(expiration_date, 'yyyy-MM-dd')
      completeStockOption({ user_iso, expiration_date: formatExpirationDate, ...rest })
    } else {
      completeStockOption({ user_iso, ...rest })
    }
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth={true}>
      <DialogTitle>
        Complete Contract
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'text.primary',
            '& svg': {
              fontSize: 24,
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ px: 3, py: 1, '&.MuiDialogContent-root': { pt: 1 } }}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stack gap={2}>
            <Typography variant='body2'>
              Enter the reason for completing the options for <strong>{fullName}</strong>
            </Typography>

            <Controller
              name='text'
              control={control}
              render={({ field, fieldState: { error } }) => {
                const charCount = field.value?.length || 0
                // const isOverLimit = charCount >= NOTES_CHAR_LIMIT
                const isCompleteNotesOverLimit = isOverLimit(charCount, MAX_DESC_NOTES_LENGTH)
                return (
                  <TextField
                    label='Additional Notes (Optional)'
                    {...field}
                    multiline={true}
                    rows={4}
                    fullWidth
                    error={!!error || isCompleteNotesOverLimit}
                    helperText={`${charCount}/${MAX_DESC_NOTES_LENGTH}`}
                    inputProps={{ maxLength: MAX_DESC_NOTES_LENGTH }}
                    FormHelperTextProps={{
                      sx: { textAlign: 'right' },
                    }}
                    data-testid='notes-text-field'
                  />
                )
              }}
            />

            <Controller
              name='expiration_date'
              control={control}
              render={({ field: { onChange, value, onBlur, ...rest }, fieldState: { error } }) => (
                <Box width='50%'>
                  <FormControl fullWidth onBlur={onBlur}>
                    <DesktopDatePicker
                      label='Completion date (Optional)'
                      value={value}
                      disablePast
                      minDate={startOfTomorrow()}
                      onChange={onChange}
                      {...rest}
                      slotProps={{
                        textField: {
                          error: !!error,
                          helperText: error?.message,
                        },
                      }}
                      data-testid='complete-options-date-picker'
                    />
                  </FormControl>
                </Box>
              )}
            />

            <Stack direction='row' justifyContent='flex-end' gap={2} py={1}>
              <Button onClick={onClose}>Cancel</Button>
              <LoadingButton
                variant='contained'
                type='submit'
                disabled={!isValid || isCompleteStockOptionPending}
                data-testid='complete-grant'
              >
                Complete
              </LoadingButton>
            </Stack>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default CompeteStockOptionsDialog

import { useState } from 'react'
import ExerciseOptionsDialog from 'features/tokens/token/stock-options/stock-option-holders/stock-option-holder-details/dialogs/ExerciseOptionsDialog'
import CompeteStockOptionsDialog from 'features/tokens/token/stock-options/stock-option-holders/stock-option-holder-details/dialogs/CompeteStockOptionsDialog'
import StockOptionHolderMenu from 'features/tokens/token/stock-options/stock-option-holders/StockOptionHolderMenu'
import { StockOptionHolderPanelProps } from '../StockOptionHolderPanel'
import ExpireStockOptionDialog from './ExpireStockOptionDialog'
import DisqualifyStockOptionDialog from './DisqualifyStockOptionDialog'

const StockOptionHolderDialogs = ({ holder }: StockOptionHolderPanelProps) => {
  const [isExerciseOptionsDialogOpen, setIsExerciseOptionsDialogOpen] = useState<boolean>(false)
  const [isCompeteStockOptionsDialogOpen, setIsCompeteStockOptionsDialogOpen] =
    useState<boolean>(false)
  const [isExpireStockOptionsDialogOpen, setIsExpireStockOptionsDialogOpen] =
    useState<boolean>(false)
  const [isDisqualifyStockOptionsDialogOpen, setIsDisqualifyStockOptionsDialogOpen] =
    useState<boolean>(false)

  return (
    <>
      <StockOptionHolderMenu
        holderStatus={holder.status}
        holderId={holder.user}
        openExerciseOptionsDialog={() => {
          setIsExerciseOptionsDialogOpen(true)
        }}
        openCompeteStockOptionsDialog={() => {
          setIsCompeteStockOptionsDialogOpen(true)
        }}
        openExpireStockOptionsDialog={() => {
          setIsExpireStockOptionsDialogOpen(true)
        }}
        openDisqualifyStockOptionsDialog={() => {
          setIsDisqualifyStockOptionsDialogOpen(true)
        }}
      />
      {/* COMPLETE OPTIONS DIALOG*/}

      {isCompeteStockOptionsDialogOpen ? (
        <CompeteStockOptionsDialog
          open={isCompeteStockOptionsDialogOpen}
          handleDialogClose={() =>
            setIsCompeteStockOptionsDialogOpen(!isCompeteStockOptionsDialogOpen)
          }
          holder={holder}
        />
      ) : null}

      {/* EXPIRE OPTIONS DIALOG*/}

      {isExpireStockOptionsDialogOpen ? (
        <ExpireStockOptionDialog
          open={isExpireStockOptionsDialogOpen}
          handleDialogClose={() =>
            setIsExpireStockOptionsDialogOpen(!isExpireStockOptionsDialogOpen)
          }
          holder={holder}
        />
      ) : null}

      {/* DISQUALIFY OPTIONS DIALOG*/}

      {isDisqualifyStockOptionsDialogOpen ? (
        <DisqualifyStockOptionDialog
          open={isDisqualifyStockOptionsDialogOpen}
          handleDialogClose={() =>
            setIsDisqualifyStockOptionsDialogOpen(!isDisqualifyStockOptionsDialogOpen)
          }
          holder={holder}
        />
      ) : null}

      {/* EXERCISE OPTIONS DIALOG*/}
      {isExerciseOptionsDialogOpen ? (
        <ExerciseOptionsDialog
          open={isExerciseOptionsDialogOpen}
          handleDialogClose={() => setIsExerciseOptionsDialogOpen(!isExerciseOptionsDialogOpen)}
          holder={holder}
        />
      ) : null}
    </>
  )
}

export default StockOptionHolderDialogs

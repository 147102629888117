import { isPaginatedResponse } from 'types/common'
import { z } from 'zod'

const shouldLog = process.env.NODE_ENV === 'development'

export const validateSchema = <TSchema extends z.ZodSchema<unknown>, TData>(
  schema: TSchema,
  data: TData,
  identifier: string,
): TData => {
  let parseResult

  if (isPaginatedResponse(data)) {
    parseResult = schema.safeParse(data.results)
  } else {
    parseResult = schema.safeParse(data)
  }

  if (!parseResult.success && shouldLog) {
    console.groupCollapsed(`%c ##### invalid schema - ${identifier} #####`, 'color: red')
    console.log(parseResult.error.format())
    console.log(data)
    console.groupEnd()
  }

  return data
}

import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { Box } from '@mui/system'
import Navbar from 'components/navbar/Navbar'
import { SIDEBAR_WIDTH } from 'components/sidebar/Sidebar'
import { useOrganizationThemeColor } from 'hooks/useOrganizationStore'
import DrawerContainer from 'components/DrawerContainer'
import { CircularProgress, Stack } from '@mui/material'
import ChatBox from 'components/ChatBox'

const PageLayout = () => {
  const organizationThemeColor = useOrganizationThemeColor()

  return (
    <>
      <Box display='flex' minHeight='100vh'>
        <Navbar />

        <Box component='nav' width={SIDEBAR_WIDTH}>
          <DrawerContainer />
        </Box>

        <Stack
          component='main'
          width={`calc(100% - ${SIDEBAR_WIDTH}px)`}
          padding='32px 24px'
          sx={{
            background: `linear-gradient(179.1deg, ${organizationThemeColor} -37.3%, rgba(21, 49, 88, 0) 16.55%)`,
          }}
        >
          <Suspense
            fallback={
              <Stack height='100%' alignItems='center' justifyContent='center'>
                <CircularProgress />
              </Stack>
            }
          >
            <Outlet />
          </Suspense>
        </Stack>
      </Box>

      {window.location.hostname.includes('kt-qa') ? <ChatBox /> : null}
    </>
  )
}

export default PageLayout

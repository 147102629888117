import { Column, Workbook } from 'exceljs'
import { formatDate } from './dates'

//data is an array of generic type T
type ConvertDataToCSVProps<T> = {
  data: T[]
  columnNames: (keyof T)[]
}

export const convertDataToCSV = <T>({ data, columnNames }: ConvertDataToCSVProps<T>) => {
  if (!data.length) {
    console.error('No data to export')
    return
  }
  if (!columnNames.length) {
    console.error('No column names provided')
    return
  }

  const columnNamesString = columnNames.join(',')

  const csvContent =
    `${columnNamesString}\n` +
    data.map((item) => columnNames.map((columnName) => item[columnName]).join(',')).join('\n')

  return csvContent
}

interface ConvertExcelToCsv {
  file: File
  columns: Partial<Column>[]
  sheetName: string
  onSuccess: (file: Blob) => void
  onError: (error: string) => void
}

export const convertExcelToCsv = ({
  file,
  columns,
  sheetName,
  onSuccess,
  onError,
}: ConvertExcelToCsv) => {
  const workbook = new Workbook()
  const reader = new FileReader()

  reader.onload = () => {
    const buffer = reader.result as Buffer
    const records: string[] = []
    workbook.xlsx.load(buffer).then(async (workbook) => {
      const sheet = workbook.getWorksheet(sheetName)

      if (!sheet) {
        onError('Please use provided sample.')
        return
      }

      sheet.eachRow((row, rowNumber) => {
        // ignore header
        if (rowNumber === 1) return

        const recordsValues: string[] = []
        for (let i = 0; i < columns.length; i++) {
          if (row.getCell(i + 1).style.numFmt === 'dd/mm/yyyy') {
            const value = row.getCell(i + 1).text?.toString() || ''
            const date = formatDate(value, 'yyyy-MM-dd')
            recordsValues.push(date)
          } else {
            recordsValues.push(row.getCell(i + 1).text?.toString() || '')
          }
        }

        // we need to store values in csv format
        records.push(recordsValues.join(','))
      })

      if (!records.length) {
        onError("Provided file doesn't have any records. Please add at least one record.")
        return
      }

      const headerRow = columns.map((column) => column.key).join(',')
      const csv = [headerRow, ...records].join('\n')

      const blob = new Blob([csv], { type: 'text/csv' })
      onSuccess(blob)
    })
  }

  reader.readAsArrayBuffer(file)
}

import { Stack, Typography, Divider, Tooltip, Button, Chip, Box } from '@mui/material'
import { MRT_PaginationState, type MRT_ColumnDef } from 'material-react-table'
import {
  Download as DownloadIcon,
  Event as EventIcon,
  InfoOutlined as InfoOutlinedIcon,
} from '@mui/icons-material'
import { PAYOUT_STATUS } from 'types/token/profitShare'
import { NumericFormat } from 'react-number-format'
import { formatDate } from 'utils/dates'
import { LoadingButton } from '@mui/lab'
import { GenericTokenIcon } from 'icons'
import Avatar from 'components/Avatar'
import { CURRENCY_SYMBOL, ObjectValues } from 'types/common'
import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { API_ENDPOINTS as API } from 'services/endpoints'
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar'
import { useMemo, useState } from 'react'
import ConfirmDialog from 'components/ConfirmDialog'
import { useNavigate } from 'react-router-dom'
import { convertDataToCSV } from 'utils/csv'
import { downloadFile } from 'utils/file'
import { CustomTokenPayoutUser, CustomTokenPayout } from 'types/token/customToken'
import KeeprTable from 'components/KeeprTable'

import { useCustomTokenPayoutUserList } from 'services/api/customToken'

const PAYOUT_ACTION = {
  Create: 'create',
  Cancel: 'cancel',
} as const

type PayoutAction = ObjectValues<typeof PAYOUT_ACTION>

export interface CustomTokenUserPayoutData {
  id: number
  full_name: string
  email: string
  payout_balance: number
  payout_value: string
  net_payout_value: string
}

interface PreviewSelectiveCashSharePayoutProps {
  tokenId: string
  payout: CustomTokenPayout
  goBack?: () => void
  isEditMode?: boolean
}

const PreviewCustomTokenPayout = ({
  tokenId,
  payout,
  goBack,
  isEditMode = false,
}: PreviewSelectiveCashSharePayoutProps) => {
  const enqueueSnackbar = useEnqueueSnackbar()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    //material-react-table and tanstack table under the hood use zero based index, our back-end uses 1 based index
    pageIndex: 0,
    pageSize: 10,
  })

  const [isCreatePayoutDialogOpen, setIsCreatePayoutDialogOpen] = useState<boolean>(false)

  const { mutate: deleteCustomTokenPayoutPreview, isPending: isDeletePayoutPreviewPending } =
    useMutation<unknown, ErrorResponse>({
      mutationFn: async () =>
        baseApiClient
          .delete(API.token.customTokenPayoutById(tokenId, payout.id))
          .then((response) => response.data),
      onMutate: () => goBack?.(),
    })

  const { mutate: updateCustomTokenPayout, isPending: isUpdatePayoutPending } = useMutation<
    unknown,
    ErrorResponse,
    PayoutAction
  >({
    mutationFn: async (action) => {
      const data = action === PAYOUT_ACTION.Create ? { is_preview: false } : { status: 'canceled' }

      return baseApiClient
        .patch(API.token.customTokenPayoutById(tokenId, payout.id), data)
        .then((response) => response.data)
    },
    onSuccess: (_, action) => {
      queryClient.invalidateQueries({ queryKey: ['custom-token-payout-list', tokenId] })
      queryClient.invalidateQueries({ queryKey: ['custom-token-payout', tokenId, payout.id] })

      const successMsg =
        action === PAYOUT_ACTION.Create
          ? 'Payout is succesfully created.'
          : 'Payout is succesfully canceled.'

      enqueueSnackbar(successMsg, { variant: 'success' })
      navigate(`/assets/custom-asset/${tokenId}/payouts`)
    },
    onError: (error) => {
      if (error?.detail) {
        enqueueSnackbar(error.detail, { variant: 'error' })
        return
      }
      enqueueSnackbar('Something went wrong. Please try Again.', { variant: 'error' })
    },
  })

  const { mutate: downloadUsersPayoutCsv, isPending: isUsersPayoutCsvDownloading } = useMutation<
    CustomTokenUserPayoutData[],
    ErrorResponse
  >({
    mutationFn: async () =>
      baseApiClient
        .get(API.token.customTokenPayoutData(payout.id))
        .then((response) => response.data),
    onSuccess: (data) => {
      const csvContent =
        convertDataToCSV({
          data,
          columnNames: ['email', 'full_name', 'payout_balance', 'payout_value', 'net_payout_value'],
        }) || ''

      downloadFile({ file: csvContent, name: 'payout' })
    },
    onError: (error) => {
      if (error?.detail) {
        enqueueSnackbar(error.detail, { variant: 'error' })
        return
      }
      enqueueSnackbar('Something went wrong. Please try Again.', { variant: 'error' })
    },
  })

  const { data: customTokenPayoutUserList, isLoading: isCustomTokenPayoutUserListLoading } =
    useCustomTokenPayoutUserList({
      payoutId: payout.id,
      pagination: {
        page: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
      },
      options: {
        placeholderData: (previousData) => previousData,
      },
    })

  const tableData = customTokenPayoutUserList?.results || []

  const columns = useMemo(
    () =>
      [
        {
          header: 'Name',
          accessorKey: 'full_name',
          enableSorting: false,
          size: 220,
          Cell: ({ cell, row }) => {
            const fullName = cell.getValue<string>()
            return (
              <Stack direction='row' alignItems='center' gap={1} data-testid='cell-name'>
                <Avatar name={fullName} src={row.original.avatar_thumbnail} size={40} />
                <Stack overflow='hidden'>
                  <Typography variant='body2' noWrap={true} color='text.primary'>
                    {fullName}
                  </Typography>
                  <Typography variant='caption' color='text.secondary' noWrap={true}>
                    {row.original.email}
                  </Typography>
                </Stack>
              </Stack>
            )
          },
        },
        {
          header: 'Payout balance',
          accessorKey: 'payout_balance',
          enableSorting: false,
          size: 150,
          muiTableHeadCellProps: {
            align: 'center',
          },
          muiTableBodyCellProps: {
            align: 'center',
          },
          Cell: ({ cell }) => {
            return (
              <Typography variant='body2' color='text.primary'>
                <NumericFormat
                  value={cell.getValue<number>()}
                  displayType={'text'}
                  thousandSeparator
                  decimalScale={2}
                />
              </Typography>
            )
          },
        },
        {
          header: 'Payout value',
          accessorKey: 'payout_value',
          enableSorting: false,
          size: 150,
          muiTableHeadCellProps: {
            align: 'center',
          },
          muiTableBodyCellProps: {
            align: 'center',
          },
          Cell: ({ cell }) => {
            return (
              <Typography variant='body2' color='text.primary'>
                <NumericFormat
                  value={cell.getValue<string>()}
                  displayType={'text'}
                  thousandSeparator
                  decimalScale={2}
                  prefix={CURRENCY_SYMBOL[payout.currency]}
                />
              </Typography>
            )
          },
        },
        {
          header: 'Net payout value',
          accessorKey: 'net_payout_value',
          enableSorting: false,
          size: 150,
          muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ cell }) => {
            return (
              <Typography variant='body2' color='text.primary'>
                <NumericFormat
                  value={cell.getValue<string>()}
                  displayType={'text'}
                  thousandSeparator
                  decimalScale={2}
                  prefix={CURRENCY_SYMBOL[payout.currency]}
                />
              </Typography>
            )
          },
        },
      ] as MRT_ColumnDef<CustomTokenPayoutUser>[],
    [payout.currency],
  )

  return (
    <>
      <Stack direction='row' alignItems='center' gap={1}>
        {/* Individual payouts do not have names, nor they have start and end dates. */}
        {payout.name && (
          <Typography variant='h5' py={3}>
            {payout.name}
          </Typography>
        )}
        {payout.start_dt && payout.end_dt && (
          <Chip
            size='small'
            icon={<EventIcon />}
            label={`${formatDate(payout.start_dt, 'MMM. dd, yyyy')} - ${formatDate(
              payout.end_dt,
              'MMM. dd, yyyy',
            )}`}
            variant='outlined'
          />
        )}
        {isEditMode ? (
          <Typography variant='caption' color='text.secondary'>
            Created by {payout.created_by_full_name}
          </Typography>
        ) : null}
        <LoadingButton
          variant='text'
          sx={{ ml: 'auto' }}
          startIcon={<DownloadIcon />}
          onClick={() => downloadUsersPayoutCsv()}
          loading={isUsersPayoutCsvDownloading}
          data-testid='csv-payout'
        >
          Download csv
        </LoadingButton>
      </Stack>
      <Stack direction='row' gap={2} divider={<Divider orientation='vertical' flexItem />}>
        <Stack gap={1}>
          <Stack direction='row' gap={2} alignItems='center'>
            <Typography color='text.secondary' width='100px'>
              Total payout
            </Typography>
            <Typography variant='h5' component='p'>
              <NumericFormat
                value={payout.payout_value}
                displayType={'text'}
                thousandSeparator
                decimalScale={2}
                prefix={CURRENCY_SYMBOL[payout.currency]}
              />
            </Typography>
          </Stack>
          <Stack direction='row' gap={2} alignItems='center'>
            <Typography color='text.secondary' width='100px'>
              Assets
            </Typography>
            <Stack direction='row' alignItems='center' gap={0.5}>
              <Typography variant='h5' component='p'>
                <NumericFormat value={payout.token_amount} displayType={'text'} thousandSeparator />
              </Typography>
              {payout.token_logo ? (
                <Avatar type='round' src={payout.token_logo} size={20} name='Asset Logo' />
              ) : (
                <GenericTokenIcon sx={{ fontSize: 20 }} />
              )}
            </Stack>
          </Stack>
        </Stack>

        <Stack gap={1}>
          <Stack direction='row' gap={2} alignItems='center'>
            <Stack direction='row' gap={0.5} alignItems='center' width='110px'>
              <Typography color='text.secondary'>Asset value</Typography>
              <Tooltip placement='top' title='This is an approximate value'>
                <InfoOutlinedIcon sx={{ fontSize: 16 }} />
              </Tooltip>
            </Stack>
            <Typography variant='h5' component='p'>
              <NumericFormat
                value={payout.token_value}
                displayType={'text'}
                thousandSeparator
                prefix={CURRENCY_SYMBOL[payout.currency]}
              />
            </Typography>
          </Stack>
          <Stack direction='row' gap={2} alignItems='center'>
            <Typography color='text.secondary' width='110px'>
              Payout date
            </Typography>
            <Typography variant='h5' component='p'>
              {formatDate(payout.payout_dt, 'MMM. dd, yyyy')}
            </Typography>
          </Stack>
        </Stack>

        <Stack gap={1}>
          <Stack direction='row' gap={2} alignItems='center'>
            <Stack direction='row' gap={0.5} alignItems='center' width='140px'>
              <Typography color='text.secondary'>Service fee</Typography>
              <Tooltip
                placement='top'
                title='This is the amount of service fee that is either deducted from or added to the payout amount.'
                componentsProps={{ tooltip: { sx: { width: '170px' } } }}
              >
                <InfoOutlinedIcon sx={{ fontSize: 16 }} />
              </Tooltip>
            </Stack>
            <Typography variant='h5' component='p'>
              <NumericFormat
                value={payout.payout_fee}
                displayType={'text'}
                thousandSeparator
                prefix={CURRENCY_SYMBOL[payout.currency]}
              />
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Divider sx={{ my: 2 }} />
      <Box>
        <KeeprTable
          columns={columns}
          data={tableData}
          onPaginationChange={setPagination}
          rowCount={customTokenPayoutUserList?.count ?? 0}
          state={{
            pagination,
            showSkeletons: isCustomTokenPayoutUserListLoading,
            showProgressBars: isCustomTokenPayoutUserListLoading,
          }}
          muiTableBodyProps={{
            title: 'Payout details table',
          }}
        />
      </Box>
      <Divider sx={{ my: 1 }} />
      {payout.status === PAYOUT_STATUS.Pending ? (
        <Stack direction='row' justifyContent='flex-end' gap={1} mt={4}>
          <LoadingButton
            variant='outlined'
            onClick={() =>
              isEditMode
                ? updateCustomTokenPayout(PAYOUT_ACTION.Cancel)
                : deleteCustomTokenPayoutPreview()
            }
            loading={isDeletePayoutPreviewPending}
          >
            {isEditMode ? 'Cancel payout' : 'Back'}
          </LoadingButton>
          {!isEditMode ? (
            <Button variant='contained' onClick={() => setIsCreatePayoutDialogOpen(true)}>
              Create payout
            </Button>
          ) : null}
        </Stack>
      ) : null}
      {isCreatePayoutDialogOpen ? (
        <ConfirmDialog
          isOpen={true}
          handleClose={() => setIsCreatePayoutDialogOpen(false)}
          title='Create payout?'
          content={`By creating this payout, the ${
            payout.users_count
          } payees and admins will be alerted via email on the payout date (${formatDate(
            payout.payout_dt,
            'MMM. dd, yyyy',
          )}). Payouts cannot be altered after this date. Are you sure you wish to proceed?`}
          onCancel={() => setIsCreatePayoutDialogOpen(false)}
          cancelText='Not yet'
          onConfirm={() => updateCustomTokenPayout(PAYOUT_ACTION.Create)}
          confirmText='Create payout'
          confirmType='primary'
          isLoading={isUpdatePayoutPending}
          maxWidth='xs'
        />
      ) : null}
    </>
  )
}

export default PreviewCustomTokenPayout

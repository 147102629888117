import { APP_DEFAULT_SUBDOMAIN, CURRENT_SUBDOMAIN } from 'App'
import { useIsUserAuthenticated, useUserAuthActions } from 'hooks/useUserAuthStore'

import AuthLayout from '../components/AuthLayout'
import ConfirmYourOrganizationForm from './ConfirmYourOrganizationForm'
import { LoginCredentials } from 'types/auth'
import LoginForm from './LoginForm'
import { Link, Navigate } from 'react-router-dom'
import { OrganizationTheme } from 'types/organization'
import { redirectToSubdomain } from 'utils/domain'
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar'
import { useOrganizationName } from 'hooks/useOrganizationStore'
import { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { AuthTokens, LoginFormValues } from 'types/auth'
import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import { API_ENDPOINTS as API } from 'services/endpoints'
import { useMutation } from '@tanstack/react-query'

const Login = () => {
  const isUserAuthenticated = useIsUserAuthenticated()
  const { setAccessToken, setRefreshToken } = useUserAuthActions()

  const organizationName = useOrganizationName()
  const enqueueSnackbar = useEnqueueSnackbar()
  const [loginError, setLoginError] = useState<string | null>(null)

  const { mutate: login, isPending: isLoginPending } = useMutation<
    AuthTokens,
    ErrorResponse,
    LoginFormValues
  >({
    mutationFn: async (credentials) =>
      baseApiClient
        .post(API.auth.login(), credentials)
        .then((response) => response.data as AuthTokens),
    onSuccess: (tokens) => {
      setAccessToken(tokens.access)
      setRefreshToken(tokens.refresh)
    },
    onError: (error) => {
      if (error.detail) {
        setLoginError(error.detail)
        return
      }

      enqueueSnackbar('Something went wrong. Please try Again.', { variant: 'error' })
    },
  })

  const onSubmit = (credentials: LoginCredentials) => {
    login({ ...credentials, subdomain: CURRENT_SUBDOMAIN })
  }

  const handleConfirmOrganization = (organization: OrganizationTheme) => {
    redirectToSubdomain(organization.subdomain)
  }

  if (isUserAuthenticated) {
    return <Navigate to={'/'} />
  }

  if (CURRENT_SUBDOMAIN === APP_DEFAULT_SUBDOMAIN) {
    return (
      <AuthLayout data-testid='enter-organization-form'>
        <ConfirmYourOrganizationForm onConfirmOrganization={handleConfirmOrganization} />
        <Box mt={3} display='flex' alignItems='center' justifyContent='flex-end' gap={1}>
          <Typography variant='body2' color='text.secondary'>
            Not registered?{' '}
            <Typography
              component={Link}
              to='/register'
              variant='body2'
              color='primary.main'
              data-testid='register-link'
            >
              Click here.
            </Typography>
          </Typography>
        </Box>
      </AuthLayout>
    )
  }

  return (
    <AuthLayout data-testid='login-form'>
      <LoginForm
        organizationName={organizationName}
        isLoginLoading={isLoginPending}
        loginError={loginError}
        onSubmit={onSubmit}
        onChangeOrganization={() => {
          redirectToSubdomain('app')
        }}
      />
    </AuthLayout>
  )
}
export default Login

import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { passwordSchema } from 'types/auth'
import { Typography, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import PasswordField from 'auth/components/PasswordField'

const FORM_DEFAULT_VALUES = {
  password: '',
}

interface ConfirmUnlinkWorkspaceFormProps {
  organizationName: string
  email: string
  isConfirmLoading: boolean
  authError?: string | null
  onSubmit: (credentials: { password: string }) => void
}

const ConfirmUnlinkWorkspaceForm = ({
  organizationName,
  email,
  isConfirmLoading,
  authError,
  onSubmit,
}: ConfirmUnlinkWorkspaceFormProps) => {
  const { handleSubmit, control } = useForm<{ password: string }>({
    mode: 'onBlur',
    resolver: zodResolver(passwordSchema),
    defaultValues: FORM_DEFAULT_VALUES,
  })

  return (
    <>
      <Typography variant='body2' color='text.secondary'>
        Confirm unlink from {organizationName} account
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box display='flex' flexDirection='column' gap={2} mt={4}>
          <Typography variant='body2' color='text.secondary' align='center'>
            {email}
          </Typography>
          <Controller
            name='password'
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <PasswordField
                showRequirements={false}
                value={field.value}
                error={error}
                onChange={field.onChange}
                data-testid='confirm-password'
              />
            )}
          />

          <LoadingButton
            sx={{ mt: 1.5 }}
            variant='contained'
            type='submit'
            loading={isConfirmLoading}
            data-testid='confirm-unlink'
          >
            Confirm unlink
          </LoadingButton>

          {authError ? (
            <Typography variant='caption' sx={{ color: 'error.main' }}>
              {authError}
            </Typography>
          ) : null}
        </Box>
      </form>
    </>
  )
}
export default ConfirmUnlinkWorkspaceForm

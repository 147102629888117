import { useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material'
import { CURRENCY_SYMBOL } from 'types/common'
import { NumericFormat } from 'react-number-format'
import { StockOptionHolderPanelProps } from './StockOptionHolderPanel'
import { formatDate } from 'utils/dates'
import { grey } from '@mui/material/colors'
import {
  PictureAsPdf as PdfIcon,
  CloudUpload as CloudUploadIcon,
  CloudDownload as CloudDownloadIcon,
} from '@mui/icons-material'
import { document } from 'constants/allowedFiles'
import { LoadingButton } from '@mui/lab'
import Upload from 'components/Upload'

import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import { API_ENDPOINTS as API } from 'services/endpoints'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar'
import { useOutletContext } from 'react-router-dom'
import { STOCK_OPTION_STATUS } from 'types/token/stockOption'

type StockOptionDocument = {
  document: File | null
}

const StockOptionHolderOverview = ({ holder }: StockOptionHolderPanelProps) => {
  const tokenId = useOutletContext<string>()

  const [isFileUploadOpen, setIsFileUploadOpen] = useState<boolean>(false)

  const [uploadedDocument, setUploadedDocument] = useState<File | null>(null)

  const [isValidationError, setIsValidationError] = useState<boolean>(false)

  const queryClient = useQueryClient()

  const enqueueSnackbar = useEnqueueSnackbar()

  const onDocumentUpload = (file: File) => {
    setUploadedDocument(file)
  }

  const { mutate: updateGrantOptionsDocument, isPending: isUpdateGrantOptionsDocumentPending } =
    useMutation<StockOptionDocument, ErrorResponse, StockOptionDocument>({
      mutationFn: (data) => {
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }

        const formData = new FormData()

        formData.append('document', data.document || '')

        return baseApiClient.patch(
          API.token.stockOptionHolder(tokenId, holder.id),
          formData,
          config,
        )
      },
      onSuccess: () => {
        enqueueSnackbar('Grant options document successfully updated.', { variant: 'success' })
        queryClient.invalidateQueries({ queryKey: ['stock-option-holders', `${tokenId}`] })
        queryClient.invalidateQueries({ queryKey: ['users'] })
        queryClient.invalidateQueries({ queryKey: ['tokens', `${tokenId}`] })
      },
      onError: (error) => {
        if (error.detail) {
          enqueueSnackbar(error.detail, { variant: 'error' })
        } else {
          enqueueSnackbar('Something went wrong. Please try Again.', { variant: 'error' })
        }
      },
    })

  return (
    <>
      <Stack
        flex={1}
        gap={1}
        pl={{ md: 1 }}
        pr={{ md: 3 }}
        sx={{ borderRight: { md: `1px solid ${grey[300]}` } }}
      >
        <Typography variant='h6'>Overview</Typography>
        <Stack direction='row' gap={2} justifyContent='space-between'>
          <Typography variant='body2'>Vesting progress</Typography>
          <Stack flex={1} sx={{ maxWidth: '250px' }}>
            <LinearProgress
              sx={{
                height: '16px',
                borderRadius: '4px',
                backgroundColor: 'divider',
                '& .MuiLinearProgress-bar': {
                  backgroundColor:
                    holder.status === STOCK_OPTION_STATUS.Disqualified ||
                    holder.status === STOCK_OPTION_STATUS.Expired
                      ? grey[400]
                      : 'success.main',
                },
              }}
              variant='determinate'
              value={(holder.vested_amount * 100) / holder.total_options_amount}
            />
            <Typography variant='body2'>
              <strong>
                <NumericFormat value={holder.vested_amount} displayType='text' thousandSeparator />
              </strong>{' '}
              of{' '}
              <strong>
                <NumericFormat
                  value={holder.total_options_amount}
                  displayType='text'
                  thousandSeparator
                />
              </strong>{' '}
              options vested
            </Typography>
          </Stack>
        </Stack>
        <Stack direction='row' justifyContent='space-between'>
          <Typography variant='body2'>Grant date</Typography>
          <Typography>{formatDate(holder.grant_date, 'MMM. dd, yyyy')}</Typography>
        </Stack>
        {holder.cliff_period ? (
          <Stack direction='row' justifyContent='space-between'>
            <Typography variant='body2'>Cliff period</Typography>
            <Typography>{holder.cliff_period}</Typography>
          </Stack>
        ) : null}
        <Stack direction='row' justifyContent='space-between'>
          <Typography variant='body2'>Expiry date</Typography>
          <Typography>{formatDate(holder.expiration_date, 'MMM. dd, yyyy')}</Typography>
        </Stack>
        <Stack direction='row' justifyContent='space-between'>
          <Typography variant='body2'>Options granted</Typography>
          <Typography>
            <NumericFormat
              value={holder.total_options_amount}
              displayType='text'
              thousandSeparator
            />
          </Typography>
        </Stack>
        <Stack direction='row' justifyContent='space-between'>
          <Typography variant='body2'>Exercise price</Typography>
          <Typography>
            {CURRENCY_SYMBOL[holder.currency]}
            {holder.strike_price}
          </Typography>
        </Stack>
        <Stack direction='row' justifyContent='space-between' alignItems='center' flexWrap='wrap'>
          <Typography variant='body2'>Document</Typography>

          {holder.document ? (
            <Box display='flex' gap={2}>
              <Button
                startIcon={<CloudDownloadIcon />}
                component='a'
                href={holder.document}
                download={holder.document}
                target='_blank'
                data-testid='stock_option-contract'
                variant='outlined'
              >
                Download
              </Button>
              <LoadingButton
                disabled={
                  isUpdateGrantOptionsDocumentPending ||
                  holder.status === STOCK_OPTION_STATUS.Disqualified ||
                  holder.status === STOCK_OPTION_STATUS.Expired
                }
                loading={isUpdateGrantOptionsDocumentPending}
                onClick={() => {
                  updateGrantOptionsDocument({ document: null })
                }}
              >
                Remove
              </LoadingButton>
            </Box>
          ) : (
            <Button
              startIcon={<CloudUploadIcon />}
              onClick={() => setIsFileUploadOpen(!isFileUploadOpen)}
              disabled={
                holder.status === STOCK_OPTION_STATUS.Disqualified ||
                holder.status === STOCK_OPTION_STATUS.Expired
              }
            >
              Upload
            </Button>
          )}
        </Stack>
      </Stack>
      {/* UPLOAD DOCUMENT DIALOG */}
      {isFileUploadOpen ? (
        <Dialog open={isFileUploadOpen}>
          <DialogTitle>Upload Document</DialogTitle>
          <DialogContent>
            <Typography variant='body2' color='text.secondary'>
              Upload a document to share with the stakeholder.
            </Typography>

            <Upload
              allowedFiles={document}
              fileIcon={PdfIcon}
              onFileChange={onDocumentUpload}
              setIsValidationError={setIsValidationError}
              isValidationError={isValidationError}
            >
              <Typography variant='body2' color='text.secondary' mt={1}>
                PDF file is required (max. 3MB).
              </Typography>
            </Upload>
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={() => setIsFileUploadOpen(!isFileUploadOpen)}>
              Cancel
            </Button>
            <LoadingButton
              disabled={!uploadedDocument || isValidationError}
              loading={isUpdateGrantOptionsDocumentPending}
              variant='contained'
              onClick={() => {
                if (uploadedDocument) {
                  updateGrantOptionsDocument({ document: uploadedDocument })
                }
                setIsFileUploadOpen(!isFileUploadOpen)
              }}
            >
              Upload
            </LoadingButton>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  )
}

export default StockOptionHolderOverview

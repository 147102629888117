import {
  MaterialReactTable,
  MRT_RowData,
  MRT_TableOptions,
  useMaterialReactTable,
} from 'material-react-table'
interface KeeprTableProps<TData extends MRT_RowData> extends MRT_TableOptions<TData> {
  globalFilterPlaceholder?: string
}

const KeeprTable = <TData extends MRT_RowData>({
  globalFilterPlaceholder,
  ...materialTableOptions
}: KeeprTableProps<TData>) => {
  const table = useMaterialReactTable({
    // table settings setup
    enableColumnFilters: false,
    enableColumnActions: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableFilters: false,
    enableHiding: false,
    // table filters setup
    manualFiltering: materialTableOptions.onGlobalFilterChange !== undefined,
    initialState: {
      showGlobalFilter: materialTableOptions.onGlobalFilterChange !== undefined,
      pagination: { pageIndex: 0, pageSize: 5 },
    },
    positionGlobalFilter: 'left',

    // table pagination setup
    manualPagination: materialTableOptions.onPaginationChange !== undefined,

    // table top toolbar setup
    enableTopToolbar:
      materialTableOptions.onGlobalFilterChange !== undefined ||
      materialTableOptions.renderTopToolbarCustomActions !== undefined,

    // table styles
    muiSearchTextFieldProps: {
      placeholder: globalFilterPlaceholder,
      sx: {
        width: '100%',
        minWidth: '240px',
        maxWidth: '300px',
        '& .MuiSvgIcon-root': {
          fontSize: 24,
          color: 'text.primary',
        },
      },
      variant: 'outlined',
      size: 'small',
    },
    muiTableProps: {
      sx: {
        tableLayout: 'fixed',
      },
    },
    defaultColumn: {
      minSize: 30,
    },
    muiTableBodyRowProps: {
      sx: {
        '&.Mui-selected': {
          background: 'transparent',
        },
      },
    },
    muiTopToolbarProps: {
      sx: {
        overflow: 'auto',
        '& .MuiFormControl-root': {
          paddingY: 2,
          paddingLeft: 1,
        },
      },
    },
    ...materialTableOptions,
  })
  return <MaterialReactTable table={table} />
}

export default KeeprTable

import { SvgIcon } from '@mui/material'
import { SvgIconProps } from '@mui/material'

const ReplayIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg viewBox='0 0 12 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M6 3.75V0.75L2.25 4.5L6 8.25V5.25C8.4825 5.25 10.5 7.2675 10.5 9.75C10.5 12.2325 8.4825 14.25 6 14.25C3.5175 14.25 1.5 12.2325 1.5 9.75H0C0 13.065 2.685 15.75 6 15.75C9.315 15.75 12 13.065 12 9.75C12 6.435 9.315 3.75 6 3.75Z'
          fill='currentColor'
        />
        <path
          d='M6.44643 8.73214H5.55357L4.125 12.75H4.97321L5.28571 11.8571H6.71429L7.02679 12.75H7.875L6.44643 8.73214ZM5.48661 11.2545L6 9.625L6.51339 11.2545H5.48661Z'
          fill='currentColor'
        />
      </svg>
    </SvgIcon>
  )
}

export default ReplayIcon

import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface UserAuthStore {
  isUserAuthenticated: boolean
  accessToken: string
  refreshToken: string
  actions: {
    setAccessToken: (token: string) => void
    setRefreshToken: (token: string) => void
    clearAuthTokens: () => void
  }
}

export const useUserAuthStore = create<UserAuthStore>()(
  persist(
    (set) => ({
      isUserAuthenticated: false,
      accessToken: '',
      refreshToken: '',
      actions: {
        setAccessToken: (token) =>
          set(() => ({
            accessToken: token,
            isUserAuthenticated: true,
          })),
        setRefreshToken: (token) =>
          set(() => ({
            refreshToken: token,
          })),
        clearAuthTokens: () =>
          set(() => ({
            isUserAuthenticated: false,
            accessToken: '',
            refreshToken: '',
          })),
      },
    }),
    {
      name: 'user-auth-store',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      partialize: ({ actions, ...rest }) => rest,
    },
  ),
)

export const useIsUserAuthenticated = () => useUserAuthStore((state) => state.isUserAuthenticated)
export const useUserAuthActions = () => useUserAuthStore((state) => state.actions)
export const useAccessToken = () => useUserAuthStore((state) => state.accessToken)

export const emailRegexExpression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
export const uuidRegex =
  /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/

export const validateEmail = (email: string) => {
  return emailRegexExpression.test(String(email).toLowerCase())
}

export const validateUUID = (uuid: string) => {
  if (!uuidRegex.test(uuid)) {
    return false
  }
  return true
}

export const passwordRegexExpression = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/

export const validatePassword = (password: string) => {
  return passwordRegexExpression.test(String(password))
}

export const hexColorRegexExpression = /^#(?:[0-9a-fA-F]{3}){1,2}$/

export const validateHexColor = (color: string) => {
  return hexColorRegexExpression.test(String(color))
}

export const validateAlpha = (value: string) => {
  const alphaRegex = /^[a-zA-Z¤]+$/i
  return alphaRegex.test(value)
}

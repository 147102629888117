//for generating arrays of numbers that represent available months and years
//used in grant token options form

export const generateNumbers = (start: number, end: number): number[] => {
  const numbers: number[] = []
  for (let i = start; i <= end; i++) {
    numbers.push(i)
  }
  return numbers
}

export const convertToPercentage = (value: number | string | null, precision = 2) => {
  if (value === null || value === '') return 0
  const numberValue = typeof value === 'string' ? parseFloat(value) : value
  const multipliedValue = numberValue * 100
  const roundedValue = multipliedValue.toFixed(precision) // Required to bypass javascript decimals calculation issues

  return Number(roundedValue)
}

export const convertToDecimalPercentage = (
  value: string | number | undefined,
  precision = 2,
  defaultValue: null | undefined | string = null,
) => {
  if (value === undefined || value === '') return defaultValue

  let numericValue

  if (typeof value === 'string') {
    const rawValue = value.replace(/[^0-9.-]+/g, '')
    numericValue = Number(rawValue)
  } else {
    numericValue = value
  }

  return Number((numericValue / 100).toFixed(precision))
}

export const isOverLimit = (value: number, limit: number) => {
  return value > limit
}

import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const NUMBER_OF_ONBOARDING_TOUR_STEPS = 6

interface OnboardingTourStore {
  currentTourStep: number | null
  actions: {
    nextTourStep: () => void
    skipTour: () => void
  }
}

export const useOnboardingTourStore = create<OnboardingTourStore>()(
  persist(
    (set) => ({
      currentTourStep: 0,
      actions: {
        nextTourStep: () =>
          set(({ currentTourStep }) => ({
            currentTourStep: currentTourStep !== null ? currentTourStep + 1 : null,
          })),
        skipTour: () => set(() => ({ currentTourStep: null })),
      },
    }),
    {
      name: 'onboarding-tour-store',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      partialize: ({ actions, ...rest }) => rest,
    },
  ),
)

export const useOnboardingTourCurrentStep = () =>
  useOnboardingTourStore((state) => state.currentTourStep)

export const useOnboardingTourActions = () => useOnboardingTourStore((state) => state.actions)

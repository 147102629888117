import { useState } from 'react'
import { IconButton, Menu, MenuItem } from '@mui/material'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import { StockOptionStatus } from 'types/token/stockOption'
import {
  stockOptionExpireStatuses,
  stockOptionCompleteStatuses,
  stockOptionExerciseStatuses,
  stockOptionDisqualifyStatuses,
} from 'utils/stockOptionStatus'
import { useProfileUUID } from 'hooks/useUserProfileStore'
interface StockOptionHolderMenuProps {
  holderStatus: StockOptionStatus
  holderId: string
  openExerciseOptionsDialog: () => void
  openCompeteStockOptionsDialog: () => void
  openExpireStockOptionsDialog: () => void
  openDisqualifyStockOptionsDialog: () => void
}

const StockOptionHolderMenu = ({
  holderStatus,
  holderId,
  openExerciseOptionsDialog,
  openCompeteStockOptionsDialog,
  openExpireStockOptionsDialog,
  openDisqualifyStockOptionsDialog,
}: StockOptionHolderMenuProps) => {
  const currentUserId = useProfileUUID()
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null)
  const isOpen = Boolean(anchorElement)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorElement(null)
  }

  const isAllowedToExercise = currentUserId === holderId

  return (
    <>
      <IconButton
        id='icon-button'
        aria-controls={isOpen ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={isOpen || undefined}
        onClick={handleClick}
      >
        <MoreVertOutlinedIcon fontSize='small' />
      </IconButton>
      <Menu
        id='stock-option-token-holder-menu'
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={isOpen}
        onClose={handleClose}
        sx={{
          '& .MuiMenu-paper': {
            minWidth: '112px',
            minHeight: 'auto',
          },
        }}
      >
        <MenuItem disabled>Edit</MenuItem>
        <MenuItem
          disabled={!stockOptionExpireStatuses.includes(holderStatus)}
          onClick={() => {
            openExpireStockOptionsDialog()
            handleClose()
          }}
        >
          Expire
        </MenuItem>
        <MenuItem
          disabled={!stockOptionCompleteStatuses.includes(holderStatus)}
          onClick={() => {
            openCompeteStockOptionsDialog()
            handleClose()
          }}
        >
          Complete
        </MenuItem>
        <MenuItem
          disabled={!stockOptionExerciseStatuses.includes(holderStatus) || !isAllowedToExercise}
          onClick={() => {
            openExerciseOptionsDialog()
            handleClose()
          }}
        >
          Exercise
        </MenuItem>
        <MenuItem
          disabled={!stockOptionDisqualifyStatuses.includes(holderStatus)}
          onClick={() => {
            openDisqualifyStockOptionsDialog()
            handleClose()
          }}
        >
          Disqualify
        </MenuItem>
        <MenuItem disabled>View Profile</MenuItem>
      </Menu>
    </>
  )
}

export default StockOptionHolderMenu

import { TypographyOptions } from '@mui/material/styles/createTypography'

const typography: TypographyOptions = {
  fontFamily: 'Roboto, Open Sans, sans-serif',
  fontSize: 16,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '6rem',
    fontWeight: 300,
    letterSpacing: '-0.015em',
  },
  h2: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '3.75rem',
    fontWeight: 300,
    letterSpacing: '-0.005em',
  },
  h3: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '3rem',
    fontWeight: 400,
  },
  h4: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '2.125rem',
    fontWeight: 400,
    letterSpacing: '0.0025em',
  },
  h5: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '1.5rem',
    fontWeight: 400,
  },
  h6: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '1.25rem',
    fontWeight: 500,
    letterSpacing: '0.0015em',
  },
  subtitle1: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '1rem',
    fontWeight: 400,
    letterSpacing: '0.0015em',
  },
  subtitle2: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '0.875rem',
    fontWeight: 500,
    letterSpacing: '0.001em',
  },
  body1: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '1rem',
    fontWeight: 400,
    letterSpacing: '0.005em',
  },
  body2: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '0.875rem',
    fontWeight: 400,
    letterSpacing: '0.0025em',
  },
  button: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '0.875rem',
    fontWeight: 500,
    letterSpacing: '0.0125em',
    textTransform: 'uppercase',
  },
  buttonSmall: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '0.75rem',
    fontWeight: 500,
    letterSpacing: '0.0125em',
    textTransform: 'uppercase',
  },
  caption: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '0.75rem',
    fontWeight: 400,
    letterSpacing: '0.04em',
  },
  overline: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '0.75rem',
    fontWeight: 400,
    letterSpacing: '0.015em',
    textTransform: 'none',
  },
}

export default typography

import { useState } from 'react'
import { Drawer } from '@mui/material'
import Sidebar from 'components/sidebar/Sidebar'
import LinkWorkspaceDialog from 'features/workspace/LinkWorkspaceDialog'
import UnlinkWorkspaceDialog from 'features/workspace/UnlinkWorkspaceDialog'

const DrawerContainer = () => {
  const [isLinkWorkspaceDialogOpen, setIsLinkWorkspaceDialogOpen] = useState(false)
  const [isUnlinkWorkspaceDialogOpen, setIsUnlinkWorkspaceDialogOpen] = useState(false)

  return (
    <Drawer variant='permanent' open>
      <Sidebar
        onLinkWorkspace={() => setIsLinkWorkspaceDialogOpen(true)}
        onUnlinkWorkspace={() => setIsUnlinkWorkspaceDialogOpen(true)}
      />
      {isLinkWorkspaceDialogOpen ? (
        <LinkWorkspaceDialog
          isOpen={isLinkWorkspaceDialogOpen}
          handleLinkWorkspaceDialogClose={() => setIsLinkWorkspaceDialogOpen(false)}
        />
      ) : null}
      {isUnlinkWorkspaceDialogOpen ? (
        <UnlinkWorkspaceDialog
          isOpen={isUnlinkWorkspaceDialogOpen}
          handleUnlinkWorkspaceDialogClose={() => setIsUnlinkWorkspaceDialogOpen(false)}
        />
      ) : null}
    </Drawer>
  )
}

export default DrawerContainer

import { useState } from 'react'
import { QueryClient, QueryCache, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { isApiErrorResponse } from 'services/axiosConfig'
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar'

interface ReactQueryProviderWrapperProps {
  children: React.ReactNode
}

const ReactQueryProviderWrapper = ({ children }: ReactQueryProviderWrapperProps) => {
  const enqueueSnackbar = useEnqueueSnackbar()

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 1,
            staleTime: Infinity,
            refetchOnWindowFocus: false,
          },
        },
        queryCache: new QueryCache({
          onError: (error, query) => {
            if (query.meta?.useCustomErrorHandling) {
              return
            }
            if (query.meta?.errorMessage && typeof query.meta?.errorMessage === 'string') {
              enqueueSnackbar(query.meta?.errorMessage, { variant: 'error' })
              return
            }
            if (isApiErrorResponse(error)) {
              enqueueSnackbar(error.detail, { variant: 'error' })
              return
            }
            enqueueSnackbar('Something went wrong. Please try Again.', { variant: 'error' })
          },
        }),
      }),
  )

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} buttonPosition='bottom-right' />
    </QueryClientProvider>
  )
}

export default ReactQueryProviderWrapper

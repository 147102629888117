import { useState } from 'react'
import { IconButton, Menu, MenuItem } from '@mui/material'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import { EXERCISE_REQUEST_STATUS, ExerciseRequestStatus } from 'types/token/stockOption'
import { Note } from 'types/note'
import ExerciseOptionsAdminDialog from './stock-option-holder-details/dialogs/ExerciseOptionsAdminDialog'
import { z } from 'zod'

export const EXERCISE_MENU_INTENT = {
  Approve: 'approve',
  Reject: 'reject',
  ViewNote: 'view-note',
} as const

const exerciseMenuIntentSchema = z.enum([
  EXERCISE_MENU_INTENT.Approve,
  EXERCISE_MENU_INTENT.Reject,
  EXERCISE_MENU_INTENT.ViewNote,
])

export type ExerciseMenuIntent = z.infer<typeof exerciseMenuIntentSchema>
interface StockOptionExerciseMenuProps {
  exerciseStatus: ExerciseRequestStatus
  exerciseId: number
  note: Note | null
}

const StockOptionExerciseMenu = ({
  exerciseStatus,
  exerciseId,
  note,
}: StockOptionExerciseMenuProps) => {
  const [isExerciseOptionsDialogOpen, setIsExerciseOptionsDialogOpen] = useState<boolean>(false)
  const [intendedAction, setIntendedAction] = useState<ExerciseMenuIntent | null>(null)

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null)
  const isOpen = Boolean(anchorElement)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorElement(null)
  }

  return (
    <>
      <IconButton
        id='icon-button'
        aria-controls={isOpen ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={isOpen || undefined}
        onClick={handleClick}
      >
        <MoreVertOutlinedIcon fontSize='small' />
      </IconButton>
      <Menu
        id='stock-option-token-holder-menu'
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={isOpen}
        onClose={handleClose}
        sx={{
          '& .MuiMenu-paper': {
            minWidth: '112px',
            minHeight: 'auto',
            pb: 0,
          },
        }}
      >
        <MenuItem
          disabled={exerciseStatus !== EXERCISE_REQUEST_STATUS.Pending}
          onClick={() => {
            setIntendedAction(EXERCISE_MENU_INTENT.Approve)
            setIsExerciseOptionsDialogOpen(true)
            handleClose()
          }}
        >
          Approve
        </MenuItem>
        <MenuItem
          disabled={exerciseStatus !== EXERCISE_REQUEST_STATUS.Pending}
          onClick={() => {
            setIntendedAction(EXERCISE_MENU_INTENT.Reject)
            setIsExerciseOptionsDialogOpen(true)
            handleClose()
          }}
        >
          Reject
        </MenuItem>
        <MenuItem
          disabled={note === null}
          onClick={() => {
            setIntendedAction(EXERCISE_MENU_INTENT.ViewNote)
            setIsExerciseOptionsDialogOpen(true)
            handleClose()
          }}
        >
          View note
        </MenuItem>
      </Menu>

      {isExerciseOptionsDialogOpen && intendedAction ? (
        <ExerciseOptionsAdminDialog
          open={isExerciseOptionsDialogOpen}
          handleDialogClose={() => setIsExerciseOptionsDialogOpen(!isExerciseOptionsDialogOpen)}
          exerciseId={exerciseId}
          intendedAction={intendedAction}
          note={note}
        />
      ) : null}
    </>
  )
}

export default StockOptionExerciseMenu

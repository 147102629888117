import { useRef } from 'react'
import { Box, Popover, SxProps, Theme, PopoverOrigin } from '@mui/material'
import TourCard from './TourCard'
import TourMainCard from './TourMainCard'
import { useOnboardingTourCurrentStep } from 'hooks/useOnboardingTourStore'

const activeElement: SxProps<Theme> = {
  content: '""',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  borderRadius: '4px',
  outline: '2px solid #007695',
}

interface TourStepProps {
  order: number
  children?: React.ReactNode
  headline?: string
  title: string
  description: string
  image?: string
  anchorOrigin?: PopoverOrigin
  transformOrigin?: PopoverOrigin
  sx?: SxProps<Theme>
  outlineOffset?: number
}

const TourStep = ({
  children,
  order,
  headline,
  title,
  description,
  image,
  sx,
  outlineOffset,
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
  transformOrigin,
}: TourStepProps) => {
  const anchorElement = useRef<HTMLDivElement>(null)

  const tourCurrentStep = useOnboardingTourCurrentStep()

  const isOpen = tourCurrentStep === order

  return (
    <>
      {children ? (
        <Box
          ref={anchorElement}
          position='relative'
          sx={
            isOpen
              ? { '&:after': { ...activeElement, outlineOffset: outlineOffset || 2 }, ...sx }
              : undefined
          }
        >
          {children}
        </Box>
      ) : null}

      {(children && anchorElement?.current && isOpen) || (!children && isOpen) ? (
        <Popover
          open={true}
          slotProps={{
            paper: {
              sx: { width: headline ? '600px' : '294px' },
            },
          }}
          anchorReference={anchorElement?.current ? 'anchorEl' : 'none'}
          anchorEl={anchorElement?.current ? anchorElement.current : undefined}
          {...(children
            ? { anchorOrigin: anchorOrigin }
            : { sx: { display: 'flex', justifyContent: 'center', alignItems: 'center' } })}
          transformOrigin={transformOrigin}
        >
          {headline ? (
            <TourMainCard
              headline={headline}
              title={title}
              description={description}
              image={image}
            />
          ) : (
            <TourCard title={title} description={description} image={image} />
          )}
        </Popover>
      ) : null}
    </>
  )
}

export default TourStep

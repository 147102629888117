import AuthLayout from 'auth/components/AuthLayout'
import { Box, Button, Typography } from '@mui/material'
import { EmailCredentials } from 'types/auth'

interface CheckYourEmailProps {
  setResetSuccess: (value: boolean) => void
  userEmail: EmailCredentials
  onSubmit: (passData: EmailCredentials) => void
}

const CheckYourEmail = ({ setResetSuccess, onSubmit, userEmail }: CheckYourEmailProps) => {
  return (
    <AuthLayout data-testid='verify-reset-password'>
      <Typography variant='h5'>
        Great! Please check your email for instructions to reset your password.
      </Typography>
      <Typography variant='body2' color='text.primary' mt={4}>
        No email received after 15 minutes? You could choose one of the below options:
      </Typography>

      <Box mt={3.5} display='flex' flexDirection='column' alignItems='flex-start' gap={2}>
        <Button
          color='primary'
          onClick={() => {
            onSubmit(userEmail)
          }}
          data-testid='resend-email'
        >
          Resend mail
        </Button>
        <Button
          variant='text'
          color='primary'
          onClick={() => setResetSuccess(false)}
          data-testid='try-another-email'
        >
          Try another email
        </Button>
      </Box>
    </AuthLayout>
  )
}
export default CheckYourEmail

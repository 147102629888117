import { SvgIcon } from '@mui/material'
import { SvgIconProps } from '@mui/material'

const VestedOptionsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox='0 0 70 70'>
      <path
        d='M13.8542 45.3208H35.7292V51.3785H13.8542V45.3208ZM13.8542 33.2055H35.7292V39.2631H13.8542V33.2055ZM30.2604 2.91699H8.38544C5.37762 2.91699 2.91669 5.64295 2.91669 8.97468V57.4362C2.91669 60.7679 5.35028 63.4939 8.35809 63.4939H41.1979C44.2057 63.4939 46.6667 60.7679 46.6667 57.4362V21.0901L30.2604 2.91699ZM41.1979 57.4362H8.38544V8.97468H27.5261V24.1189H41.1979V57.4362Z'
        fill='currentColor'
      />
      <path
        d='M64.8148 46.0185H63.6806V43.75H61.4121V46.0185H52.338V43.75H50.0695V46.0185H48.9352C47.6762 46.0185 46.6667 47.0394 46.6667 48.287V64.1667C46.6667 65.4144 47.6762 66.4352 48.9352 66.4352H64.8148C66.0625 66.4352 67.0834 65.4144 67.0834 64.1667V48.287C67.0834 47.0394 66.0625 46.0185 64.8148 46.0185ZM64.8148 64.1667H48.9352V52.8241H64.8148V64.1667ZM64.8148 50.5556H48.9352V48.287H64.8148V50.5556ZM51.2037 55.0926H56.875V60.7639H51.2037V55.0926Z'
        fill='currentColor'
      />
      <path
        d='M14.5834 14.5837C14.5834 16.1878 15.8959 17.5003 17.5 17.5003C19.1042 17.5003 20.4167 16.1878 20.4167 14.5837C20.4167 12.9795 19.1042 11.667 17.5 11.667C15.8959 11.667 14.5834 12.9795 14.5834 14.5837Z'
        fill='currentColor'
      />
      <path
        d='M11.6667 23.3337H23.3334V21.8462C23.3334 19.4156 19.4736 18.3656 17.5 18.3656C15.5264 18.3656 11.6667 19.4156 11.6667 21.8462V23.3337Z'
        fill='currentColor'
      />
    </SvgIcon>
  )
}

export default VestedOptionsIcon

import { useEffect, useCallback } from 'react'
import { useUserAuthActions } from 'hooks/useUserAuthStore'
import { useUserProfileActions } from './useUserProfileStore'

const useIdleTimer = (timeout: number, Authentication: boolean) => {
  const { setRedirectUrl } = useUserProfileActions()
  const { clearAuthTokens } = useUserAuthActions()

  const onTimeout = useCallback(() => {
    clearAuthTokens()
    setRedirectUrl(window.location.pathname)
    window.location.href = '/login'
  }, [clearAuthTokens, setRedirectUrl])

  useEffect(() => {
    if (!Authentication) {
      return
    }

    let timer = setTimeout(onTimeout, timeout)

    const handleUserActivity = () => {
      clearTimeout(timer)
      timer = setTimeout(onTimeout, timeout)
    }

    window.addEventListener('mousemove', handleUserActivity)
    window.addEventListener('keydown', handleUserActivity)
    window.addEventListener('scroll', handleUserActivity)
    window.addEventListener('click', handleUserActivity)

    return () => {
      clearTimeout(timer)
      window.removeEventListener('mousemove', handleUserActivity)
      window.removeEventListener('keydown', handleUserActivity)
      window.removeEventListener('scroll', handleUserActivity)
      window.removeEventListener('click', handleUserActivity)
    }
  }, [timeout, onTimeout, Authentication])
}

export default useIdleTimer

import { Chip } from '@mui/material'

import { ExerciseRequestStatus, StockOptionStatus } from 'types/token/stockOption'

export interface StockOptionStatusChipProps {
  status: StockOptionStatus | ExerciseRequestStatus
  size?: 'medium' | 'small'
  'data-testid'?: string
}

const getStatusColor = (userStatus: string) => {
  switch (userStatus) {
    case 'pending':
      return 'info'
    case 'vesting':
      return 'success'
    case 'vested':
      return 'success'
    case 'disqualified':
      return 'error'
    case 'expired':
      return 'warning'
    //ExerciseRequestStatus, info
    case 'accepted':
      return 'success'
    case 'rejected':
      return 'error'
    default:
      return 'error'
  }
}

const StockOptionStatusChip = ({
  status,
  size = 'small',
  'data-testid': dataTestId,
}: StockOptionStatusChipProps) => {
  return (
    <Chip
      label={status}
      color={getStatusColor(status)}
      size={size}
      sx={{ textTransform: 'capitalize', px: 0.5 }}
      data-testid={dataTestId}
    />
  )
}

export default StockOptionStatusChip

import { Box, Icon, Link, Typography } from '@mui/material'
import { ElementType, ReactNode, useCallback } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { PictureAsPdf as PdfIcon, UploadFile as UploadFileIcon } from '@mui/icons-material'
import { document } from 'constants/allowedFiles'

import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar'

interface UploadProps {
  onFileChange: (file: File) => void
  allowedFiles?: { [key: string]: string[] }
  fileIcon?: ElementType
  children?: ReactNode
  setIsValidationError?: (isValidationError: boolean) => void
  isValidationError?: boolean
}

const Upload = ({
  onFileChange,
  allowedFiles,
  fileIcon = UploadFileIcon,
  children,
  setIsValidationError,
  isValidationError,
}: UploadProps) => {
  const enqueueSnackbar = useEnqueueSnackbar()

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (!acceptedFiles[0]) {
        return
      }

      enqueueSnackbar('File loaded successfully!', { variant: 'success' })

      onFileChange(acceptedFiles[0])

      if (isValidationError && setIsValidationError) {
        setIsValidationError(false)
      }
    },

    [enqueueSnackbar, isValidationError, onFileChange, setIsValidationError],
  )

  const onDropRejected = useCallback(
    (rejectedFiles: FileRejection[]) => {
      if (rejectedFiles.length > 1) {
        enqueueSnackbar('Multiple files have been uploaded, only one file was expected.')
      }
      const { errors } = rejectedFiles[0]

      errors.forEach((error) => {
        let errorMessage = 'An error occurred during the upload.'

        if (error.code === 'file-too-large') {
          errorMessage = 'File size is too large.'
        }

        if (error.code === 'file-invalid-type') {
          errorMessage = 'File type is not supported or no MIME type present.'
        }

        if (error.code === 'file-name-too-long') {
          errorMessage = error.message
        }

        enqueueSnackbar(errorMessage, { variant: 'error' })
      })

      if (!isValidationError && setIsValidationError) {
        setIsValidationError(true)
      }
    },
    [enqueueSnackbar, isValidationError, setIsValidationError],
  )

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    maxFiles: 1,
    accept: allowedFiles,
    multiple: false,
    maxSize: 3000000, // 3MB
    onDrop,
    onDropRejected,
    validator: (file) => {
      if (file.name.length > 100) {
        return {
          code: 'file-name-too-long',
          message: 'File name should be under 100 characters.',
        }
      }
      return null
    },
  })

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      width='396px'
      height='396px'
      border='1px dashed rgba(0, 0, 0, 0.42)'
      p={2}
      {...getRootProps()}
      data-testid='upload-dropzone'
    >
      <input {...getInputProps()} />

      {isDragActive ? (
        <Typography>Drop the file here ...</Typography>
      ) : allowedFiles === document && acceptedFiles[0] ? (
        <Box display='flex' flexDirection='column' alignItems='center' width='100%'>
          <Icon component={PdfIcon} color='primary' fontSize='large' />
          <Typography
            mt={2}
            whiteSpace='nowrap'
            overflow='hidden'
            textOverflow='ellipsis'
            maxWidth='90%'
          >
            {acceptedFiles[0].name}
          </Typography>
        </Box>
      ) : (
        <Box textAlign='center'>
          <Icon component={fileIcon} color='primary' />
          <Typography mt={2}>
            <Link
              variant='body1'
              color='primary'
              sx={{ verticalAlign: 'unset', cursor: 'pointer' }}
              data-testid='click-to-upload'
            >
              Click to upload
            </Link>{' '}
            or drag and drop
          </Typography>

          {children}
        </Box>
      )}
    </Box>
  )
}

export default Upload

import { TOKEN_TYPE_OPTIONS, TokenTypeOptions } from 'types/token/basicToken'
import { TokenBalance, UserTokenBalance } from 'types/token/profitShare'

interface TokenTypeTab {
  name: TokenTypeOptions
  identifier: string
  count: number
}

const tokenTypeSortMap = {
  [TOKEN_TYPE_OPTIONS.StockOption]: 1,
  [TOKEN_TYPE_OPTIONS.ProfitShare]: 2,
  [TOKEN_TYPE_OPTIONS.Custom]: 3,
}

export const parseTokenTypeTabsFormTokenBalance = (
  userBalances: TokenBalance[] | UserTokenBalance[],
): TokenTypeTab[] => {
  return userBalances
    .reduce((acc: TokenTypeTab[], current: TokenBalance | UserTokenBalance) => {
      if (acc.some((to) => to.name === current.token.token_type)) {
        return acc
      }
      return [
        ...acc,
        {
          name: current.token.token_type,
          identifier: current.token.token_type.toLowerCase().replace(/ /g, '-'),
          count: userBalances.filter((ub) => ub.token.token_type === current.token.token_type)
            .length,
        },
      ]
    }, [])
    .sort((a: TokenTypeTab, b: TokenTypeTab) => {
      if (tokenTypeSortMap[a.name] < tokenTypeSortMap[b.name]) {
        return -1
      } else if (tokenTypeSortMap[a.name] > tokenTypeSortMap[b.name]) {
        return 1
      }
      return 0
    })
}

import { useState, useEffect, useCallback } from 'react'

type UseDebounceProps = {
  value: string
  delay?: number
}

const useDebounce = ({ value, delay = 300 }: UseDebounceProps): string => {
  const searchValue = useCallback(() => {
    if (value.includes('+')) {
      return value.replace('+', '%2B')
    }
    return value
  }, [value])

  const [debouncedValue, setDebouncedValue] = useState<string>(searchValue)

  useEffect(() => {
    const timeoutId = setTimeout(() => setDebouncedValue(searchValue), delay)

    return () => clearTimeout(timeoutId)
  }, [searchValue, delay])

  return debouncedValue
}

export default useDebounce

import { useState } from 'react'
import { Box, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { OrganizationTheme } from 'types/organization'
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar'
import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import { useMutation } from '@tanstack/react-query'
import { API_ENDPOINTS as API } from 'services/endpoints'

interface ConfirmYourOrganizationFormProps {
  onConfirmOrganization: (organization: OrganizationTheme) => void
}

const ConfirmYourOrganizationForm = ({
  onConfirmOrganization,
}: ConfirmYourOrganizationFormProps) => {
  const enqueueSnackbar = useEnqueueSnackbar()
  const [organizationName, setOrganizationName] = useState<string>('')

  const { mutate: getOrganizationData, isPending: isGetOrganizationDataPending } = useMutation<
    OrganizationTheme,
    ErrorResponse,
    string
  >({
    mutationFn: async (subdomain) =>
      baseApiClient.get(API.org.theme(subdomain)).then((response) => response.data),
    onSuccess: (organization) => {
      onConfirmOrganization(organization)
    },
    onError: (error) => {
      if (error.detail) {
        enqueueSnackbar(error.detail, { variant: 'error' })
        return
      }
      enqueueSnackbar('Please try again', { variant: 'error' })
    },
  })

  return (
    <>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Typography variant='h5'>Enter your organization domain</Typography>
        <Tooltip
          placement='top'
          title={
            <>
              Your organization domain contains no spaces and is usually the name of your
              organization. (organizationname.keepr.io).
              <br /> <br />
              If you've forgotten your domain name, please contact your organization admin.
            </>
          }
          componentsProps={{ tooltip: { sx: { width: '160px' } } }}
        >
          <InfoOutlinedIcon sx={{ fontSize: 16 }} />
        </Tooltip>
      </Box>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          getOrganizationData(organizationName.toLowerCase())
        }}
      >
        <Stack flexDirection='row' alignItems='center' gap={3} mt={4} mb={3.5}>
          <TextField
            placeholder='OrganizationDomain'
            type='text'
            value={organizationName}
            onChange={(e) => setOrganizationName(e.target.value)}
            sx={{ width: 254 }}
            data-testid='organization-domain'
          />
          <Typography>.keepr.io</Typography>
        </Stack>

        <LoadingButton
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          loading={isGetOrganizationDataPending}
          disabled={!organizationName}
          data-testid='confirm-organization-domain'
        >
          Confirm
        </LoadingButton>
      </form>
    </>
  )
}
export default ConfirmYourOrganizationForm

import { CheckCircleOutline, CloseOutlined } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

interface PasswordRequirementsProps {
  password: string
}

const hasUppercaseRegex = /^(?=.*[A-Z]).+$/
const hasLowercaseRegex = /^(?=.*[a-z]).+$/
const hasNumberRegex = /^(?=.*[0-9]).+$/
const hasRequiredLengthRegex = /^(?=.{8,}).+$/

const getPasswordMessage = (regexExp: RegExp) => {
  switch (regexExp) {
    case hasUppercaseRegex:
      return '1 uppercase character'
    case hasLowercaseRegex:
      return '1 lowercase character'
    case hasNumberRegex:
      return '1 number'
    case hasRequiredLengthRegex:
      return 'At least 8 characters'
  }
}

const PasswordRequirements = ({ password = '' }: PasswordRequirementsProps) => {
  const checkPasswordRequirements = (passwordValue: string, regexExp: RegExp) => {
    return (
      <Box display='flex' flexDirection='row' alignItems='flex-start' justifyContent='flex-start'>
        {regexExp.test(passwordValue) === false ? (
          <CloseOutlined
            sx={{
              color: 'error.dark',
            }}
            fontSize='small'
          />
        ) : (
          <CheckCircleOutline color='success' fontSize='small' />
        )}

        <Typography variant='body2' color='text.secondary' sx={{ pl: 1 }}>
          {getPasswordMessage(regexExp)}
        </Typography>
      </Box>
    )
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='flex-start'
      justifyContent='center'
      pl={3}
      mt={2}
    >
      <Box>
        <Typography variant='body2' color='text.secondary'>
          Passwords must have:
        </Typography>
      </Box>
      {checkPasswordRequirements(password, hasRequiredLengthRegex)}
      {checkPasswordRequirements(password, hasUppercaseRegex)}
      {checkPasswordRequirements(password, hasLowercaseRegex)}
      {checkPasswordRequirements(password, hasNumberRegex)}
    </Box>
  )
}
export default PasswordRequirements

import React, { CSSProperties } from 'react'
import { NavLink as NavLinkBase, NavLinkProps as NavLinkBaseProps } from 'react-router-dom'

interface NavLinkProps extends NavLinkBaseProps {
  // React instructions: if you want it to appear in the DOM as a custom attribute, spell it as lowercase
  activestyle: CSSProperties
  isnavlinkactive?: string
}

const NavLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => {
  return (
    <NavLinkBase
      ref={ref}
      {...props}
      style={({ isActive }) => (props.isnavlinkactive || isActive ? props.activestyle : undefined)}
    />
  )
})

NavLink.displayName = 'NavLink'
export default NavLink

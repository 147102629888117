import { Avatar as MuiAvatar, Box, SxProps, Theme } from '@mui/material'
import { getInitials } from 'utils/getInitials'

type AvatarType = 'square' | 'round'

interface AvatarProps {
  name: string
  src: string | null | undefined
  sx?: SxProps<Theme>
  'data-testid'?: string
  size?: number
  fontSize?: number
  type?: AvatarType
}

const Avatar = ({
  name,
  src,
  sx,
  size,
  'data-testid': dataTestId,
  type = 'square',
  fontSize = 20,
}: AvatarProps) => {
  if (src) {
    return (
      <Box
        sx={{
          width: size ? size + 4 : undefined,
          height: size ? size + 4 : undefined,
          backgroundColor: 'background.default',
          border: '2px solid white',
          ...sx,
        }}
        overflow='hidden'
        borderRadius={type === 'square' ? 1 : '50%'}
        data-testid={dataTestId}
      >
        <img src={src} alt={name} style={{ display: 'block', width: '100%', height: '100%' }} />
      </Box>
    )
  }

  return (
    <MuiAvatar
      sx={{
        width: size,
        height: size,
        fontSize,
        borderRadius: type === 'square' ? 1 : '50%',
        ...sx,
      }}
      data-testid={dataTestId}
    >
      {getInitials(name)}
    </MuiAvatar>
  )
}

export default Avatar

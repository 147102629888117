import { useMemo, useState } from 'react'
import { useCustomTokenUserList } from 'services/api/customToken'
import { CustomTokenUser, PaginatedCustomTokenUserList } from 'types/token/customToken'
import {
  MRT_PaginationState,
  MRT_RowSelectionState,
  type MRT_ColumnDef,
} from 'material-react-table'
import {
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Link,
  FormControl,
  InputLabel,
} from '@mui/material'
import { Link as RouterLink, useOutletContext } from 'react-router-dom'
import Avatar from 'components/Avatar'
import { TokenDistributionSchedule } from 'types/token/profitShare'
import {
  Edit as EditIcon,
  Add as AddIcon,
  BlurCircularOutlined as BlurCircularOutlinedIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  CancelOutlined as CancelOutlinedIcon,
} from '@mui/icons-material'
import { TOKEN_DISTRIBUTION_SCHEDULE } from 'types/token/basicToken'
import KeeprTable from 'components/KeeprTable'
import useDebounce from 'hooks/useDebounce'
import AddUsersDialog from './AddUsersDialog'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import { API_ENDPOINTS as API } from 'services/endpoints'
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar'
import ConfirmDialog from 'components/ConfirmDialog'
import { NumberFormatValues, NumericFormat } from 'react-number-format'
import { LoadingButton } from '@mui/lab'
import CustomTokenMenu from './CustomTokenMenu'
import { useTokenDetails } from 'services/api/token'

const TOKEN_DISTRIBUTION_SCHEDULE_OPTIONS = [
  {
    value: TOKEN_DISTRIBUTION_SCHEDULE.No_Schedule,
    label: 'No Schedule',
    description: '',
  },
  {
    value: TOKEN_DISTRIBUTION_SCHEDULE.Weekly,
    label: 'Weekly',
    description: 'Transaction repeats every Sunday',
  },
  {
    value: TOKEN_DISTRIBUTION_SCHEDULE.Monthly,
    label: 'Monthly',
    description: 'Transaction repeats on the last day of each month',
  },
  {
    value: TOKEN_DISTRIBUTION_SCHEDULE.Quarterly,
    label: 'Quarterly',
    description: 'Transaction repeats on the last day of the month, every three months.',
  },
]

interface CustomTokenUserDistribution {
  pk: number
  dist_amount: number
  dist_schedule: TokenDistributionSchedule
}

const initialUsersData: PaginatedCustomTokenUserList = {
  count: 0,
  next: null,
  previous: null,
  results: [],
}

const CustomTokenUsers = () => {
  const tokenId = useOutletContext<string>()
  const queryClient = useQueryClient()
  const enqueueSnackbar = useEnqueueSnackbar()

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({})
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    //material-react-table and tanstack table under the hood use zero based index, our back-end uses 1 based index
    pageIndex: 0,
    pageSize: 10,
  })

  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchParam = useDebounce({ value: searchTerm || '' })

  const [isAddUsersDialogOpen, setIsAddUsersDialogOpen] = useState(false)
  const [isRemoveUserDialogOpen, setIsRemoveUserDialogOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState<number>()
  const [isEditMode, setIsEditMode] = useState(false)

  const [distributionAmount, setDistributionAmount] = useState<number>(0)
  const [distributionSchedule, setDistributionSchedule] = useState<TokenDistributionSchedule>(
    TOKEN_DISTRIBUTION_SCHEDULE.No_Schedule,
  )

  const { data: tokenDetails } = useTokenDetails({ tokenId })

  const { data: users = initialUsersData, isLoading: areUsersLoading } = useCustomTokenUserList({
    tokenId,
    searchTerm: debouncedSearchParam,
    pagination: {
      page: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
    },
    options: {
      placeholderData: (previousData) => previousData,
    },
  })

  const { mutate: removeUser, isPending: isRemoveUserLoading } = useMutation<
    unknown,
    ErrorResponse,
    number
  >({
    mutationFn: (selectedUser) =>
      baseApiClient
        .delete(API.token.customTokenUser(tokenId, selectedUser))
        .then((response) => response.data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['custom-token-user-list', tokenId] })
      queryClient.invalidateQueries({ queryKey: ['custom-token-users-search', tokenId] })
      queryClient.invalidateQueries({ queryKey: ['tokens', tokenId] })

      enqueueSnackbar('User successfully removed.', { variant: 'success' })

      setIsRemoveUserDialogOpen(false)
    },
    onError: (error) => {
      if (error.detail) {
        enqueueSnackbar(error.detail, { variant: 'error' })
        return
      }
      enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' })
    },
  })

  const { mutate: updateUsersDistribution, isPending: isUpdateUserDistributionLoading } =
    useMutation<unknown, ErrorResponse, CustomTokenUserDistribution[]>({
      mutationFn: (usersDistribution) =>
        baseApiClient
          .patch(API.token.customTokenUserDistributionUpdate(tokenId), {
            custom_token_users: usersDistribution,
          })
          .then((response) => response.data),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['custom-token-user-list', tokenId] })
        setRowSelection({})
        exitEditMode()

        enqueueSnackbar('Distribution successfully updated.', { variant: 'success' })
      },
      onError: (error) => {
        if (error.detail) {
          enqueueSnackbar(error.detail, { variant: 'error' })
          return
        }
        enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' })
      },
    })

  const exitEditMode = () => {
    setIsEditMode(false)
    setSelectedUser(undefined)
    setDistributionAmount(0)
    setDistributionSchedule(TOKEN_DISTRIBUTION_SCHEDULE.No_Schedule)
  }

  const columns = useMemo(
    () =>
      [
        {
          header: 'Name',
          accessorKey: 'full_name',
          size: 220,
          Cell: ({ cell, row }) => {
            const fullName = cell.getValue<string>()
            return (
              <Link
                component={RouterLink}
                to={`/people/${row.original.uuid}`}
                sx={{ textDecoration: 'none' }}
              >
                <Stack direction='row' alignItems='center' gap={1} data-testid='cell-name'>
                  <Avatar name={fullName} src={row.original.avatar} size={40} />
                  <Stack overflow='hidden'>
                    <Typography variant='body2' noWrap={true} color='text.primary'>
                      {fullName}
                    </Typography>
                    <Typography variant='caption' color='text.secondary' noWrap={true}>
                      {row.original.email}
                    </Typography>
                  </Stack>
                </Stack>
              </Link>
            )
          },
        },
        {
          header: 'Distribution',
          accessorKey: 'dist_amount',
          size: 150,
          muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ row, cell }) => {
            const distAmount = cell.getValue<number>()
            return isEditMode && row.original.id === selectedUser ? (
              <NumericFormat
                customInput={TextField}
                value={distributionAmount}
                min={0}
                onValueChange={(value: NumberFormatValues) => {
                  setDistributionAmount(value.floatValue || 0)
                }}
                thousandSeparator
                allowNegative={false}
                decimalScale={0}
                fullWidth
                size='small'
                inputProps={{
                  sx: { textAlign: 'right' },
                }}
                InputProps={{
                  sx: { pl: 1.5 },
                  startAdornment: (
                    <InputAdornment position='start'>
                      <BlurCircularOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <Typography variant='body2'>{distAmount}</Typography>
            )
          },
        },
        {
          header: 'Frequency',
          accessorKey: 'dist_schedule',
          size: 150,
          Cell: ({ row, cell }) => {
            const distSchedule = cell.getValue<TokenDistributionSchedule>()
            return isEditMode && row.original.id === selectedUser ? (
              <Select
                size='small'
                fullWidth
                value={distributionSchedule}
                onChange={(e) =>
                  setDistributionSchedule(e.target.value as TokenDistributionSchedule)
                }
                MenuProps={{
                  MenuListProps: {
                    sx: { width: '232px' },
                  },
                }}
                inputProps={{
                  sx: { '.MuiTypography-body2': { display: 'none' } },
                }}
              >
                {TOKEN_DISTRIBUTION_SCHEDULE_OPTIONS.map((option) => {
                  return (
                    <MenuItem
                      value={option.value}
                      key={option.value}
                      sx={{ whiteSpace: 'initial' }}
                    >
                      <Stack>
                        <Typography>{option.label}</Typography>
                        <Typography variant='body2' color='text.secondary'>
                          {option.description}
                        </Typography>
                      </Stack>
                    </MenuItem>
                  )
                })}
              </Select>
            ) : (
              <Typography variant='body2' textTransform='capitalize'>
                {distSchedule === TOKEN_DISTRIBUTION_SCHEDULE.No_Schedule
                  ? 'No Schedule'
                  : distSchedule}
              </Typography>
            )
          },
        },
        {
          header: 'Balance',
          accessorKey: 'balance',
          size: 140,
          muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ cell }) => {
            const balance = cell.getValue<number>()
            return <NumericFormat value={balance} displayType='text' thousandSeparator />
          },
        },
        {
          id: 'action',
          header: 'Actions',
          size: 90,
          muiTableHeadCellProps: {
            align: 'right',
          },
          Cell: ({ row }) => {
            return (
              <Stack direction='row' justifyContent='right' gap={0.5}>
                {isEditMode && row.original.id === selectedUser ? (
                  <>
                    <IconButton
                      aria-label='apply'
                      size='small'
                      onClick={() => {
                        updateUsersDistribution([
                          {
                            pk: row.original.id,
                            dist_amount: distributionAmount,
                            dist_schedule: distributionSchedule,
                          },
                        ])

                        exitEditMode()
                      }}
                    >
                      <CheckCircleOutlineIcon fontSize='inherit' color='success' />
                    </IconButton>
                    <IconButton aria-label='remove' size='small' onClick={() => exitEditMode()}>
                      <CancelOutlinedIcon fontSize='inherit' />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <IconButton
                      aria-label='edit'
                      size='small'
                      disabled={Object.keys(rowSelection).length > 0 || isEditMode}
                      onClick={() => {
                        setSelectedUser(row.original.id)
                        setDistributionAmount(row.original.dist_amount)
                        setDistributionSchedule(row.original.dist_schedule)
                        setIsEditMode(true)
                      }}
                    >
                      <EditIcon fontSize='inherit' />
                    </IconButton>
                    {tokenDetails && (
                      <CustomTokenMenu
                        tokenDetails={tokenDetails}
                        rowId={row.original.id}
                        availableUserBalance={row.original.balance}
                        setSelectedUser={setSelectedUser}
                        setIsRemoveUserDialogOpen={setIsRemoveUserDialogOpen}
                      />
                    )}
                  </>
                )}
              </Stack>
            )
          },
        },
      ] as MRT_ColumnDef<CustomTokenUser>[],
    [
      isEditMode,
      selectedUser,
      distributionAmount,
      distributionSchedule,
      rowSelection,
      tokenDetails,
      updateUsersDistribution,
    ],
  )

  const usersTableActions = useMemo(() => {
    const numbOfSelectedRows = Object.keys(rowSelection).length

    return (
      <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        alignSelf='center'
        flex={1}
        gap={2}
        sx={{ pl: 2 }}
      >
        {numbOfSelectedRows > 0 ? (
          <>
            <Typography variant='body2' color='text.secondary' sx={{ flexShrink: 0 }}>
              {numbOfSelectedRows} selected
            </Typography>
            <NumericFormat
              customInput={TextField}
              label='Distribution'
              value={distributionAmount}
              min={0}
              onValueChange={(value: NumberFormatValues) => {
                setDistributionAmount(value.floatValue || 0)
              }}
              thousandSeparator
              allowNegative={false}
              decimalScale={0}
              fullWidth
              size='small'
              sx={{ p: '0px !important', width: '180px' }}
              inputProps={{
                sx: { textAlign: 'right' },
              }}
              InputProps={{
                sx: { pl: 1.5 },
                startAdornment: (
                  <InputAdornment position='start'>
                    <BlurCircularOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
            <FormControl sx={{ p: '0px !important', width: '180px' }}>
              <InputLabel id='bulk-update-frequency'>Frequency</InputLabel>
              <Select
                labelId='bulk-update-frequency'
                label='Frequency'
                size='small'
                fullWidth
                value={distributionSchedule}
                onChange={(e) =>
                  setDistributionSchedule(e.target.value as TokenDistributionSchedule)
                }
                MenuProps={{
                  MenuListProps: {
                    sx: { width: '232px' },
                  },
                }}
                inputProps={{
                  sx: { '.MuiTypography-body2': { display: 'none' } },
                }}
              >
                {TOKEN_DISTRIBUTION_SCHEDULE_OPTIONS.map((option) => {
                  return (
                    <MenuItem
                      value={option.value}
                      key={option.value}
                      sx={{ whiteSpace: 'initial' }}
                    >
                      <Stack>
                        <Typography>{option.label}</Typography>
                        <Typography variant='body2' color='text.secondary'>
                          {option.description}
                        </Typography>
                      </Stack>
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <LoadingButton
              size='small'
              loading={isUpdateUserDistributionLoading}
              variant='contained'
              onClick={() => {
                updateUsersDistribution(
                  Object.keys(rowSelection).map((userId) => {
                    return {
                      pk: Number(userId),
                      dist_amount: distributionAmount,
                      dist_schedule: distributionSchedule,
                    }
                  }),
                )
              }}
            >
              Apply
            </LoadingButton>
          </>
        ) : (
          <Button
            variant='outlined'
            startIcon={<AddIcon />}
            onClick={() => setIsAddUsersDialogOpen(true)}
          >
            Add
          </Button>
        )}
      </Stack>
    )
  }, [
    distributionAmount,
    distributionSchedule,
    isUpdateUserDistributionLoading,
    rowSelection,
    updateUsersDistribution,
  ])

  return (
    <>
      <KeeprTable
        key={`edit-mode-${isEditMode}`}
        columns={columns}
        data={users.results}
        enableSorting={false}
        enableRowSelection={!isEditMode}
        getRowId={(row) => String(row.id)}
        onRowSelectionChange={setRowSelection}
        globalFilterPlaceholder='Name, email'
        onGlobalFilterChange={setSearchTerm}
        onPaginationChange={setPagination}
        rowCount={users.count}
        state={{
          showSkeletons: areUsersLoading,
          showProgressBars: areUsersLoading,
          pagination,
          rowSelection,
          globalFilter: searchTerm,
        }}
        muiTablePaperProps={{
          elevation: 0,
        }}
        muiTableHeadCellProps={{
          sx: !isEditMode
            ? {
                '&.MuiTableCell-root:first-of-type': {
                  width: '40px',
                },
              }
            : undefined,
        }}
        positionToolbarAlertBanner='none'
        renderTopToolbarCustomActions={() => usersTableActions}
      />

      {isAddUsersDialogOpen ? (
        <AddUsersDialog
          open={true}
          handleClose={() => setIsAddUsersDialogOpen(false)}
          tokenId={tokenId}
        />
      ) : null}

      {isRemoveUserDialogOpen ? (
        <ConfirmDialog
          isOpen={true}
          maxWidth='xs'
          handleClose={() => setIsRemoveUserDialogOpen(false)}
          title='Remove user'
          content='Removing this user will cancel all future distributions they are due. Are you sure?'
          onCancel={() => setIsRemoveUserDialogOpen(false)}
          cancelText='Back'
          confirmType='error'
          onConfirm={() => {
            if (!selectedUser) return
            removeUser(selectedUser)
          }}
          confirmText='Remove user'
          isLoading={isRemoveUserLoading}
        />
      ) : null}
    </>
  )
}

export default CustomTokenUsers

import {
  PaginatedTokenDistributions,
  PaginatedTransactionList,
  TokenDistributionInfo,
  distributionDetailsDownloadSchema,
  tokenDistributionInfoSchema,
  tokenDistributionSchema,
  distributionTransactionSchema,
} from 'types/token/tokenDistribution'
import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { API_ENDPOINTS as API } from 'services/endpoints'
import { QueryPagination } from 'types/common'
import { validateSchema } from 'utils/zod'
import { DistributionDetailsDownload as DistributionDetailsDownload } from 'types/token/tokenDistribution'

interface UseTokenDistributionInfoProps<TData> {
  tokenId: string
  options?: Omit<UseQueryOptions<TokenDistributionInfo, ErrorResponse, TData>, 'queryKey'>
}

export const useTokenDistributionInfo = <TData = TokenDistributionInfo>({
  tokenId,
  options,
}: UseTokenDistributionInfoProps<TData>) => {
  return useQuery<TokenDistributionInfo, ErrorResponse, TData>({
    queryKey: ['token-distribution-info', `${tokenId}`],
    queryFn: async () => {
      const response = await baseApiClient.get(API.token.distributionInfo(tokenId))
      const data = response.data
      return validateSchema(tokenDistributionInfoSchema, data, 'useTokenDistributionInfo')
    },
    ...options,
  })
}

interface UseTokenDistributionListProps<TData> {
  tokenId: string
  pagination?: QueryPagination
  options?: Omit<UseQueryOptions<PaginatedTokenDistributions, ErrorResponse, TData>, 'queryKey'>
}

export const useTokenDistributionList = <TData = PaginatedTokenDistributions>({
  tokenId,
  pagination,
  options,
}: UseTokenDistributionListProps<TData>) => {
  return useQuery<PaginatedTokenDistributions, ErrorResponse, TData>({
    queryKey: ['token-distributions', `${tokenId}`, pagination?.page, pagination?.pageSize],
    queryFn: async () => {
      const response = await baseApiClient.get(API.token.distribution(tokenId), {
        params: {
          page: pagination?.page,
          page_size: pagination?.pageSize,
        },
      })
      const data = response.data
      return validateSchema(tokenDistributionSchema.array(), data, 'useTokenDistributionList')
    },

    ...options,
  })
}

interface UseTokenDistributionDetailsDownloadProps<TData = DistributionDetailsDownload> {
  tokenId: string
  distributionId: string
  options?: Omit<UseQueryOptions<TData, ErrorResponse, TData>, 'queryKey'>
}

export const useTokenDistributionDetailsDownload = <TData = DistributionDetailsDownload>({
  tokenId,
  distributionId,
  options,
}: UseTokenDistributionDetailsDownloadProps<TData>) => {
  return useQuery<TData, ErrorResponse>({
    queryKey: ['token-distribution-details-download', `${tokenId}`, `${distributionId}`],
    queryFn: async () => {
      const response = await baseApiClient.get(
        API.token.distributionDetailsDownload(tokenId, distributionId),
      )
      const data = response.data
      return validateSchema(distributionDetailsDownloadSchema, data, 'useTokenDistributionDetails')
    },
    ...options,
  })
}

interface UseTokenDistributionDetailsListProps<TData = PaginatedTransactionList> {
  distributionId: string
  options?: Omit<UseQueryOptions<TData, ErrorResponse, TData>, 'queryKey'>
  pagination?: QueryPagination
  searchTerm?: string
}

export const useTokenDistributionDetailsList = <TData = PaginatedTransactionList>({
  distributionId,
  options,
  pagination,
  searchTerm,
}: UseTokenDistributionDetailsListProps<TData>) => {
  return useQuery<TData, ErrorResponse>({
    queryKey: [
      'token-distribution-details',
      `${distributionId}`,
      pagination?.page,
      pagination?.pageSize,
      searchTerm,
    ],
    queryFn: async () => {
      const response = await baseApiClient.get(API.token.distribution(distributionId), {
        params: {
          page: pagination?.page,
          page_size: pagination?.pageSize,
          q: searchTerm,
        },
      })
      const data = response.data
      return validateSchema(
        distributionTransactionSchema.array(),
        data,
        'useTokenDistributionDetails',
      )
    },
    ...options,
  })
}

import { useState } from 'react'
import { Box, Button, Dialog, Slide, Stack, Typography } from '@mui/material'
import { CheckCircleOutline } from '@mui/icons-material'
import { useOrganizationStore } from 'hooks/useOrganizationStore'
import { useProfileEmail } from 'hooks/useUserProfileStore'
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar'
import ConfirmUnlinkWorkspaceForm from './ConfirmUnlinkWorkspaceForm'
import AuthFormLayout from 'auth/components/AuthFormLayout'
import { AuthTokens, LoginFormValues } from 'types/auth'
import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import { API_ENDPOINTS as API } from 'services/endpoints'
import { useQueryClient, useMutation } from '@tanstack/react-query'

interface UnlinkWorkspaceDialogProps {
  isOpen: boolean
  handleUnlinkWorkspaceDialogClose: () => void
}

const UnlinkWorkspaceDialog = ({
  isOpen,
  handleUnlinkWorkspaceDialogClose,
}: UnlinkWorkspaceDialogProps) => {
  const [unlinkWorkspaceStep, setUnlinkWorkspaceStep] = useState<number>(0)
  const currentOrganization = useOrganizationStore()
  const email = useProfileEmail()
  const enqueueSnackbar = useEnqueueSnackbar()
  const [authError, setAuthError] = useState<string | null>(null)
  const queryClient = useQueryClient()

  const { mutate: unlinkWorkspace, isPending: isUnLinkWorkspacePending } = useMutation<
    unknown,
    ErrorResponse
  >({
    mutationFn: async () => baseApiClient.get(API.workspace.unlink()),
    onSuccess: () => {
      setUnlinkWorkspaceStep(1)
      queryClient.invalidateQueries({ queryKey: ['workspaces'] })
      queryClient.invalidateQueries({ queryKey: ['current-user-balance'] })
    },
    onError: (error) => {
      if (error?.detail) {
        enqueueSnackbar(error.detail, { variant: 'error' })
      } else {
        enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' })
      }
    },
  })

  const { mutate: login, isPending: isLoginPending } = useMutation<
    AuthTokens,
    ErrorResponse,
    LoginFormValues
  >({
    mutationFn: async (credentials) =>
      baseApiClient
        .post(API.auth.login(), credentials)
        .then((response) => response.data as AuthTokens),
    onSuccess: () => {
      unlinkWorkspace()
    },
    onError: (error) => {
      if (error.detail) {
        setAuthError(error.detail)
        return
      }

      enqueueSnackbar('Something went wrong. Please try Again.', { variant: 'error' })
    },
  })

  const onSubmit = (data: { password: string }) => {
    login({ ...data, email, subdomain: currentOrganization.subdomain })
  }

  return (
    <Dialog
      aria-labelledby='unlink workspace'
      open={isOpen}
      onClose={handleUnlinkWorkspaceDialogClose}
      disableEscapeKeyDown
      maxWidth='md'
      data-testid='unlink-workspace-dialog'
    >
      <Slide direction='right' in={unlinkWorkspaceStep === 0} mountOnEnter unmountOnExit>
        <Box padding={2} display='flex' flexDirection='column'>
          {unlinkWorkspaceStep === 0 ? (
            <AuthFormLayout organization={currentOrganization}>
              <ConfirmUnlinkWorkspaceForm
                organizationName={currentOrganization?.name || ''}
                email={email}
                isConfirmLoading={isLoginPending || isUnLinkWorkspacePending}
                authError={authError}
                onSubmit={onSubmit}
              />
            </AuthFormLayout>
          ) : null}
        </Box>
      </Slide>
      <Slide direction='left' in={unlinkWorkspaceStep === 1} mountOnEnter unmountOnExit>
        <Box padding={2} display='flex' flexDirection='column'>
          {unlinkWorkspaceStep === 1 ? (
            <Stack display='inline-flex' gap={2} justifyContent='center'>
              <Stack alignItems='center' sx={{ fontSize: '128px', padding: 3 }} gap={1}>
                <CheckCircleOutline fontSize='inherit' color='success' />
                <Typography variant='h5' color='text.secondary'>
                  Your workspace has been unlinked successfully
                </Typography>
              </Stack>
              <Button
                variant='contained'
                size='large'
                sx={{ mt: 2 }}
                onClick={handleUnlinkWorkspaceDialogClose}
              >
                Return to workspace
              </Button>
            </Stack>
          ) : null}
        </Box>
      </Slide>
    </Dialog>
  )
}

export default UnlinkWorkspaceDialog

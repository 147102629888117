import { z } from 'zod'

export interface PaginatedResponse<T> {
  count: number
  next: string | null
  previous: string | null
  results: T[]
}

export function isPaginatedResponse(data: unknown): data is PaginatedResponse<unknown> {
  if (typeof data === 'object' && data !== null) {
    return 'results' in data
  }
  return false
}

export type ObjectValues<T> = T[keyof T]

export const CURRENCY_OPTIONS = {
  USD: 'dollar',
  EUR: 'euro',
} as const

export const CURRENCY_SYMBOL = {
  [CURRENCY_OPTIONS.USD]: '$',
  [CURRENCY_OPTIONS.EUR]: '€',
} as const

export const currencyOptionsSchema = z.enum([CURRENCY_OPTIONS.USD, CURRENCY_OPTIONS.EUR])

export type CurrencyOptions = z.infer<typeof currencyOptionsSchema>

export interface SelectOption<TData = number> {
  value: TData
  label: string
}
export interface QueryPagination {
  page?: number
  pageSize?: number
}

export const addressSchema = z.object({
  id: z.number(),
  country: z.string(),
})

export type Address = z.infer<typeof addressSchema>

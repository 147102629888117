import {
  DeleteOutline as DeleteOutlineIcon,
  DragHandle as DragHandleIcon,
  Edit as EditIcon,
} from '@mui/icons-material'
import { Grid, IconButton, Paper, Stack, Typography } from '@mui/material'
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd'

import { StrictModeDroppable } from 'components/StrictModeDroppable'
import { Tier } from 'types/organization'
import { reorderItemsInArray } from 'utils/array'
import { useState } from 'react'

interface DraggableTiersProps {
  tiers: Tier[]
  handleReorderTiers: (tiers: Tier[]) => void
  openRemoveTierDialog: (tierId: number) => void
  openEditTierDialog: (tierId: number) => void
}

const DraggableTiers = ({
  tiers,
  handleReorderTiers,
  openRemoveTierDialog,
  openEditTierDialog,
}: DraggableTiersProps) => {
  const [hoveredTier, setHoveredTier] = useState<number | null>(null)

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result

    // dropped outside box
    if (!destination) {
      return
    }
    // if it is dropped on the same position
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }

    const items = reorderItemsInArray<Tier>(tiers, source.index, destination.index)

    handleReorderTiers(
      items.map((item, index) => {
        return { ...item, position: index + 1 }
      }),
    )
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <StrictModeDroppable droppableId='tiers-dnd'>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {tiers.map((tier, index) => {
              const isTierHovered = hoveredTier === tier.id

              return (
                <Draggable key={tier.id} draggableId={String(tier.id)} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      data-testid={`tier-row-${index + 1}`}
                    >
                      <Paper
                        elevation={isTierHovered ? 4 : 2}
                        sx={{
                          borderRadius: 0,
                          borderTop: '1px solid',
                          borderColor: 'divider',
                        }}
                      >
                        <Grid
                          container
                          p={2}
                          alignItems='center'
                          gap={2}
                          onMouseEnter={() => setHoveredTier(tier.id)}
                          onMouseLeave={() => setHoveredTier(null)}
                        >
                          <Grid item xs='auto'>
                            <IconButton
                              aria-label='drag-handle'
                              size='small'
                              sx={{ padding: 1.5 }}
                              data-testid='drag-handle'
                              {...provided.dragHandleProps}
                            >
                              <DragHandleIcon fontSize='inherit' />
                            </IconButton>
                          </Grid>

                          <Grid item xs zeroMinWidth>
                            <Typography data-testid='tier-name' noWrap>
                              {tier.name}
                            </Typography>

                            {tier.description ? (
                              <Typography
                                variant='body2'
                                color='text.secondary'
                                mt={0.5}
                                data-testid='tier-description'
                              >
                                {tier.description}
                              </Typography>
                            ) : null}
                          </Grid>

                          <Grid item xs='auto'>
                            <Stack direction='row' justifyContent='right'>
                              <IconButton
                                aria-label='edit'
                                size='small'
                                sx={{ padding: 0.5 }}
                                onClick={() => openEditTierDialog(tier.id)}
                              >
                                <EditIcon fontSize='inherit' />
                              </IconButton>
                              <IconButton
                                aria-label='delete'
                                size='small'
                                sx={{ padding: 0.5 }}
                                onClick={() => openRemoveTierDialog(tier.id)}
                                data-testid='tier-remove'
                              >
                                <DeleteOutlineIcon fontSize='inherit' />
                              </IconButton>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Paper>
                    </div>
                  )}
                </Draggable>
              )
            })}
            {provided.placeholder}
          </div>
        )}
      </StrictModeDroppable>
    </DragDropContext>
  )
}
export default DraggableTiers

import { Autocomplete, TextField } from '@mui/material'
import useDebounce from 'hooks/useDebounce'
import { useMemo, useState } from 'react'
import { useCustomTokenUsersSearch } from 'services/api/customToken'
import { CustomTokenUsersSearch } from 'types/token/customToken'

interface AddUsersProps {
  tokenId: string
  selectedUsers: CustomTokenUsersSearch[]
  onChange: (users: CustomTokenUsersSearch[]) => void
}

const AddUsers = ({ tokenId, selectedUsers, onChange }: AddUsersProps) => {
  const [searchParam, setSearchParam] = useState<string>('')

  const debouncedSearchParam = useDebounce({ value: searchParam || '' })

  const { data: userAutocompleteOptions = [] } = useCustomTokenUsersSearch<
    CustomTokenUsersSearch[]
  >({
    tokenId,
    searchTerm: debouncedSearchParam,
    options: {
      enabled: debouncedSearchParam.length > 2,
      placeholderData: (previousData) => previousData,
      select: (data) => data.results,
    },
  })

  const allPossibleOptions: CustomTokenUsersSearch[] = useMemo(() => {
    if (!selectedUsers.length) {
      return userAutocompleteOptions
    }

    return [...new Set([...userAutocompleteOptions, ...selectedUsers])]
  }, [selectedUsers, userAutocompleteOptions])

  return (
    <Autocomplete
      multiple
      fullWidth
      onChange={(_e, newValue) => {
        onChange(newValue)
      }}
      options={allPossibleOptions}
      getOptionLabel={(option) => option.email}
      isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
      getOptionDisabled={(option) => selectedUsers.map((user) => user.uuid).includes(option.uuid)}
      noOptionsText='No users'
      filterOptions={(options, { inputValue }) => {
        const search = inputValue.toLowerCase()

        return options.filter(
          ({ email, first_name, last_name }) =>
            email.toLowerCase().includes(search) ||
            first_name.toLowerCase().includes(search) ||
            last_name.toLowerCase().includes(search),
        )
      }}
      renderInput={(params) => (
        <TextField {...params} variant='outlined' placeholder='Search by name, email, etc...' />
      )}
      onInputChange={(_e, enteredValue) => {
        if (enteredValue === ' ') {
          setSearchParam('')
          return
        }

        setSearchParam(enteredValue)
      }}
    />
  )
}

export default AddUsers

import { Container, Grid } from '@mui/material'
import { useOrganizationStore } from 'hooks/useOrganizationStore'
import { APP_DEFAULT_SUBDOMAIN, CURRENT_SUBDOMAIN } from 'App'
import AuthFormLayout from './AuthFormLayout'

interface AuthLayoutProps {
  children: React.ReactNode
  'data-testid'?: string
}

const AuthLayout = ({ children, 'data-testid': dataTestId }: AuthLayoutProps) => {
  const organization = useOrganizationStore()

  return (
    <Container maxWidth={false} data-testid={dataTestId}>
      <Grid container direction='column' justifyContent='center' alignItems='center' height='100vh'>
        <Grid item>
          <AuthFormLayout
            isDefaultSubdomain={CURRENT_SUBDOMAIN == APP_DEFAULT_SUBDOMAIN}
            organization={organization}
          >
            {children}
          </AuthFormLayout>
        </Grid>
      </Grid>
    </Container>
  )
}
export default AuthLayout

import { PaginatedResponse, currencyOptionsSchema } from 'types/common'
import { noteSchema } from 'types/note'

import { z } from 'zod'

export const STOCK_OPTION_DISTRIBUTION_FREQUENCY_OPTIONS = {
  Monthly: 'monthly',
  Quarterly: 'quarterly',
} as const

export const stockOptionDistributionFrequencySchema = z.enum([
  STOCK_OPTION_DISTRIBUTION_FREQUENCY_OPTIONS.Monthly,
  STOCK_OPTION_DISTRIBUTION_FREQUENCY_OPTIONS.Quarterly,
])

export type StockOptionDistributionFrequency = z.infer<
  typeof stockOptionDistributionFrequencySchema
>

export const STOCK_OPTION_HOLDER_OVERVIEW_TABS = {
  Chart: 'chart',
  Schedule: 'schedule',
  Notes: 'notes',
}

const stockOptionHolderOverviewSchema = z.enum([
  STOCK_OPTION_HOLDER_OVERVIEW_TABS.Chart,
  STOCK_OPTION_HOLDER_OVERVIEW_TABS.Schedule,
  STOCK_OPTION_HOLDER_OVERVIEW_TABS.Notes,
])

export type StockOptionHolderOverviewTabs = z.infer<typeof stockOptionHolderOverviewSchema>

export const STOCK_OPTION_STATUS = {
  Pending: 'pending',
  Vesting: 'vesting',
  Vested: 'vested',
  Disqualified: 'disqualified',
  Expired: 'expired',
} as const

export const stockOptionStatusSchema = z.enum([
  STOCK_OPTION_STATUS.Pending,
  STOCK_OPTION_STATUS.Vesting,
  STOCK_OPTION_STATUS.Vested,
  STOCK_OPTION_STATUS.Disqualified,
  STOCK_OPTION_STATUS.Expired,
])

export type StockOptionStatus = z.infer<typeof stockOptionStatusSchema>

export const EXERCISE_REQUEST_STATUS = {
  Pending: 'pending',
  Accepted: 'accepted',
  Rejected: 'rejected',
} as const

export const exerciseRequestStatusSchema = z.enum([
  EXERCISE_REQUEST_STATUS.Pending,
  EXERCISE_REQUEST_STATUS.Accepted,
  EXERCISE_REQUEST_STATUS.Rejected,
])

export type ExerciseRequestStatus = z.infer<typeof exerciseRequestStatusSchema>

export const stockOptionExerciseRequestSchema = z.object({
  id: z.number(),
  requested_amount: z.number(),
  status: exerciseRequestStatusSchema,
  created_at: z.string(),
  modified_at: z.string(),
  note: noteSchema.nullable(),
})

export const userStockOptionGrantOptionsSchema = z.object({
  id: z.number(),
  user: z.string(),
  grant_date: z.string(),
  cliff_period: z.number().nullable(),
  vesting_period: z.number(),
  status: stockOptionStatusSchema,
  expiration_date: z.string(),
  total_options_amount: z.number(),
  distribution_frequency: stockOptionDistributionFrequencySchema,
  strike_price: z.string(),
  currency: currencyOptionsSchema,
  exercised_amount: z.number(),
  exercisable_amount: z.number(),
  next_vesting_date: z.string().nullable().optional(),
  vested_amount: z.number(),
})

export const stockOptionHolderScheduleSchema = z.object({
  id: z.number(),
  date: z.string(),
  amount: z.number(),
  completed: z.boolean(),
})

export const stockOptionHolderSchema = userStockOptionGrantOptionsSchema.extend({
  user_avatar_url: z.string().nullable(),
  user_email: z.string(),
  user_full_name: z.string(),
  user_thumbnail_url: z.string().nullable(),
  schedule: z.array(stockOptionHolderScheduleSchema),
  document: z.string().nullable().optional(),
})

export const userStockOptionsExerciseRequestSchema = z.object({
  id: z.number(),
  iso: z.number(),
  status: exerciseRequestStatusSchema,
  created_at: z.string(),
  modified_at: z.string(),
  note: noteSchema.nullable(),
})

export type UserStockOptionGrantOptions = z.infer<typeof userStockOptionGrantOptionsSchema>

export type StockOptionHolder = z.infer<typeof stockOptionHolderSchema>

export type PaginatedStockOptionHolders = PaginatedResponse<StockOptionHolder>

export type StockOptionHolderSchedule = z.infer<typeof stockOptionHolderScheduleSchema>

export type StockOptionExerciseRequest = z.infer<typeof stockOptionExerciseRequestSchema>

export type PaginatedStockOptionExerciseRequests = PaginatedResponse<StockOptionExerciseRequest>

export type UserStockOptionsExerciseRequest = z.infer<typeof userStockOptionsExerciseRequestSchema>

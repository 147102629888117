import { Typography, Button, Card, CardMedia, CardContent, CardActions } from '@mui/material'
import { useOnboardingTourActions } from 'hooks/useOnboardingTourStore'

interface TourMainCardProps {
  headline: string
  title: string
  description: string
  image?: string
}

const TourMainCard = ({ headline, title, description, image }: TourMainCardProps) => {
  const { nextTourStep } = useOnboardingTourActions()
  return (
    <Card elevation={0}>
      {image ? (
        <CardMedia
          component='img'
          src={image}
          sx={{ width: 'auto', height: '162px', mt: 2, mx: 'auto' }}
        />
      ) : null}
      <CardContent sx={{ px: 3, pb: 1, textAlign: 'center' }}>
        <Typography variant='h3'>{headline}</Typography>
        <Typography variant='h5' mt={1}>
          {title}
        </Typography>
        <Typography variant='body1' mt={3}>
          {description}
        </Typography>
      </CardContent>
      <CardActions sx={{ px: 3, py: 2, justifyContent: 'center' }}>
        <Button variant='contained' onClick={nextTourStep}>
          Get started
        </Button>
      </CardActions>
    </Card>
  )
}

export default TourMainCard

import {
  TokenType,
  profitSharePayoutSchema,
  PaginatedProfitSharePayout,
  ProfitSharePayout,
  userPayoutSchema,
  PaginatedUserPayoutList,
} from 'types/token/profitShare'
import { ProfitShareHolderForDownload, profitShareHolderForDownloadSchema } from 'types/user'
import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import { Token, tokenSchema } from 'types/token/basicToken'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { API_ENDPOINTS as API } from 'services/endpoints'
import { tokenTypeSchema } from 'types/token/profitShare'
import { validateSchema } from 'utils/zod'
import { QueryPagination } from 'types/common'

interface UseTokenListProps<TData> {
  options?: Omit<UseQueryOptions<Token[], ErrorResponse, TData>, 'queryKey'>
}
export const useTokenList = <TData = Token[]>({ options }: UseTokenListProps<TData>) => {
  return useQuery<Token[], ErrorResponse, TData>({
    queryKey: ['tokens'],
    queryFn: async () => {
      const response = await baseApiClient.get(API.token.list())
      const data = response.data
      return validateSchema(tokenSchema.array(), data, 'useTokenList')
    },
    ...options,
  })
}

interface UseTokenDetailsProps<TData> {
  tokenId: string
  options?: Omit<UseQueryOptions<Token, ErrorResponse, TData>, 'queryKey'>
}

export const useTokenDetails = <TData = Token>({
  tokenId,
  options,
}: UseTokenDetailsProps<TData>) => {
  return useQuery<Token, ErrorResponse, TData>({
    queryKey: ['tokens', tokenId],
    queryFn: async () => {
      const response = await baseApiClient.get(API.token.details(tokenId))
      const data = response.data
      return validateSchema(tokenSchema, data, 'useTokenDetails')
    },
    ...options,
  })
}

interface UseTokenTypesProps<TData> {
  options?: Omit<UseQueryOptions<TokenType[], ErrorResponse, TData>, 'queryKey'>
}

export const useTokenTypes = <TData = TokenType[]>({ options }: UseTokenTypesProps<TData>) => {
  return useQuery<TokenType[], ErrorResponse, TData>({
    queryKey: ['token-types'],
    queryFn: async () => {
      const response = await baseApiClient.get(API.token.type())
      const data = response.data
      return validateSchema(tokenTypeSchema.array(), data, 'useTokenTypes')
    },
    ...options,
  })
}

interface UseTokenPayoutListProps<TData> {
  tokenId: string
  isPreview: boolean
  pagination?: QueryPagination
  options?: Omit<UseQueryOptions<PaginatedProfitSharePayout, ErrorResponse, TData>, 'queryKey'>
}

export const useTokenPayoutList = <TData = PaginatedProfitSharePayout>({
  tokenId,
  isPreview,
  pagination,
  options,
}: UseTokenPayoutListProps<TData>) => {
  return useQuery<PaginatedProfitSharePayout, ErrorResponse, TData>({
    queryKey: ['token-payouts', tokenId, isPreview, pagination?.page, pagination?.pageSize],
    queryFn: async () => {
      const response = await baseApiClient.get(API.token.payoutList(tokenId), {
        params: {
          page: pagination?.page,
          page_size: pagination?.pageSize,
          preview: isPreview,
        },
      })
      const data = response.data
      return validateSchema(profitSharePayoutSchema.array(), data, 'useTokenPayoutList')
    },
    ...options,
  })
}

interface UseTokenPayoutDetailsProps<TData> {
  tokenId: string
  payoutId: number
  options?: Omit<UseQueryOptions<ProfitSharePayout, ErrorResponse, TData>, 'queryKey'>
}

export const useTokenPayoutDetails = <TData = ProfitSharePayout>({
  tokenId,
  payoutId,
  options,
}: UseTokenPayoutDetailsProps<TData>) => {
  return useQuery<ProfitSharePayout, ErrorResponse, TData>({
    queryKey: ['token-payout', tokenId, payoutId],
    queryFn: async () => {
      const response = await baseApiClient.get(API.token.payoutById(tokenId, payoutId))
      const data = response.data
      return validateSchema(profitSharePayoutSchema, data, 'useTokenPayoutDetails')
    },
    ...options,
  })
}

interface UseTokenHoldersListProps<TData> {
  tokenId: string
  options?: Omit<UseQueryOptions<TData, ErrorResponse>, 'queryKey'>
}

export const useAllTokenHoldersList = <TData = ProfitShareHolderForDownload[]>({
  tokenId,
  options,
}: UseTokenHoldersListProps<TData>) => {
  return useQuery<TData, ErrorResponse>({
    queryKey: ['token-holders-list', tokenId],
    queryFn: async () => {
      const response = await baseApiClient.get(API.token.balanceExportList(tokenId))
      const data = response.data
      return validateSchema(
        profitShareHolderForDownloadSchema.array(),
        data,
        'useTokenHoldersListForDownload',
      )
    },
    ...options,
  })
}
interface UseUserPayoutsProps<TData> {
  userId: string
  pagination?: QueryPagination
  options?: Omit<UseQueryOptions<PaginatedUserPayoutList, ErrorResponse, TData>, 'queryKey'>
}

export const useUserPayouts = <TData = PaginatedUserPayoutList>({
  userId,
  pagination,
  options,
}: UseUserPayoutsProps<TData>) => {
  return useQuery<PaginatedUserPayoutList, ErrorResponse, TData>({
    queryKey: ['token-user-payouts', userId, pagination?.page, pagination?.pageSize],
    queryFn: async () => {
      const response = await baseApiClient.get(API.token.userPayouts(userId))
      const data = response.data
      return validateSchema(userPayoutSchema.array(), data, 'useUserPayouts')
    },
    ...options,
  })
}

import { OrganizationTheme } from 'types/organization'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface OrganizationStore extends OrganizationTheme {
  isDarkTheme?: boolean
  actions: {
    setOrganization: (organization: Partial<OrganizationStore>) => void
    setOrgColor: (org_color: string) => void
    toggleDarkTheme: () => void
  }
}

export const useOrganizationStore = create<OrganizationStore>()(
  persist(
    (set) => ({
      subdomain: '',
      logo_thumbnail: null,
      name: '',
      org_color: '',
      restricted_use: false,
      free_use: false,
      actions: {
        setOrganization: (organization: Partial<OrganizationStore>) => {
          set(() => organization)
        },
        setOrgColor: (org_color) => {
          set(() => ({ org_color }))
        },
        toggleDarkTheme: () => {
          set((state) => ({ isDarkTheme: !state.isDarkTheme }))
        },
      },
    }),
    {
      name: 'organization-store',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      partialize: ({ actions, restricted_use, free_use, ...rest }) => rest,
    },
  ),
)

export const useOrganizationSubdomain = () => useOrganizationStore((state) => state.subdomain)
export const useOrganizationThemeColor = () => useOrganizationStore((state) => state.org_color)
export const useOrganizationName = () => useOrganizationStore((state) => state.name)
export const useOrganizationLogoThumbnail = () =>
  useOrganizationStore((state) => state.logo_thumbnail)
export const useOrganizationIsDarkTheme = () => useOrganizationStore((state) => state.isDarkTheme)

export const useFreeUse = () => useOrganizationStore((state) => state.free_use)

export const useRestrictedUse = () => useOrganizationStore((state) => state.restricted_use)

export const useOrganizationActions = () => useOrganizationStore((state) => state.actions)

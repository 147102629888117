import {
  AccountBalanceWalletOutlined as AccountBalanceWalletOutlinedIcon,
  BlurCircularOutlined as BlurCircularOutlinedIcon,
  HomeOutlined as HomeOutlinedIcon,
  PeopleAltOutlined as PeopleAltOutlinedIcon,
  Settings as SettingsIcon,
  HelpOutline as HelpOutlineIcon,
} from '@mui/icons-material'
import {
  Box,
  Divider,
  Icon,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  SvgIconTypeMap,
  Typography,
  useTheme,
  Stack,
  Link,
} from '@mui/material'
import { useOnboardingStepCompleted, useProfileUserJob } from 'hooks/useUserProfileStore'
import { useProfileAvatar, useProfileFullName, useProfileRole } from 'hooks/useUserProfileStore'

import Avatar from 'components/Avatar'
import NavLink from 'components/NavLink'
import OnboardingStepsBlock from './onboardingStepsBlock/OnboardingStepsBlock'
import OrganizationMenu from './OrganizationMenu'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { USER_ROLE } from 'types/user'
import UserJobBullets from './UserJobBullets'
import KeeprFooterLogo from 'assets/keepr-footer-logo.svg'
import { matchPath, useLocation } from 'react-router-dom'
import TourStep from 'components/tour-step/TourStep'
import { useRestrictedUse } from 'hooks/useOrganizationStore'

export const SIDEBAR_WIDTH = 240

interface SidebarProps {
  onLinkWorkspace: () => void
  onUnlinkWorkspace: () => void
}

interface NavItem {
  to: string
  label: string
  icon: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
    muiName: string
  }
  activeUrls?: string[]
}

const navItemsList: NavItem[] = [
  {
    to: '/',
    label: 'Home',
    icon: HomeOutlinedIcon,
    activeUrls: ['/dashboard', '/dashboard/organization'],
  },
  {
    to: '/wallet',
    label: 'Wallet',
    icon: AccountBalanceWalletOutlinedIcon,
  },
]

const adminNavItemsList: NavItem[] = [
  {
    to: '/assets',
    label: 'Assets',
    icon: BlurCircularOutlinedIcon,
  },
  {
    to: '/people',
    label: 'People',
    icon: PeopleAltOutlinedIcon,
  },
  {
    to: '/settings',
    label: 'Settings',
    icon: SettingsIcon,
  },
]

const Sidebar = ({ onLinkWorkspace, onUnlinkWorkspace }: SidebarProps) => {
  const theme = useTheme()

  const location = useLocation()

  const profileFullName = useProfileFullName()
  const profileAvatar = useProfileAvatar()
  const userJob = useProfileUserJob()
  const role = useProfileRole()

  const restricted_use = useRestrictedUse()

  const onBoardingStepCompleted = useOnboardingStepCompleted()

  const nextStep = (stepCompleted: number) => {
    switch (stepCompleted) {
      case 0:
        return 'Create your organization'
      case 1:
        return 'Customize your organization'
      case 2:
        return 'Confirm tiers'
      case 3:
        return 'Invite your team'
      case 4:
        return 'Create asset'
      case 5:
        return 'Onboarding completed'
      default:
        return 'Create your organization'
    }
  }

  const activeLinkStyle = {
    borderLeft: '2px solid',
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  }

  return (
    <Box
      sx={{
        width: SIDEBAR_WIDTH,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid',
        borderColor: 'divider',
      }}
      data-testid='sidebar-navigation'
    >
      <OrganizationMenu onLinkWorkspace={onLinkWorkspace} onUnlinkWorkspace={onUnlinkWorkspace} />

      <Box display='flex' flexDirection='column' padding={2}>
        <Avatar
          name={profileFullName}
          src={profileAvatar || ''}
          size={80}
          sx={{ margin: 'auto' }}
          data-testid='user-avatar'
        />
        <Typography
          component='h5'
          fontWeight='500'
          noWrap={true}
          align='center'
          sx={{ marginTop: 1 }}
          data-testid='user-name'
        >
          {profileFullName}
        </Typography>
      </Box>
      <Box display='flex' flexDirection='column' padding='0px 26px' gap='14px'>
        <UserJobBullets userJob={userJob} />
      </Box>
      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
      <Box height='100%' display='flex' flexDirection='column' justifyContent='space-between'>
        <List>
          {navItemsList
            .filter((item) => item.label !== 'Wallet')
            .map((item) => {
              return (
                <ListItem
                  disablePadding
                  key={item.label}
                  data-testid={`${item.label.toLowerCase().replace(/ /g, '-')}-link`}
                >
                  <ListItemButton
                    component={NavLink}
                    color='text.secondary'
                    sx={{
                      py: 1.25,
                      px: 3.5,
                    }}
                    activestyle={activeLinkStyle}
                    to={item.to}
                    isnavlinkactive={
                      item.activeUrls &&
                      item.activeUrls.some((url) => matchPath(location.pathname, url))
                        ? 'true'
                        : undefined
                    }
                    disabled={restricted_use}
                  >
                    <Icon component={item.icon} fontSize='small' sx={{ marginRight: '10px' }} />
                    <ListItemText
                      primary={item.label}
                      primaryTypographyProps={{ variant: 'body1' }}
                    />
                  </ListItemButton>
                </ListItem>
              )
            })}
          <TourStep
            order={5}
            outlineOffset={-2}
            title='Your Wallet'
            description='Access your personal wallet, where you can manage your assets in more detail and view your transaction history.'
            transformOrigin={{
              vertical: 'top',
              horizontal: -10,
            }}
          >
            <ListItem disablePadding data-testid='wallet-link'>
              <ListItemButton
                component={NavLink}
                color='text.secondary'
                sx={{
                  py: 1.25,
                  px: 3.5,
                }}
                activestyle={activeLinkStyle}
                to='/wallet'
                disabled={restricted_use}
              >
                <Icon
                  component={AccountBalanceWalletOutlinedIcon}
                  fontSize='small'
                  sx={{ marginRight: '10px' }}
                />
                <ListItemText primary='Wallet' primaryTypographyProps={{ variant: 'body1' }} />
              </ListItemButton>
            </ListItem>
          </TourStep>
        </List>

        {onBoardingStepCompleted && role === USER_ROLE.Admin ? (
          <OnboardingStepsBlock
            nextStep={nextStep}
            onBoardingStepCompleted={onBoardingStepCompleted}
          />
        ) : null}
      </Box>
      <Box marginTop='auto'>
        {role === USER_ROLE.Admin ? (
          <TourStep
            order={6}
            outlineOffset={-2}
            title='Founders Studio'
            description='Only visible to admins, this is where you can create and distribute assets, invite and manage your team, and customize your organization settings.'
            transformOrigin={{
              vertical: 'top',
              horizontal: -10,
            }}
          >
            <Divider sx={{ my: 2 }} />
            <Typography
              variant='body2'
              color='text.secondary'
              sx={{
                padding: '0px 26px',
                textTransform: 'capitalize',
                fontWeight: 'fontWeightMedium',
              }}
            >
              Founders Studio
            </Typography>
            <List>
              {adminNavItemsList.map((item) => (
                <ListItem disablePadding key={item.label}>
                  <ListItemButton
                    component={NavLink}
                    sx={{ py: 1.25, px: 3.5 }}
                    activestyle={activeLinkStyle}
                    to={item.to}
                    disabled={
                      (!!onBoardingStepCompleted &&
                        onBoardingStepCompleted < 5 &&
                        item.label === 'Assets') ||
                      restricted_use
                    }
                    data-testid={`${item.label.toLowerCase().replace(/ /g, '-')}-link`}
                  >
                    <Icon component={item.icon} fontSize='small' sx={{ mr: 1.25 }} />
                    <ListItemText
                      primary={item.label}
                      primaryTypographyProps={{ variant: 'body1' }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </TourStep>
        ) : null}
        <Divider />
        <Box px={3} py={2}>
          <Link
            component='a'
            href='mailto:support@keepr.io'
            underline='none'
            sx={{ display: 'flex', alignItems: 'center', pl: 0.5 }}
          >
            <Icon
              component={HelpOutlineIcon}
              fontSize='small'
              sx={{ color: 'text.secondary', mr: 1 }}
            />
            <Typography variant='body2' color='text.secondary'>
              Support
            </Typography>
          </Link>
        </Box>
        <Divider />
        <Stack px={3} py={2}>
          <Typography variant='caption' color='grey.500'>
            Powered by
          </Typography>
          <img
            src={KeeprFooterLogo}
            alt='Keepr footer logo'
            style={{ width: '90px', height: 'auto' }}
          />
        </Stack>
      </Box>
    </Box>
  )
}

export default Sidebar

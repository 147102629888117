import React, { useState } from 'react'
import { Box, IconButton, Slider, Stack } from '@mui/material'
import AvatarEditor from 'react-avatar-editor'
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material'

const EDITOR_WIDTH = 346
const EDITOR_HEIGHT = 346

const ZOOM_MIN = 0.5
const ZOOM_MAX = 1.5
const ZOOM_STEP = 0.1

interface EditProps {
  avatar: File
  onAvatarEdited: () => void
  uploadTitle?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Edit = React.forwardRef<any, EditProps>(({ avatar, onAvatarEdited, uploadTitle }, ref) => {
  const [zoom, setZoom] = useState<number>(1)

  const handleZoomChange = (_event: Event, newValue: number | number[]) => {
    setZoom(newValue as number)
  }

  const zoomIn = () => {
    setZoom((zoom) => Number((zoom + ZOOM_STEP).toFixed(2)))
  }

  const zoomOut = () => {
    setZoom((zoom) => Number((zoom - ZOOM_STEP).toFixed(2)))
  }

  return (
    <Box>
      <AvatarEditor
        ref={ref}
        image={avatar}
        crossOrigin='anonymous'
        width={EDITOR_WIDTH}
        height={EDITOR_HEIGHT}
        border={[25, 25]}
        borderRadius={uploadTitle ? EDITOR_WIDTH / 2 : 0}
        color={[0, 0, 0, 0.6]}
        scale={zoom}
        disableHiDPIScaling={true}
        onImageChange={onAvatarEdited}
      />

      <Stack direction='row' alignItems='center' justifyContent='center' spacing={1} sx={{ mt: 1 }}>
        <IconButton aria-label='delete' size='small' onClick={zoomOut} disabled={zoom === ZOOM_MIN}>
          <RemoveIcon fontSize='inherit' />
        </IconButton>
        <Slider
          aria-label='avatar-scale'
          min={ZOOM_MIN}
          max={ZOOM_MAX}
          step={ZOOM_STEP}
          value={zoom}
          onChange={handleZoomChange}
          size='small'
          sx={{ width: 268 }}
          data-testid='uploaded-image-slider'
        />
        <IconButton aria-label='delete' size='small' onClick={zoomIn} disabled={zoom === ZOOM_MAX}>
          <AddIcon fontSize='inherit' />
        </IconButton>
      </Stack>
    </Box>
  )
})

Edit.displayName = 'Edit'
export default Edit

import StockOptionExerciseMenu from 'features/tokens/token/stock-options/stock-option-holders/StockOptionExerciseMenu'
import { StockOptionExerciseRequest } from 'types/token/stockOption'

export interface StockOptionExerciseDialogsProps {
  exerciseRequest: StockOptionExerciseRequest
}

const StockOptionExerciseDialogs = ({ exerciseRequest }: StockOptionExerciseDialogsProps) => {
  return (
    <StockOptionExerciseMenu
      exerciseStatus={exerciseRequest.status}
      exerciseId={exerciseRequest.id}
      note={exerciseRequest.note}
    />
  )
}

export default StockOptionExerciseDialogs

import { ThemeOptions } from '@mui/material'
import typography from './typography'
import { grey } from '@mui/material/colors'

export const tokensTheme: ThemeOptions = {
  typography,
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          buttonSmall: 'p',
        },
      },
    },
    MuiAvatar: {
      defaultProps: {
        variant: 'rounded',
      },
      styleOverrides: {
        root: {
          color: grey[200],
          textTransform: 'uppercase',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '10px',
          lineHeight: '14px',
          fontWeight: 500,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '42px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: '42px',
        },
      },
    },
  },
}

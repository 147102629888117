import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import {
  PaginatedStockOptionExerciseRequests,
  PaginatedStockOptionHolders,
  UserStockOptionsExerciseRequest,
  stockOptionExerciseRequestSchema,
  stockOptionHolderSchema,
  userStockOptionsExerciseRequestSchema,
} from 'types/token/stockOption'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { API_ENDPOINTS as API } from 'services/endpoints'
import { QueryPagination } from 'types/common'
import { validateSchema } from 'utils/zod'

interface UseStockOptionHoldersListProps<TData> {
  tokenId: string
  pagination?: QueryPagination
  searchTerm?: string
  status?: string
  order?: string
  options?: Omit<UseQueryOptions<PaginatedStockOptionHolders, ErrorResponse, TData>, 'queryKey'>
}

export const useStockOptionHoldersList = <TData = PaginatedStockOptionHolders>({
  tokenId,
  pagination,
  searchTerm,
  status,
  order,
  options,
}: UseStockOptionHoldersListProps<TData>) => {
  return useQuery<PaginatedStockOptionHolders, ErrorResponse, TData>({
    queryKey: [
      'stock-option-holders',
      tokenId,
      pagination?.page,
      pagination?.pageSize,
      searchTerm,
      status,
      order,
    ],
    queryFn: async () => {
      const response = await baseApiClient.get(API.token.stockOptionHolders(tokenId), {
        params: {
          page: pagination?.page,
          page_size: pagination?.pageSize,
          q: searchTerm,
          status: status,
          order: order,
        },
      })
      const data = response.data
      return validateSchema(stockOptionHolderSchema.array(), data, 'useStockOptionHoldersList')
    },
    ...options,
  })
}

interface UseStockOptionExerciseRequestListProps<TData> {
  tokenId: string
  pagination?: QueryPagination
  options?: Omit<
    UseQueryOptions<PaginatedStockOptionExerciseRequests, ErrorResponse, TData>,
    'queryKey'
  >
}

export const useStockOptionExerciseRequestList = <TData = PaginatedStockOptionExerciseRequests>({
  tokenId,
  pagination,
  options,
}: UseStockOptionExerciseRequestListProps<TData>) => {
  return useQuery<PaginatedStockOptionExerciseRequests, ErrorResponse, TData>({
    queryKey: ['stock-option-exercise-requests', tokenId, pagination?.page, pagination?.pageSize],
    queryFn: async () => {
      const response = await baseApiClient.get(API.token.exercise.requestList(tokenId), {
        params: {
          page: pagination?.page,
          page_size: pagination?.pageSize,
        },
      })
      const data = response.data
      return validateSchema(
        stockOptionExerciseRequestSchema.array(),
        data,
        'useStockOptionExerciseRequestList',
      )
    },
    ...options,
  })
}

interface UseUserStockOptionExerciseRequestListProps<TData> {
  options?: Omit<
    UseQueryOptions<UserStockOptionsExerciseRequest[], ErrorResponse, TData>,
    'queryKey'
  >
}

export const useUserStockOptionExerciseRequestList = <TData = UserStockOptionsExerciseRequest[]>({
  options,
}: UseUserStockOptionExerciseRequestListProps<TData>) => {
  return useQuery<UserStockOptionsExerciseRequest[], ErrorResponse, TData>({
    queryKey: ['user-stock-option-exercise-requests'],
    queryFn: async () => {
      const response = await baseApiClient.get(API.token.exercise.userRequestList())
      const data = response.data
      return validateSchema(
        userStockOptionsExerciseRequestSchema.array(),
        data,
        'useUserStockOptionExerciseRequestList',
      )
    },
    ...options,
  })
}

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  Stack,
  Box,
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'

import { LoadingButton } from '@mui/lab'
import { useEffect, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { Note } from 'types/note'
import ConfirmDialog from 'components/ConfirmDialog'
import {
  Close as CloseIcon,
  EditOutlined as EditOutlinedIcon,
  DeleteOutline as DeleteOutlineIcon,
} from '@mui/icons-material'
import { formatDate } from 'utils/dates'
import { isOverLimit } from 'utils/numbers'
import { MAX_DESC_NOTES_LENGTH, MAX_NOTES_TITLE_LENGTH } from 'constants/common'

const noteFormSchema = z.object({
  title: z
    .string()
    .min(1, { message: 'Title is required.' })
    .max(MAX_NOTES_TITLE_LENGTH, {
      message: `Max length is ${MAX_NOTES_TITLE_LENGTH} characters.`,
    }),
  text: z
    .string()
    .max(MAX_DESC_NOTES_LENGTH, { message: `Max length is ${MAX_DESC_NOTES_LENGTH} characters.` }),
})

export type NoteForm = z.infer<typeof noteFormSchema>

const NOTE_FORM_DEFAULT_VALUES: NoteForm = {
  title: '',
  text: '',
}

interface NoteDialogProps {
  open: boolean
  handleClose: () => void
  isLoading: boolean
  note: Note | null
  saveNote: (note: Partial<Note>) => void
  removeNote: (noteId: number) => void
}

const NoteDialog = ({
  open,
  handleClose,
  isLoading,
  note,
  saveNote,
  removeNote,
}: NoteDialogProps) => {
  const [isRemoveNoteDialogOpen, setIsRemoveNoteDialogOpen] = useState<boolean>(false)
  const [isEditModeOn, setIsEditModeOn] = useState<boolean>(!note)

  const {
    handleSubmit,
    control,
    reset,
    formState: { dirtyFields, isValid, isDirty },
  } = useForm<NoteForm>({
    mode: 'onBlur',
    resolver: zodResolver(noteFormSchema),
    defaultValues: NOTE_FORM_DEFAULT_VALUES,
  })

  useEffect(() => {
    if (!note) {
      return
    }

    reset({
      title: note.title,
      text: note.text,
    })
  }, [reset, note])

  const onSubmit = (values: NoteForm) => {
    const changedValues = Object.keys(values).reduce((acc: Partial<NoteForm>, field) => {
      const fieldKey = field as keyof NoteForm
      if (dirtyFields[fieldKey]) {
        return { [fieldKey]: values[fieldKey], ...acc }
      }
      return { ...acc }
    }, {})

    saveNote(changedValues)
  }

  return (
    <>
      <Dialog
        onClose={(_event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose()
          }
        }}
        aria-labelledby='Add Note'
        open={open}
        fullWidth={true}
        maxWidth='xs'
        data-testid='note-dialog'
      >
        <DialogTitle>
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Box>
              {note ? (
                <>
                  {note.is_automatic ? note.title : 'Note'}
                  <Typography variant='body2' color='text.secondary'>
                    {note.modified_by
                      ? `${formatDate(note.modified_at, 'MMM dd, yyyy')} | ${note.modified_by}`
                      : `${formatDate(note.created_at, 'MMM dd, yyyy')} | ${note.created_by}`}
                  </Typography>
                </>
              ) : (
                'Add note'
              )}
            </Box>

            <Stack direction='row'>
              {note && !note.is_automatic ? (
                <>
                  <IconButton
                    aria-label='Edit note'
                    onClick={() => setIsEditModeOn(true)}
                    size='small'
                  >
                    <EditOutlinedIcon fontSize='inherit' />
                  </IconButton>
                  <IconButton
                    aria-label='Remove note'
                    onClick={() => setIsRemoveNoteDialogOpen(true)}
                    size='small'
                  >
                    <DeleteOutlineIcon fontSize='inherit' />
                  </IconButton>
                </>
              ) : null}
              <IconButton aria-label='Close note dialog' onClick={handleClose} size='small'>
                <CloseIcon fontSize='inherit' />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>

        <DialogContent sx={{ px: 3, py: 1, '&.MuiDialogContent-root': { pt: 1 } }}>
          <form id='note-form' onSubmit={handleSubmit(onSubmit)} noValidate>
            {!note?.is_automatic ? (
              <Controller
                name='title'
                control={control}
                render={({ field, fieldState: { error } }) => {
                  const charCount = field.value.length || 0
                  const isTitleOverLimit = isOverLimit(charCount, MAX_NOTES_TITLE_LENGTH)
                  return (
                    <TextField
                      label='Title'
                      placeholder='Add a note title'
                      {...field}
                      fullWidth
                      error={!!error || isTitleOverLimit}
                      helperText={error?.message || `${charCount}/${MAX_NOTES_TITLE_LENGTH}`}
                      inputProps={{ maxLength: MAX_NOTES_TITLE_LENGTH }}
                      FormHelperTextProps={{
                        sx: { textAlign: 'right' },
                      }}
                      sx={{ mb: 2 }}
                      disabled={!isEditModeOn}
                      data-testid='note-title'
                    />
                  )
                }}
              />
            ) : null}

            <Controller
              name='text'
              control={control}
              render={({ field, fieldState: { error } }) => {
                const charCount = field.value.length || 0
                const isTextOverLimit = isOverLimit(charCount, MAX_DESC_NOTES_LENGTH)

                return (
                  <TextField
                    multiline
                    rows={12}
                    label='Note'
                    placeholder='Write your note'
                    {...field}
                    fullWidth
                    error={!!error || isTextOverLimit}
                    helperText={error?.message || `${charCount}/${MAX_DESC_NOTES_LENGTH}`}
                    inputProps={{ maxLength: MAX_DESC_NOTES_LENGTH }}
                    FormHelperTextProps={{
                      sx: { textAlign: 'right' },
                    }}
                    disabled={!isEditModeOn}
                    data-testid='note-text'
                  />
                )
              }}
            />
          </form>
        </DialogContent>

        <DialogActions sx={{ px: 3, py: 2 }}>
          {note && !isEditModeOn ? (
            <Button variant='outlined' onClick={handleClose} data-testid='close-note-dialog'>
              Close
            </Button>
          ) : null}

          {note && isEditModeOn ? (
            <>
              <Button
                variant='outlined'
                onClick={() => {
                  setIsEditModeOn(false)
                  reset({
                    title: note.title,
                    text: note.text,
                  })
                }}
                data-testid='cancel-note-edit-mode'
              >
                Cancel
              </Button>
              <LoadingButton
                variant='contained'
                type='submit'
                form='note-form'
                disabled={!isDirty || !isValid}
                loading={isLoading}
                data-testid='save-note'
              >
                Save note
              </LoadingButton>
            </>
          ) : null}

          {!note ? (
            <LoadingButton
              variant='contained'
              type='submit'
              form='note-form'
              disabled={!isDirty || !isValid}
              loading={isLoading}
              data-testid='add-note'
            >
              Add note
            </LoadingButton>
          ) : null}
        </DialogActions>
      </Dialog>

      {isRemoveNoteDialogOpen ? (
        <ConfirmDialog
          isOpen={true}
          handleClose={() => setIsRemoveNoteDialogOpen(false)}
          title='Delete note'
          content='Are you sure you wish to delete this note?'
          onCancel={() => setIsRemoveNoteDialogOpen(false)}
          cancelText='Back'
          onConfirm={() => note?.id && removeNote(note.id)}
          confirmText='Delete note'
          isLoading={isLoading}
          maxWidth='xs'
        />
      ) : null}
    </>
  )
}
export default NoteDialog

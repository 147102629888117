import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useState } from 'react'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CloseIcon from '@mui/icons-material/Close'
import { NumericFormat } from 'react-number-format'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import { API_ENDPOINTS as API } from 'services/endpoints'
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar'
import { LoadingButton } from '@mui/lab'
import { VestedOptionsIcon } from 'icons'
import NoData from 'components/no-data/NoData'
import { CurrencyOptions } from 'types/common'

export interface ExerciseOptionsDialogProps {
  open: boolean
  handleDialogClose: () => void
  holder: {
    id: number
    user_full_name: string
    exercisable_amount: number
    currency: CurrencyOptions
    strike_price: string
  }
}

const exerciseOptionsFormSchema = z.object({
  requested_amount: z.number().min(1, 'Please enter a number greater than 0'),
})

export type ExerciseOptionsFormValues = z.infer<typeof exerciseOptionsFormSchema>

const EXERCISE_OPTIONS_FORM_DEFAULT_VALUES: ExerciseOptionsFormValues = {
  requested_amount: 0,
}

const ExerciseOptionsDialog = ({
  open,
  handleDialogClose: onClose,
  holder,
}: ExerciseOptionsDialogProps) => {
  const queryClient = useQueryClient()
  const enqueueSnackbar = useEnqueueSnackbar()

  const [dialogStep, setDialogStep] = useState<number>(0)

  const { mutate: exerciseOptions, isPending } = useMutation<
    ExerciseOptionsFormValues,
    ErrorResponse,
    ExerciseOptionsFormValues
  >({
    mutationFn: (reqAmount) =>
      baseApiClient
        .post(API.token.exercise.create(), {
          ...reqAmount,
          iso: holder.id,
        })
        .then((response) => response.data),
    onSuccess: () => {
      setDialogStep(2)
      queryClient.invalidateQueries({ queryKey: ['tokens'] })
      queryClient.invalidateQueries({ queryKey: ['current-user-balance'] })
      queryClient.invalidateQueries({ queryKey: ['stock-option-exercise-requests'] })
      queryClient.invalidateQueries({ queryKey: ['user-stock-option-exercise-requests'] })
    },
    onError: (error) => {
      if (error.detail) {
        enqueueSnackbar(error.detail, { variant: 'error' })
        return
      }
      enqueueSnackbar('Something went wrong. Please try Again.', { variant: 'error' })
    },
  })

  const {
    control,
    handleSubmit,
    formState: { isValid, isLoading },
  } = useForm({
    mode: 'onBlur',
    resolver: zodResolver(exerciseOptionsFormSchema),
    defaultValues: EXERCISE_OPTIONS_FORM_DEFAULT_VALUES,
  })

  const optionsToExerciseWatch = useWatch({
    control,
    name: 'requested_amount',
  })

  const handleClose = () => {
    onClose()
  }

  const onSubmit = (data: ExerciseOptionsFormValues) => {
    exerciseOptions(data)
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      {!holder.exercisable_amount ? (
        <Stack alignItems='center' flexWrap='wrap' padding={4}>
          <NoData
            headline='No vested options'
            content='When your granted options are vested, you will be able to exercise.'
          >
            <Stack
              width={106}
              height={106}
              borderRadius='50%'
              sx={{ backgroundColor: 'grey.100' }}
              alignItems='center'
              justifyContent='center'
            >
              <VestedOptionsIcon sx={{ fontSize: '70px', color: 'grey.500' }} />
            </Stack>
          </NoData>
        </Stack>
      ) : (
        <>
          <form id='exercise-options-form' noValidate onSubmit={handleSubmit(onSubmit)}>
            {/* DIALOG 1 */}
            {dialogStep === 0 ? (
              <>
                <DialogTitle>Exercise Options</DialogTitle>
                <IconButton
                  aria-label='close'
                  onClick={handleClose}
                  size='small'
                  color='primary'
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <Stack gap={3} width={600} height='auto' pt={2} p={3}>
                  <Typography>
                    Exercise options for <strong>{holder.user_full_name}</strong>
                  </Typography>
                  <Stack direction='row' justifyContent='start'>
                    <Box>
                      <Typography color='text.secondary' variant='subtitle1'>
                        Options available
                      </Typography>

                      <Typography>
                        <NumericFormat
                          value={holder.exercisable_amount}
                          displayType='text'
                          thousandSeparator
                        />
                      </Typography>
                    </Box>
                    <Box ml={16}>
                      <Typography color='text.secondary' variant='subtitle1'>
                        Price per share
                      </Typography>
                      <Typography>
                        {holder.currency === 'dollar' ? '$' : '€'}
                        {holder.strike_price}
                      </Typography>
                    </Box>
                  </Stack>
                  <Box>
                    <Controller
                      name='requested_amount'
                      control={control}
                      render={({
                        field: { ref, value, onChange, onBlur },
                        fieldState: { error },
                      }) => (
                        <NumericFormat
                          id='options_to_exercise_amount'
                          customInput={TextField}
                          thousandSeparator
                          allowNegative={false}
                          decimalScale={0}
                          fullWidth
                          required
                          onBlur={onBlur}
                          inputRef={ref}
                          label='Options to exercise'
                          error={!!error || holder.exercisable_amount < optionsToExerciseWatch}
                          helperText={error?.message}
                          data-testid='options_to_exercise_amount'
                          value={value}
                          onValueChange={(value) => onChange(value.floatValue)}
                        />
                      )}
                    />
                  </Box>
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6' color='text.secondary'>
                      Total cost
                    </Typography>

                    <Typography variant='h6'>
                      <NumericFormat
                        value={
                          optionsToExerciseWatch === 0 || !optionsToExerciseWatch
                            ? 0
                            : optionsToExerciseWatch * parseFloat(holder.strike_price)
                        }
                        displayType='text'
                        thousandSeparator
                        decimalScale={2}
                        prefix={holder.currency === 'dollar' ? '$' : '€'}
                      />
                    </Typography>
                  </Stack>
                  <Stack direction='row' justifyContent='flex-end'>
                    <Button
                      type='submit'
                      variant='contained'
                      disabled={
                        !isValid || isLoading || holder.exercisable_amount < optionsToExerciseWatch
                      }
                      onClick={() => {
                        setDialogStep(1)
                      }}
                    >
                      Next
                    </Button>
                  </Stack>
                </Stack>
              </>
            ) : null}

            {/* DIALOG 2 */}
            {dialogStep === 1 ? (
              <>
                <DialogTitle>Exercise options for {holder.user_full_name}</DialogTitle>
                <IconButton
                  aria-label='close'
                  onClick={handleClose}
                  size='small'
                  color='primary'
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <Stack gap={3} width={600} height='auto' pt={2} p={3}>
                  <Typography>
                    Are you sure you would like to exercise these options for the cost below?
                  </Typography>
                  <Stack direction='row' justifyContent='start'>
                    <Box>
                      <Typography color='text.secondary' variant='subtitle1'>
                        Exercising Options
                      </Typography>
                      <Typography>
                        <NumericFormat
                          value={optionsToExerciseWatch}
                          displayType='text'
                          thousandSeparator
                          decimalScale={2}
                        />{' '}
                      </Typography>
                    </Box>
                    <Box ml={8}>
                      <Typography color='text.secondary' variant='subtitle1'>
                        Price per share
                      </Typography>
                      <Typography>
                        <NumericFormat
                          value={holder.strike_price}
                          displayType='text'
                          thousandSeparator
                          decimalScale={2}
                          prefix={holder.currency === 'dollar' ? '$' : '€'}
                        />
                      </Typography>
                    </Box>
                  </Stack>

                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6' color='text.secondary'>
                      Total cost
                    </Typography>

                    <Typography variant='h6'>
                      <NumericFormat
                        value={optionsToExerciseWatch * parseFloat(holder.strike_price)}
                        displayType='text'
                        thousandSeparator
                        decimalScale={2}
                        prefix={holder.currency === 'dollar' ? '$' : '€'}
                      />
                    </Typography>
                  </Stack>

                  <Stack direction='row' justifyContent='flex-end' gap={1}>
                    <Button
                      variant='text'
                      onClick={() => {
                        setDialogStep(0)
                      }}
                    >
                      Back
                    </Button>
                    <LoadingButton
                      variant='contained'
                      color='primary'
                      type='submit'
                      data-testid='exercise-options'
                      loading={isPending}
                    >
                      Exercise
                    </LoadingButton>
                  </Stack>
                </Stack>
              </>
            ) : null}
          </form>

          {/* DIALOG 3 */}
          {dialogStep === 2 ? (
            <>
              <Stack
                justifyContent='center'
                alignItems='center'
                gap={1}
                width={600}
                height='auto'
                pt={2}
                p={3}
              >
                <CheckCircleOutlineIcon sx={{ color: 'success.light', fontSize: '4rem' }} />
                <Typography variant='h5'>Great!</Typography>
                <Typography>
                  You have successfully exercised{' '}
                  <NumericFormat
                    value={optionsToExerciseWatch}
                    displayType='text'
                    thousandSeparator
                    decimalScale={2}
                  />{' '}
                  {optionsToExerciseWatch === 1 ? 'option' : 'options'}. <br /> This will be
                  reflected on your dashboard soon.
                </Typography>
                <Typography variant='h6'>
                  Total cost:{' '}
                  <NumericFormat
                    value={optionsToExerciseWatch * parseFloat(holder.strike_price)}
                    displayType='text'
                    thousandSeparator
                    decimalScale={2}
                    prefix={holder.currency === 'dollar' ? '$' : '€'}
                  />
                </Typography>

                <Box mt={2}>
                  <Button
                    variant='contained'
                    onClick={() => {
                      handleClose()
                    }}
                  >
                    Okay
                  </Button>
                </Box>
              </Stack>
            </>
          ) : null}
        </>
      )}
    </Dialog>
  )
}

export default ExerciseOptionsDialog

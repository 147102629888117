import { addressSchema } from './common'
import { emailRegexExpression } from 'utils/validations'
import { z } from 'zod'
import {
  MAX_EMPLOYMENT_TYPE_NAME_LENGTH,
  MAX_TIER_NAME_LENGTH,
  MAX_DESC_NOTES_LENGTH,
} from 'constants/common'

export const organizationThemeSchema = z.object({
  subdomain: z.string(),
  logo_thumbnail: z.string().nullable(),
  name: z.string(),
  org_color: z.string(),
  restricted_use: z.boolean(),
  free_use: z.boolean(),
})

export type OrganizationTheme = z.infer<typeof organizationThemeSchema>

export const organizationSchema = organizationThemeSchema.omit({ restricted_use: true }).extend({
  logo: z.string().nullable(),
  org_address: addressSchema.nullable(),
  status_count: z.object({
    created: z.number(),
    invited: z.number(),
    active: z.number(),
    inactive: z.number(),
  }),
  trial_end_dt: z.string().nullable(),
  free_use: z.boolean(),
  free_trial_days_left: z.number().nullable(),
  has_subscribed: z.boolean(),
})

export type Organization = z.infer<typeof organizationSchema>

export const organizationTierSchema = z.object({
  id: z.number(),
  name: z
    .string()
    .min(1, { message: 'Tier name is required.' })
    .max(MAX_TIER_NAME_LENGTH, { message: `Max length is ${MAX_TIER_NAME_LENGTH} characters.` }),
  description: z.string().max(MAX_DESC_NOTES_LENGTH, {
    message: `Max length is ${MAX_DESC_NOTES_LENGTH} characters.`,
  }),
  position: z.number(),
  jobs_count: z.number(),
  is_default: z.boolean(),
})

const assignedUserSchema = z.object({
  uuid: z.string(),
  email: z.string().email(),
  first_name: z.string(),
  last_name: z.string(),
  full_name: z.string(),
  avatar_thumbnail: z.string().nullable(),
})

export const organizationTierDetailsSchema = organizationTierSchema.extend({
  userjob_set: z
    .object({
      id: z.number(),
      user: assignedUserSchema,
    })
    .array(),
})

export const organizationTierFormSchema = organizationTierSchema.omit({
  id: true,
  position: true,
  jobs_count: true,
  is_default: true,
})

export type Tier = z.infer<typeof organizationTierSchema>
export type TierUser = z.infer<typeof assignedUserSchema>
export type TierDetails = z.infer<typeof organizationTierDetailsSchema>
export type TierForm = z.infer<typeof organizationTierFormSchema>

export const createUsersFormSchema = z.object({
  emails: z
    .array(
      z.string().regex(emailRegexExpression, {
        message: 'Email is invalid.',
      }),
    )
    .min(1, 'At least one email is required.'),
  role: z.string().min(1, { message: 'Role is required.' }),
  company_tier: z.number().or(z.literal('')),
  employment_type: z.number().or(z.literal('')),
})

export type CreateUsersForm = z.infer<typeof createUsersFormSchema>

export const workspaceSchema = organizationThemeSchema
  .omit({ restricted_use: true, free_use: true })
  .extend({
    email: z.string().min(1, { message: 'Email is required.' }).regex(emailRegexExpression, {
      message: 'Email is invalid.',
    }),
  })

export type Workspace = z.infer<typeof workspaceSchema>

export const employmentTypeSchema = z.object({
  id: z.number(),
  name: z
    .string()
    .min(1, { message: 'Employment type name is required.' })
    .max(MAX_EMPLOYMENT_TYPE_NAME_LENGTH, {
      message: `Max length is ${MAX_EMPLOYMENT_TYPE_NAME_LENGTH}  characters.`,
    }),
  description: z.string(),
  multiplier: z.string(),
  jobs_count: z.number(),
  is_default: z.boolean(),
})

export type EmploymentType = z.infer<typeof employmentTypeSchema>

export const employmentTypeDetailsSchema = employmentTypeSchema.extend({
  userjob_set: z
    .object({
      id: z.number(),
      user: assignedUserSchema,
    })
    .array(),
})

export type EmploymentTypeDetails = z.infer<typeof employmentTypeDetailsSchema>

const reassignUserSchema = z.object({
  values: z
    .object({
      user_uuid: z.string(),
      obj_id: z.number(),
    })
    .array(),
})

export type ReassignUser = z.infer<typeof reassignUserSchema>

import { Box, Paper } from '@mui/material'
import KeeprTable from 'components/KeeprTable'
import StockOptionStatusChip from 'components/status-chip/StockOptionStatusChip'
import StockOptionExerciseDialogs from './stock-option-holders/stock-option-holder-details/dialogs/StockOptionExerciseDialogs'
import { MRT_ColumnDef, MRT_PaginationState } from 'material-react-table'
import { useMemo, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { useOutletContext } from 'react-router-dom'
import {
  ExerciseRequestStatus,
  PaginatedStockOptionExerciseRequests,
  StockOptionExerciseRequest,
} from 'types/token/stockOption'
import { useStockOptionExerciseRequestList } from 'services/api/stockOption'
import { formatDate } from 'utils/dates'

const initialExerciseRequestsData: PaginatedStockOptionExerciseRequests = {
  count: 0,
  next: null,
  previous: null,
  results: [],
}

const StockOptionExercising = () => {
  const tokenId = useOutletContext<string>()

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    //material-react-table and tanstack table under the hood use zero based index, our back-end uses 1 based index
    pageIndex: 0,
    pageSize: 5,
  })

  const { data: exerciseRequests = initialExerciseRequestsData, isLoading } =
    useStockOptionExerciseRequestList({
      tokenId,
      pagination: {
        page: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
      },
      options: {
        placeholderData: (previousData) => previousData,
      },
    })

  const data = useMemo(() => exerciseRequests?.results, [exerciseRequests]) || []

  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'requested_amount',
          header: 'Requested Amount',
          size: 150,
          enableSorting: false,
          muiTableHeadCellProps: {
            align: 'left',
          },
          muiTableBodyCellProps: {
            align: 'left',
          },
          Cell: ({ cell }) => {
            const requestedAmount = cell.getValue<number>() || 0
            return <NumericFormat value={requestedAmount} displayType={'text'} thousandSeparator />
          },
        },
        {
          accessorKey: 'status',
          header: 'Status',
          size: 200,
          enableSorting: false,
          muiTableHeadCellProps: {
            align: 'center',
          },
          muiTableBodyCellProps: {
            align: 'center',
          },
          Cell: ({ cell }) => {
            const status = cell.getValue<ExerciseRequestStatus>()
            return <StockOptionStatusChip status={status} />
          },
        },
        {
          accessorKey: 'created_at',
          header: 'Created at',
          size: 200,
          muiTableHeadCellProps: {
            align: 'center',
          },
          muiTableBodyCellProps: {
            align: 'center',
          },
          enableSorting: false,
          Cell: ({ row }) => {
            return <>{formatDate(row.original.created_at, 'MMM. dd, yyyy')}</>
          },
        },
        {
          accessorKey: 'note_created_by',
          header: 'Approved by',
          size: 200,
          muiTableHeadCellProps: {
            align: 'center',
          },
          muiTableBodyCellProps: {
            align: 'center',
          },
          enableSorting: false,
          Cell: ({ row }) => {
            return <>{row.original.note?.created_by || 'N/A'}</>
          },
        },
        {
          accessorKey: 'modified_at',
          header: 'Modified at',
          size: 200,
          enableSorting: false,
          Cell: ({ row }) => {
            return <>{formatDate(row.original.modified_at, 'MMM. dd, yyyy')}</>
          },
        },
        {
          header: 'Actions',
          id: 'action',
          enableSorting: false,
          size: 100,
          muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ row }) => {
            return <StockOptionExerciseDialogs exerciseRequest={row.original} />
          },
        },
      ] as MRT_ColumnDef<StockOptionExerciseRequest>[],
    [],
  )

  return (
    <Box display='flex' flexDirection='column' gap={4} pt={2}>
      <Paper elevation={1}>
        <KeeprTable
          columns={columns}
          data={data}
          onPaginationChange={setPagination}
          rowCount={exerciseRequests?.count || 0}
          state={{
            showSkeletons: isLoading,
            showProgressBars: isLoading,
            pagination,
          }}
        />
      </Paper>
    </Box>
  )
}

export default StockOptionExercising

// need mock data
//query has paginated response
//action to view note if there is one
//actions column needs to have approve and reject buttons
//approve button should change status to approved
//reject button should change status to rejected
//note should be displayed in a dialog
//note should have a close button
//note should have a title and text
//note should have created by and created at
//note should have modified by and modified at

import { Breadcrumbs, Icon, Link, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import NavLink from 'components/NavLink'
import { useBreadcrumbs } from 'hooks/useBreadcrumbsStore'

const AppBreadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs()

  const theme = useTheme()

  return (
    <Breadcrumbs
      aria-label='breadcrumb'
      sx={{
        '.MuiBreadcrumbs-separator': {
          color: theme.palette.primary.contrastText,
        },
      }}
    >
      {breadcrumbs.list.map((breadcrumb, index, list) => {
        const isFirst = index === 0
        const isLast = index === list.length - 1

        return isLast ? (
          <Typography
            key={index}
            display='flex'
            alignItems='center'
            gap={1}
            color={theme.palette.primary.contrastText}
          >
            {isFirst && breadcrumbs.icon ? (
              <Icon component={breadcrumbs.icon} fontSize='small' />
            ) : (
              ''
            )}
            {breadcrumb.text}
          </Typography>
        ) : (
          <Link
            key={index}
            //eslint-disable-next-line
            component={NavLink as any}
            display='flex'
            alignItems='center'
            gap={1}
            color={theme.palette.primary.contrastText}
            underline='none'
            to={breadcrumb.href}
          >
            {isFirst && breadcrumbs.icon ? (
              <Icon component={breadcrumbs.icon} fontSize='small' />
            ) : (
              ''
            )}
            {breadcrumb.text}
          </Link>
        )
      })}
    </Breadcrumbs>
  )
}

export default AppBreadcrumbs

import { useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import AuthLayout from '../components/AuthLayout'
import { LoadingButton } from '@mui/lab'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { EmailCredentials, emailSchema } from 'types/auth'
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar'
import { useMutation } from '@tanstack/react-query'
import { baseApiClient, ErrorResponse } from 'services/axiosConfig'
import { API_ENDPOINTS as API } from 'services/endpoints'
import CheckYourEmail from './CheckYourEmail'

const ResetPassword = () => {
  const enqueueSnackbar = useEnqueueSnackbar()

  const [emailError, setEmailError] = useState<string | null>(null)
  const [resetSuccess, setResetSuccess] = useState<boolean>(false)

  const { mutate: resetPassword, isPending: isResetPasswordPending } = useMutation<
    unknown,
    ErrorResponse,
    EmailCredentials
  >({
    mutationFn: (passData) =>
      baseApiClient.post(API.auth.passwordReset(), passData).then((response) => response.data),
    onSuccess: () => {
      enqueueSnackbar('Password reset link sent to your email.', { variant: 'success' })
      setResetSuccess(true)
    },
    onError: (error) => {
      if (error.detail) {
        setEmailError(error.detail)
        return
      }

      enqueueSnackbar('Something went wrong. Please try Again.', { variant: 'error' })
    },
  })

  const { handleSubmit, control, watch } = useForm<EmailCredentials>({
    mode: 'onBlur',
    resolver: zodResolver(emailSchema),
    defaultValues: {
      email: '',
    },
  })

  const userEmail = watch('email')

  const onSubmit = (passData: EmailCredentials) => {
    resetPassword(passData)
  }

  if (resetSuccess) {
    return (
      <CheckYourEmail
        setResetSuccess={(isSuccess) => setResetSuccess(isSuccess)}
        onSubmit={onSubmit}
        userEmail={{ email: userEmail }}
      />
    )
  }

  return (
    <AuthLayout data-testid='reset-password'>
      <Typography variant='h5'>Reset your password</Typography>
      <Typography variant='body2' color='text.secondary'>
        Tell us your email address and we’ll send you a link to reset your password.
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box display='flex' flexDirection='column' gap={2} mt={4}>
          <Controller
            name='email'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                label='Email'
                type='email'
                {...field}
                fullWidth
                error={!!error}
                helperText={error?.message}
                data-testid='reset-password-field'
              />
            )}
          />

          <LoadingButton
            sx={{ mt: 1.5 }}
            variant='contained'
            type='submit'
            loading={isResetPasswordPending}
            data-testid='send-link'
          >
            Send link
          </LoadingButton>

          {emailError ? (
            <Typography variant='caption' sx={{ color: 'error.main' }}>
              {emailError}
            </Typography>
          ) : null}

          <Button
            variant='text'
            color='primary'
            component={RouterLink}
            to='/login'
            data-testid='return-to-sign-in'
          >
            Return to sign in
          </Button>
        </Box>
      </form>
    </AuthLayout>
  )
}

export default ResetPassword

import { useEffect, useState } from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  IconButton,
} from '@mui/material'
import { useRestrictedUse, useFreeUse } from 'hooks/useOrganizationStore'
import EmbeddedStripeForm from './EmbeddedStripeForm'
import StripePortal from './StripePortal'
import { useOrganization } from 'services/api/organization'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { Close as CloseIcon } from '@mui/icons-material'
import { useQueryClient } from '@tanstack/react-query'
import OrganizationFreeUse from './OrganizationFreeUse'

const PlansAndBilling = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const isRestrictedUse = useRestrictedUse()
  const isFreeUse = useFreeUse()

  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const subscribeSuccess = searchParams.get('subscribe_success')
  const customerPortalUpdate = searchParams.get('customer_portal_update')

  const queryClient = useQueryClient()

  const { data: organizationData, isLoading: isOrganizationLoading } = useOrganization({
    options: {
      enabled: !isRestrictedUse && !isFreeUse,
    },
  })

  const handleDialogOpen = () => {
    setIsDialogOpen(true)
  }

  const handleClose = () => {
    setIsDialogOpen(false)
  }

  useEffect(() => {
    if (subscribeSuccess || customerPortalUpdate) {
      queryClient.invalidateQueries({ queryKey: ['organization-details'] })
      handleDialogOpen()
    }
  }, [subscribeSuccess, customerPortalUpdate, queryClient])

  return (
    <>
      <Stack gap={2} alignItems='center'>
        {isFreeUse ? <OrganizationFreeUse /> : null}
        {isOrganizationLoading ? (
          <CircularProgress size={30} disableShrink />
        ) : organizationData?.free_use ? (
          <OrganizationFreeUse />
        ) : organizationData?.has_subscribed ? (
          <StripePortal />
        ) : (
          <EmbeddedStripeForm hasSubscribed={!!organizationData?.has_subscribed} />
        )}
      </Stack>
      {(subscribeSuccess || customerPortalUpdate) && isDialogOpen && (
        <Dialog
          open={isDialogOpen}
          onClose={() => {
            navigate('/settings/plans-and-billing')
            handleClose()
          }}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          disableEscapeKeyDown={true}
        >
          <DialogTitle>
            Stripe
            <IconButton
              aria-label='close'
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'text.primary',
                '& svg': {
                  fontSize: 24,
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Stack px={2} width='400px'>
              <DialogContentText>
                {subscribeSuccess
                  ? 'Stripe subscription created successfully.'
                  : 'Customer portal updated successfully.'}
              </DialogContentText>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Stack py={1}>
              <Button
                variant='contained'
                onClick={() => {
                  navigate('/settings/plans-and-billing')
                  handleClose()
                }}
              >
                Dismiss
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default PlansAndBilling

import { Link } from 'react-router-dom'
import { Container, Typography, Grid, Button } from '@mui/material'
const Error = () => {
  return (
    <Container data-testid='404-page-container'>
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
        height='100vh'
        m={2}
      >
        <Grid item xs={12} sm={6}>
          <Typography variant='h1'>Error 404</Typography>
          <Typography>Page not found</Typography>
          <Link to='/' style={{ textDecoration: 'none' }}>
            <Button variant='contained' color='primary' sx={{ mt: 2 }} data-testid='home'>
              Home
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Container>
  )
}
export default Error

import { Grid, Stack, Tab, Tabs } from '@mui/material'
import {
  STOCK_OPTION_HOLDER_OVERVIEW_TABS,
  StockOptionHolder,
  StockOptionHolderOverviewTabs,
} from 'types/token/stockOption'

import StockOptionHolderOverview from './StockOptionHolderOverview'
import VestingChart from 'features/tokens/token/stock-options/stock-option-holders/stock-option-holder-details/VestingChart'
import VestingSchedule from 'features/tokens/token/stock-options/stock-option-holders/stock-option-holder-details/VestingSchedule'
import { useState } from 'react'
import StockOptionHolderNotes from './StockOptionHolderNotes'

export interface StockOptionHolderPanelProps {
  holder: StockOptionHolder
}

const StockOptionHolderPanel = ({ holder }: StockOptionHolderPanelProps) => {
  const [activeTab, setActiveTab] = useState<StockOptionHolderOverviewTabs>(
    STOCK_OPTION_HOLDER_OVERVIEW_TABS.Chart,
  )

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={5} display='flex'>
        <StockOptionHolderOverview holder={holder} />
      </Grid>
      <Grid item xs={12} md={7} display='flex'>
        <Stack gap={2} width='100%'>
          <Tabs
            aria-label='token holder overview tabs'
            value={activeTab}
            onChange={(event: React.SyntheticEvent, tab: StockOptionHolderOverviewTabs) =>
              setActiveTab(tab)
            }
          >
            <Tab label='Chart' value={STOCK_OPTION_HOLDER_OVERVIEW_TABS.Chart} />
            <Tab label='Schedule' value={STOCK_OPTION_HOLDER_OVERVIEW_TABS.Schedule} />
            <Tab label='Notes' value={STOCK_OPTION_HOLDER_OVERVIEW_TABS.Notes} />
          </Tabs>
          {activeTab === STOCK_OPTION_HOLDER_OVERVIEW_TABS.Chart ? (
            <VestingChart options={holder} />
          ) : null}
          {activeTab === STOCK_OPTION_HOLDER_OVERVIEW_TABS.Schedule ? (
            <VestingSchedule
              grantDate={holder.grant_date}
              totalOptionsAmount={holder.total_options_amount}
              vestingPeriod={holder.vesting_period}
              cliffPeriod={holder.cliff_period}
              distribution_frequency={holder.distribution_frequency}
              schedule={holder.schedule}
            />
          ) : null}
          {activeTab === STOCK_OPTION_HOLDER_OVERVIEW_TABS.Notes ? (
            <StockOptionHolderNotes holderIsoId={holder.id} />
          ) : null}
        </Stack>
      </Grid>
    </Grid>
  )
}

export default StockOptionHolderPanel

import { useCallback, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogContent, DialogTitle, IconButton, Slide, Tab, Tabs } from '@mui/material'
import Profile from './Profile'
import Account from './Account'
import { ArrowBack } from '@mui/icons-material'
import ChangePassword from './ChangePassword'
import { ObjectValues } from 'types/common'
import Legal from './Legal'

interface UserSettingsProps {
  isOpen: boolean
  handleClose: () => void
}

export const ActiveDialog = {
  Dashboard: 'dashboard',
  ChangePassword: 'change-password',
  Legal: 'legal',
} as const

export type ActiveDialogType = ObjectValues<typeof ActiveDialog>

const DialogTitles: Record<ActiveDialogType, string> = {
  dashboard: 'Settings',
  'change-password': 'Change Password',
  legal: 'Legal',
}

const UserSettings = ({ isOpen, handleClose }: UserSettingsProps) => {
  const [activeTab, setActiveTab] = useState(0)
  const [activeDialogContent, setActiveDialogContent] = useState<ActiveDialogType>(
    ActiveDialog.Dashboard,
  )

  const handleTabChange = useCallback((event: React.SyntheticEvent, tab: number) => {
    setActiveTab(tab)
  }, [])

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='Settings'
      open={isOpen}
      fullWidth={true}
      data-testid='user-settings-modal'
    >
      <DialogTitle>
        {activeDialogContent !== ActiveDialog.Dashboard ? (
          <IconButton
            aria-label='back'
            onClick={() => setActiveDialogContent(ActiveDialog.Dashboard)}
          >
            <ArrowBack fontSize='small' />
          </IconButton>
        ) : null}
        {DialogTitles[activeDialogContent]}
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'text.primary',
            '& svg': {
              fontSize: 24,
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ px: 3, py: 1, '&.MuiDialogContent-root': { pt: 1 } }}>
        <Slide direction='right' in={activeDialogContent === ActiveDialog.Dashboard} mountOnEnter>
          <div>
            {activeDialogContent === ActiveDialog.Dashboard ? (
              <>
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  aria-label='User settings tabs'
                  sx={{ mb: 3 }}
                >
                  <Tab label='Profile' data-testid='tab-profile' />
                  <Tab label='Account' data-testid='tab-account' />
                  <Tab label='Legal' data-testid='tab-legal' />
                  <Tab label='Notifications' data-testid='tab-notifications' disabled />
                </Tabs>

                {activeTab === 0 ? <Profile handleClose={handleClose} /> : null}
                {activeTab === 1 ? (
                  <Account
                    handleClose={handleClose}
                    onChangeDialogContent={(content) => setActiveDialogContent(content)}
                  />
                ) : null}
                {activeTab === 2 ? <Legal handleClose={handleClose} /> : null}
              </>
            ) : null}
          </div>
        </Slide>
        <Slide direction='left' in={activeDialogContent !== ActiveDialog.Dashboard} mountOnEnter>
          <div>
            {activeDialogContent === ActiveDialog.ChangePassword ? (
              <ChangePassword
                onReturnToAccount={() => setActiveDialogContent(ActiveDialog.Dashboard)}
              />
            ) : null}
          </div>
        </Slide>
      </DialogContent>
    </Dialog>
  )
}
export default UserSettings

import { SvgIcon } from '@mui/material'
import { SvgIconProps } from '@mui/material'

const GenericTokenIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M16 32C11.7565 32 7.68698 30.3142 4.68635 27.3137C1.68584 24.313 0 20.2435 0 16C0 11.7565 1.68578 7.68698 4.68635 4.68635C7.68698 1.68584 11.7565 0 16 0C20.2435 0 24.313 1.68578 27.3137 4.68635C30.3142 7.68698 32 11.7565 32 16C31.9959 20.2422 30.3089 24.3095 27.3092 27.3092C24.3095 30.3088 20.2422 31.9959 16 32ZM16 1.52381C12.1606 1.52381 8.47841 3.04898 5.76381 5.76381C3.04921 8.47864 1.52381 12.1606 1.52381 16C1.52381 19.8394 3.04898 23.5216 5.76381 26.2362C8.47864 28.9508 12.1606 30.4762 16 30.4762C19.8394 30.4762 23.5216 28.951 26.2362 26.2362C28.9508 23.5214 30.4762 19.8394 30.4762 16C30.4721 12.1619 28.9457 8.48222 26.2317 5.76825C23.5178 3.05429 19.8381 1.52794 16 1.52381Z'
          fill='currentColor'
        />
        <path
          d='M16.0001 27.4286C12.9691 27.4286 10.062 26.2245 7.91884 24.0813C5.7756 21.9381 4.57153 19.0312 4.57153 16C4.57153 12.9689 5.77566 10.0619 7.91884 7.91877C10.0621 5.77554 12.969 4.57147 16.0001 4.57147C19.0312 4.57147 21.9382 5.7756 24.0814 7.91877C26.2246 10.062 27.4287 12.9689 27.4287 16C27.4287 18.0061 26.9006 19.9769 25.8976 21.7143C24.8945 23.4517 23.4518 24.8943 21.7144 25.8975C19.977 26.9006 18.0061 27.4286 16.0001 27.4286ZM16.0001 6.09528C13.3732 6.09528 10.8538 7.13881 8.9963 8.99633C7.13877 10.8539 6.09525 13.3732 6.09525 16.0001C6.09525 18.6271 7.13877 21.1465 8.9963 23.0039C10.8538 24.8615 13.3731 25.905 16.0001 25.905C18.6271 25.905 21.1465 24.8615 23.0039 23.0039C24.8614 21.1464 25.905 18.6271 25.905 16.0001C25.901 13.3745 24.8561 10.8576 22.9995 9.00077C21.1428 7.14414 18.6258 6.09925 16.0001 6.09528Z'
          fill='currentColor'
        />
        <path
          d='M15.9997 21.0743C15.7977 21.0768 15.604 20.9937 15.4664 20.8457L11.154 16.5334C11.0067 16.3951 10.9231 16.202 10.9231 16C10.9231 15.798 11.0067 15.6049 11.154 15.4666L15.4664 11.1542C15.6046 11.0069 15.7977 10.9233 15.9997 10.9233C16.2017 10.9233 16.3948 11.0069 16.5331 11.1542L20.8455 15.4666C20.9928 15.6049 21.0764 15.798 21.0764 16C21.0764 16.202 20.9928 16.3951 20.8455 16.5334L16.5331 20.8457C16.3955 20.9937 16.2017 21.0768 15.9997 21.0743ZM12.7693 16L15.9997 19.2305L19.2302 16L15.9997 12.7695L12.7693 16Z'
          fill='currentColor'
        />
        <path
          d='M16.0001 9.14287C15.9049 9.16556 15.8058 9.16556 15.7105 9.14287C15.6154 9.11038 15.5311 9.05234 15.4667 8.97521C15.3194 8.83694 15.2358 8.64397 15.2358 8.44197C15.2358 8.23984 15.3194 8.04689 15.4667 7.90861C15.5547 7.7784 15.6821 7.67981 15.8303 7.62773C15.9785 7.57552 16.1396 7.57254 16.2897 7.61905C16.3848 7.65154 16.4691 7.70957 16.5335 7.7867C16.6808 7.92497 16.7644 8.11794 16.7644 8.31994C16.7644 8.52208 16.6808 8.71502 16.5335 8.85331C16.4691 8.93044 16.3848 8.98848 16.2897 9.02097C16.2051 9.08557 16.1054 9.12762 16.0001 9.14287Z'
          fill='currentColor'
        />
        <path
          d='M8.38098 16.7619C8.18009 16.7581 7.98863 16.6765 7.8465 16.5345C7.70451 16.3924 7.62292 16.2009 7.61907 16C7.59638 15.9048 7.59638 15.8057 7.61907 15.7105C7.65156 15.6154 7.7096 15.531 7.78673 15.4667C7.88792 15.3362 8.02966 15.243 8.18962 15.2017C8.34946 15.1603 8.5186 15.1731 8.6705 15.2381C8.85391 15.3194 9.0006 15.4661 9.08184 15.6496C9.10354 15.7448 9.10354 15.8438 9.08184 15.939C9.10453 16.0343 9.10453 16.1335 9.08184 16.2286C9.00061 16.4121 8.85391 16.5588 8.6705 16.64C8.58593 16.7047 8.48623 16.7467 8.38095 16.7619L8.38098 16.7619Z'
          fill='currentColor'
        />
        <path
          d='M16.0001 24.381C15.9049 24.4037 15.8058 24.4037 15.7105 24.381C15.6154 24.3485 15.5311 24.2905 15.4667 24.2133C15.3194 24.0751 15.2358 23.8821 15.2358 23.6801C15.2358 23.478 15.3194 23.285 15.4667 23.1467C15.5713 23.0395 15.7057 22.9662 15.8524 22.9363C15.9991 22.9064 16.1515 22.9213 16.2896 22.9791C16.3847 23.0116 16.4691 23.0696 16.5334 23.1467C16.6814 23.2843 16.7645 23.478 16.762 23.6801C16.7836 23.7753 16.7836 23.8743 16.762 23.9695C16.7295 24.0647 16.6714 24.149 16.5943 24.2133C16.4317 24.3562 16.2134 24.4178 16.0001 24.381L16.0001 24.381Z'
          fill='currentColor'
        />
        <path
          d='M23.6192 16.7619C23.4183 16.7581 23.2268 16.6765 23.0847 16.5345C22.9427 16.3924 22.8611 16.2009 22.8572 16C22.8357 15.9047 22.8357 15.8058 22.8572 15.7105C22.8897 15.6153 22.9478 15.531 23.0249 15.4667C23.1673 15.327 23.3587 15.2489 23.5581 15.2489C23.7577 15.2489 23.9491 15.327 24.0915 15.4667C24.1686 15.531 24.2267 15.6153 24.2592 15.7105C24.2807 15.8058 24.2807 15.9047 24.2592 16C24.2553 16.2009 24.1737 16.3924 24.0317 16.5345C23.8896 16.6765 23.6981 16.7581 23.4973 16.7619H23.6192Z'
          fill='currentColor'
        />
      </svg>
    </SvgIcon>
  )
}

export default GenericTokenIcon

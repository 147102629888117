import { useMemo } from 'react'
import { Chip, Stack, Typography } from '@mui/material'
import { type MRT_ColumnDef } from 'material-react-table'
import { NumericFormat } from 'react-number-format'
import Avatar from 'components/Avatar'
import { UserTokenBalance } from 'types/token/profitShare'
import { GenericTokenIcon } from 'icons'
import KeeprTable from 'components/KeeprTable'

interface PeopleDetailsProfitShareAssetsProps {
  userBalance: UserTokenBalance[]
}

const PeopleDetailsProfitShareAssets = ({ userBalance }: PeopleDetailsProfitShareAssetsProps) => {
  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'token.name',
          header: 'Name',
          Cell: ({ cell, row }) => {
            const tokenName = cell.getValue<string>()
            return (
              <Stack direction='row' alignItems='center' gap={1}>
                {row.original.token.logo_thumbnail ? (
                  <Avatar
                    type='round'
                    src={row.original.token.logo_thumbnail}
                    size={32}
                    name='Token Logo'
                  />
                ) : (
                  <GenericTokenIcon
                    sx={{ fontSize: 32, verticalAlign: 'middle', color: 'text.primary' }}
                  />
                )}
                <Stack overflow='hidden'>
                  <Typography variant='body2' noWrap={true}>
                    {tokenName}
                  </Typography>
                  <Typography variant='caption' color='text.secondary' noWrap={true}>
                    {row.original.token.abbreviation}
                  </Typography>
                </Stack>
              </Stack>
            )
          },
        },
        {
          accessorKey: 'status',
          header: 'Status',
          Cell: () => {
            return <Chip label='Active' color='success' size='small' />
          },
        },
        {
          accessorKey: 'amount',
          header: 'Balance',
          muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ cell }) => {
            const amount = cell.getValue<number>() || 0
            return <NumericFormat value={amount} displayType='text' thousandSeparator />
          },
        },
        {
          accessorKey: 'token.amount_allocated',
          header: '% of pool',
          muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ row, cell }) => {
            const totalPoolAmount = cell.getValue<number>() || 0
            const amount = row.original.amount || 0
            return (
              <NumericFormat
                value={(amount * 100) / totalPoolAmount}
                displayType='text'
                decimalScale={2}
                suffix='%'
              />
            )
          },
        },
      ] as MRT_ColumnDef<UserTokenBalance>[],
    [],
  )

  return (
    <KeeprTable
      columns={columns}
      data={userBalance}
      enableSorting={false}
      muiTablePaperProps={{
        elevation: 0,
      }}
    />
  )
}

export default PeopleDetailsProfitShareAssets

import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress'

import { Box, Typography } from '@mui/material'

const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
  return (
    <Box data-testid='onboarding'>
      <Box>
        <Typography
          variant='body2'
          color='green.900'
          fontSize='10px'
          data-testid='completion-percentage'
        >
          {`${Math.round(props.value)}% completed`}
        </Typography>
      </Box>

      <LinearProgress
        variant='determinate'
        {...props}
        sx={{
          height: '8px',
          mt: 0.5,
          borderRadius: 0.5,
          backgroundColor: 'divider',
          '& .MuiLinearProgress-bar': { backgroundColor: 'green.900', borderRadius: 0.5 },
        }}
        data-testid='completion-bar'
      />
    </Box>
  )
}
export default LinearProgressWithLabel

import {
  EmploymentType,
  EmploymentTypeDetails,
  Organization,
  OrganizationTheme,
  Tier,
  TierDetails,
  employmentTypeDetailsSchema,
  employmentTypeSchema,
  organizationSchema,
  organizationThemeSchema,
  organizationTierDetailsSchema,
} from 'types/organization'
import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { API_ENDPOINTS as API } from 'services/endpoints'
import { companyTierSchema } from 'types/token/basicToken'
import { validateSchema } from 'utils/zod'

interface UseOrganizationThemeProps<TData> {
  subdomain: string
  options?: Omit<UseQueryOptions<OrganizationTheme, ErrorResponse, TData>, 'queryKey'>
}

export const useOrganizationTheme = <TData = OrganizationTheme>({
  subdomain,
  options,
}: UseOrganizationThemeProps<TData>) => {
  return useQuery<OrganizationTheme, ErrorResponse, TData>({
    queryKey: ['organization-theme', subdomain],
    queryFn: async () => {
      const response = await baseApiClient.get(API.org.theme(subdomain))
      const data = response.data
      return validateSchema(organizationThemeSchema, data, 'useOrganizationTheme')
    },
    ...options,
  })
}

interface UseOrganizationProps<TData> {
  options?: Omit<UseQueryOptions<Organization, ErrorResponse, TData>, 'queryKey'>
}

export const useOrganization = <TData = Organization>({ options }: UseOrganizationProps<TData>) => {
  return useQuery<Organization, ErrorResponse, TData>({
    queryKey: ['organization-details'],
    queryFn: async () => {
      const response = await baseApiClient.get(API.org.details())
      const data = response.data
      return validateSchema(organizationSchema, data, 'useOrganization')
    },
    ...options,
  })
}

interface UseTiersProps<TData> {
  options?: Omit<UseQueryOptions<Tier[], ErrorResponse, TData>, 'queryKey'>
}

export const useTiers = <TData = Tier[]>({ options }: UseTiersProps<TData>) => {
  return useQuery<Tier[], ErrorResponse, TData>({
    queryKey: ['tiers'],
    queryFn: async () => {
      const response = await baseApiClient.get(API.org.tierList())
      const data = response.data
      return validateSchema(companyTierSchema.array(), data, 'useTiers')
    },
    ...options,
  })
}

interface UseTiersDetailsProps<TData> {
  tierId: string | number
  options?: Omit<UseQueryOptions<TierDetails, ErrorResponse, TData>, 'queryKey'>
}

export const useTierDetails = <TData = TierDetails>({
  tierId,
  options,
}: UseTiersDetailsProps<TData>) => {
  return useQuery<TierDetails, ErrorResponse, TData>({
    queryKey: ['tiers', `${tierId}`],
    queryFn: async () => {
      const response = await baseApiClient.get(API.org.tier(Number(tierId)))
      const data = response.data
      return validateSchema(organizationTierDetailsSchema, data, 'useTierDetails')
    },

    ...options,
  })
}

interface UseEmploymentTypesProps<TData> {
  options?: Omit<UseQueryOptions<EmploymentType[], ErrorResponse, TData>, 'queryKey'>
}

export const useEmploymentTypes = <TData = EmploymentType[]>({
  options,
}: UseEmploymentTypesProps<TData>) => {
  return useQuery<EmploymentType[], ErrorResponse, TData>({
    queryKey: ['employment-types'],
    queryFn: async () => {
      const response = await baseApiClient.get(API.org.employmentTypeList())
      const data = response.data
      return validateSchema(employmentTypeSchema.array(), data, 'useEmploymentTypes')
    },
    ...options,
  })
}

interface UseEmploymentTypeDetailsProps<TData> {
  employmentTypeId: string | number
  options?: Omit<UseQueryOptions<EmploymentTypeDetails, ErrorResponse, TData>, 'queryKey'>
}

export const useEmploymentTypeDetails = <TData = EmploymentTypeDetails>({
  employmentTypeId,
  options,
}: UseEmploymentTypeDetailsProps<TData>) => {
  return useQuery<EmploymentTypeDetails, ErrorResponse, TData>({
    queryKey: ['employment-types', `${employmentTypeId}`],
    queryFn: async () => {
      const response = await baseApiClient.get(API.org.employmentType(Number(employmentTypeId)))
      const data = response.data
      return validateSchema(employmentTypeDetailsSchema, data, 'useEmploymentTypeDetails')
    },

    ...options,
  })
}

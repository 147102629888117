import { Link as RouterLink } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoginCredentials, loginCredentialsSchema } from 'types/auth'
import { Typography, TextField, Link } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import PasswordField from 'auth/components/PasswordField'
import { Stack } from '@mui/system'

const FORM_DEFAULT_VALUES: LoginCredentials = {
  email: '',
  password: '',
}

interface LoginFormProps {
  organizationName: string
  isLoginLoading: boolean
  loginError?: string | null
  onSubmit: (credentials: LoginCredentials) => void
  onChangeOrganization: () => void
}

const LoginForm = ({
  organizationName,
  isLoginLoading,
  loginError,
  onSubmit,
  onChangeOrganization,
}: LoginFormProps) => {
  const { handleSubmit, control } = useForm<LoginCredentials>({
    mode: 'onBlur',
    resolver: zodResolver(loginCredentialsSchema),
    defaultValues: FORM_DEFAULT_VALUES,
  })

  return (
    <>
      <Typography variant='h5'>Welcome back!</Typography>
      <Typography variant='body2' color='text.secondary'>
        Sign in to your {organizationName} account
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack gap={2} mt={4}>
          <Controller
            name='email'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                label='Email'
                type='email'
                {...field}
                fullWidth
                error={!!error}
                helperText={error?.message}
                data-testid='enter-email'
              />
            )}
          />

          <Controller
            name='password'
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <PasswordField
                showRequirements={false}
                value={field.value}
                error={error}
                onChange={field.onChange}
                data-testid='enter-password'
              />
            )}
          />

          <div>
            <Link
              variant='button'
              sx={{ textTransform: 'uppercase' }}
              underline='hover'
              component={RouterLink}
              to='/reset-password'
              data-testid='forgot-password'
            >
              Forgot your password?
            </Link>
          </div>

          <LoadingButton
            sx={{ mt: 1.5 }}
            variant='contained'
            type='submit'
            size='large'
            loading={isLoginLoading}
            data-testid='log-in'
          >
            Log in
          </LoadingButton>

          {loginError ? (
            <Typography variant='caption' sx={{ color: 'error.main' }}>
              {loginError}
            </Typography>
          ) : null}
        </Stack>
      </form>

      <Stack mt={3} alignItems='flex-end'>
        <Typography variant='body2' color='text.secondary'>
          Not <b>{organizationName}</b>?{' '}
          <Link
            role='button'
            component='button'
            variant='body2'
            onClick={(e) => {
              e.stopPropagation()
              onChangeOrganization()
            }}
          >
            Change organization
          </Link>
        </Typography>
      </Stack>
    </>
  )
}
export default LoginForm

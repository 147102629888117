import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { API_ENDPOINTS as API } from 'services/endpoints'
import { validateSchema } from 'utils/zod'
import { QueryPagination } from 'types/common'
import { PaginatedTokenHolders, tokenHolderSchema } from 'types/user'

interface UseProfitShareHoldersListProps<TData> {
  tokenId: string
  pagination?: QueryPagination
  searchTerm?: string
  options?: Omit<UseQueryOptions<PaginatedTokenHolders, ErrorResponse, TData>, 'queryKey'>
}
export const useProfitShareHoldersList = <TData = PaginatedTokenHolders>({
  tokenId,
  pagination,
  searchTerm,
  options,
}: UseProfitShareHoldersListProps<TData>) => {
  return useQuery<PaginatedTokenHolders, ErrorResponse, TData>({
    queryKey: ['profit-share-holders', tokenId, pagination?.page, pagination?.pageSize, searchTerm],
    queryFn: async () => {
      const response = await baseApiClient.get(API.token.balance(tokenId), {
        params: {
          page: pagination?.page,
          page_size: pagination?.pageSize,
          q: searchTerm,
        },
      })
      const data = response.data
      return validateSchema(tokenHolderSchema.array(), data, 'useProfitShareHoldersList')
    },
    ...options,
  })
}

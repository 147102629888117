export const getSubdomain = () => {
  const locationParts = window.location.hostname.split('.')

  const isLocalhost = locationParts.slice(-1)[0] === 'localhost'

  let sliceNum = -2
  if (isLocalhost) {
    sliceNum = -1
  }

  return locationParts.slice(0, sliceNum).join('')
}

export const getDomain = () => {
  // here we are using window.location.host because we need port
  const locationParts = window.location.host.split('.')

  const isLocalhost = locationParts.slice(-1)[0].includes('localhost')

  let sliceNum = -2
  if (isLocalhost) {
    sliceNum = -1
  }

  return locationParts.slice(sliceNum).join('.')
}

export const redirectToSubdomain = (subdomain: string) => {
  const domain = getDomain()
  if (domain.includes('localhost')) {
    window.location.href = `http://${subdomain}.${domain}`
    return
  }
  window.location.href = `https://${subdomain}.${domain}`
}

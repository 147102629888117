export const document: { [key: string]: string[] } = {
  'application/pdf': ['.pdf'],
}

export const image: { [key: string]: string[] } = {
  'image/png': ['.png'],
  'image/jpg': ['.jpg'],
  'image/jpeg': ['.jpeg'],
  'image/svg': ['.svg'],
}

import { StockOptionStatus, STOCK_OPTION_STATUS } from 'types/token/stockOption'

export const stockOptionExpireStatuses: StockOptionStatus[] = [
  STOCK_OPTION_STATUS.Pending,
  STOCK_OPTION_STATUS.Vesting,
  STOCK_OPTION_STATUS.Vested,
]

export const stockOptionCompleteStatuses: StockOptionStatus[] = [
  STOCK_OPTION_STATUS.Pending,
  STOCK_OPTION_STATUS.Vesting,
]

export const stockOptionExerciseStatuses: StockOptionStatus[] = [
  STOCK_OPTION_STATUS.Vesting,
  STOCK_OPTION_STATUS.Vested,
]

export const stockOptionDisqualifyStatuses: StockOptionStatus[] = [
  STOCK_OPTION_STATUS.Pending,
  STOCK_OPTION_STATUS.Vesting,
  STOCK_OPTION_STATUS.Vested,
]

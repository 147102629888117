import { ReactElement } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

interface PrivateRoute {
  isAllowed: boolean
  redirectPath?: string
  children?: ReactElement
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PrivateRoute = ({ isAllowed, redirectPath = '/login', children }: PrivateRoute) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />
  }

  return children ? children : <Outlet />
}

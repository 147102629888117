import {
  Chart as ChartJS,
  Filler,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  ChartOptions,
  ChartData,
  ChartDataset,
} from 'chart.js'
import { addMonths, parseISO } from 'date-fns'
import { Line } from 'react-chartjs-2'
import { StockOptionHolderSchedule } from 'types/token/stockOption'
import { formatDate } from 'utils/dates'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Filler)

interface VestingChartOptions {
  grant_date: string
  vesting_period: number
  cliff_period: number | null
  total_options_amount: number
  schedule: StockOptionHolderSchedule[]
}

interface VestingChartProps {
  options: VestingChartOptions
}

const chartOptions = (maxValue: number): ChartOptions<'line'> => ({
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        autoSkip: false,
        callback: (tickValue: number | string, index: number) => {
          if (index === 0) {
            return 'Grant Date'
          }
          return index % 12 === 0 ? `${tickValue} months` : ''
        },
        maxRotation: 0,
        minRotation: 0,
      },
    },
    y: {
      grid: {
        display: false,
      },
      max: maxValue * 1.01,
      ticks: {
        callback: (_, index, ticks) => {
          const percentage = Math.floor((index * 100) / (ticks.length - 1))
          return `${percentage}%`
        },
        maxTicksLimit: 5,
        maxRotation: 0,
        minRotation: 0,
      },
      bounds: 'data',
    },
  },
  plugins: {
    tooltip: {
      filter: (tooltipItem) => tooltipItem.dataset.label === 'Vesting',
    },
  },
})

const getVestingChartDetails = ({
  grant_date,
  vesting_period,
  cliff_period,
  schedule,
}: VestingChartOptions) => {
  const gDate = parseISO(grant_date)
  const vestingStartDate = addMonths(gDate, cliff_period ?? 0)
  const monthsDiff = vesting_period % 12
  const monthCount = vesting_period + (monthsDiff > 0 ? 12 - monthsDiff : 0)

  const monthsArray = new Array(monthCount + 1).fill(null)
  const labels = monthsArray.map((_, index) => (index === 0 ? 'Grant date' : `${index} months`))
  const vestingSeriesPointStyle = monthsArray.map((_, index) =>
    index % 12 === 0 || index === cliff_period || index === vesting_period || index === 0
      ? 'circle'
      : 'line',
  )
  let cumulativeAmount = 0
  const vestingSeries = monthsArray.map((_, index) => {
    const currentDate = addMonths(gDate, index)
    if (currentDate >= vestingStartDate) {
      const scheduleKey = formatDate(currentDate, 'yyyy-MM-dd')
      const vestingAmount = schedule.find((s) => s.date === scheduleKey)?.amount
      if (vestingAmount) {
        cumulativeAmount = cumulativeAmount + vestingAmount
        return cumulativeAmount
      }
    }
    return null
  })

  return {
    labels,
    vestingSeries,
    vestingSeriesPointStyle,
  }
}

const data = (options: VestingChartOptions): ChartData<'line'> => {
  const { cliff_period, schedule } = options
  const { labels, vestingSeries, vestingSeriesPointStyle } = getVestingChartDetails(options)
  const cliffPeriod = cliff_period || 0
  const valueAtCliff = schedule.find((s) => s.amount > 0)?.amount ?? 0

  let datasets: ChartDataset<'line'>[] = []

  if (cliffPeriod > 0) {
    const cliffSeries = new Array(cliffPeriod).fill(0)
    datasets = [
      ...datasets,
      {
        fill: false,
        label: 'cliff',
        data: [...cliffSeries, valueAtCliff + valueAtCliff],
        borderColor: 'rgb(229, 229, 229)',
        stepped: true,
        borderDash: [5, 5],
        order: 4,
        pointStyle: 'line',
      },
      {
        fill: false,
        label: 'vesting blocked',
        data: [...cliffSeries, valueAtCliff],
        borderColor: 'rgb(53, 162, 235)',
        stepped: true,
        order: 3,
        pointStyle: 'line',
      },
    ]
  }

  datasets = [
    ...datasets,
    {
      fill: true,
      label: 'Vesting',
      data: vestingSeries,
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgb(186, 228, 255, .4)',
      order: 1,
      spanGaps: true,
      pointStyle: vestingSeriesPointStyle,
    },
  ]

  return {
    labels,
    datasets,
  }
}

const VestingChart = ({ options }: VestingChartProps) => {
  return <Line data={data(options)} options={chartOptions(options.total_options_amount)} />
}

export default VestingChart

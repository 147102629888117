import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { Workspace, workspaceSchema } from 'types/organization'

import { API_ENDPOINTS as API } from 'services/endpoints'
import { validateSchema } from 'utils/zod'

interface UseWorkspaceListProps<TData> {
  userId: string
  options?: Omit<UseQueryOptions<Workspace[], ErrorResponse, TData>, 'queryKey'>
}

export const useWorkspaceList = <TData = Workspace[]>({
  userId,
  options,
}: UseWorkspaceListProps<TData>) => {
  return useQuery<Workspace[], ErrorResponse, TData>({
    queryKey: ['workspaces', userId],
    queryFn: async () => {
      const response = await baseApiClient.get(API.workspace.list(userId))
      const data = response.data
      return validateSchema(workspaceSchema.array(), data, 'useWorkspaceList')
    },
    ...options,
  })
}

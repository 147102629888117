import { emailRegexExpression, passwordRegexExpression } from 'utils/validations'

import { z } from 'zod'

export const loginCredentialsSchema = z.object({
  email: z.string().regex(emailRegexExpression, {
    message: 'Email is invalid.',
  }),
  password: z.string().min(1, { message: 'Password is required.' }),
})

export type LoginCredentials = z.infer<typeof loginCredentialsSchema>

export const passwordSchema = z.object({
  password: z
    .string()
    .regex(passwordRegexExpression, { message: 'Password does not meet requirements.' }),
})

export type Password = z.infer<typeof passwordSchema>

export const emailSchema = loginCredentialsSchema.pick({ email: true })

export type EmailCredentials = z.infer<typeof emailSchema>

export const authTokensSchema = z.object({
  access: z.string(),
  refresh: z.string(),
})
export type AuthTokens = z.infer<typeof authTokensSchema>

export const loginFormSchema = loginCredentialsSchema.extend({
  subdomain: z.string(),
})
export type LoginFormValues = z.infer<typeof loginFormSchema>

export const changePasswordFormSchema = passwordSchema
  .extend({
    old_password: z.string().min(1, { message: 'Current password is required.' }),
    confirm_password: z.string().min(1, { message: 'Confirm password is required.' }),
  })
  .superRefine(({ old_password, password, confirm_password }, ctx) => {
    if (password !== confirm_password) {
      ctx.addIssue({
        code: 'custom',
        path: ['confirm_password'],
        message: 'The passwords did not match',
      })
    }

    if (password === old_password) {
      ctx.addIssue({
        code: 'custom',
        path: ['password'],
        message: 'Your password cannot be your current password',
      })
    }
  })

export type ChangePasswordFormValues = z.infer<typeof changePasswordFormSchema>

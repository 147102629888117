import { addMonths, format } from 'date-fns'

type DateToConvert = string | Date

export const formatDate = (dateToConvert: DateToConvert, dateFormat = 'MM/dd/yy') => {
  if (!dateToConvert) return ''

  let date = dateToConvert

  if (typeof date === 'string') {
    date = new Date(dateToConvert)
  }

  return format(date, dateFormat)
}

export const convertToIsoDate = (dateToConvert: DateToConvert) => {
  if (!dateToConvert) return ''

  let date = dateToConvert

  if (typeof date === 'string') {
    const parsedDate = Date.parse(date)
    if (isNaN(parsedDate)) return ''
    date = new Date(dateToConvert)
  }

  return date.toISOString()
}

export const addMonthsToDate = (dateToConvert: DateToConvert, monthsToAdd: number) => {
  if (!dateToConvert) return ''

  let date = dateToConvert

  if (typeof date === 'string') {
    date = new Date(dateToConvert)
  }

  return addMonths(date, monthsToAdd)
}

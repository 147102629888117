import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { MAX_DESC_NOTES_LENGTH } from 'constants/common'
import { Controller, useForm } from 'react-hook-form'
import { isOverLimit } from 'utils/numbers'
import { Close as CloseIcon } from '@mui/icons-material'
import { Note } from 'types/note'
import { EXERCISE_MENU_INTENT, ExerciseMenuIntent } from '../../StockOptionExerciseMenu'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { API_ENDPOINTS as API } from 'services/endpoints'
import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar'
import { useOutletContext } from 'react-router-dom'
import { useEffect } from 'react'

type ExerciseOptionsAdminDialogProps = {
  open: boolean
  handleDialogClose: () => void
  exerciseId: number
  intendedAction: ExerciseMenuIntent
  note: Note | null
}

const EXERCISE_MENU_RESULT = {
  Accepted: 'accepted',
  Rejected: 'rejected',
} as const

const exerciseMenuResultSchema = z.enum([
  EXERCISE_MENU_RESULT.Accepted,
  EXERCISE_MENU_RESULT.Rejected,
])

export type ExerciseMenuResult = z.infer<typeof exerciseMenuResultSchema>

export const stockOptionsExerciseFormSchema = z.object({
  title: z.string().min(1).max(50),
  text: z.string().min(1).max(MAX_DESC_NOTES_LENGTH),
})

type StockOptionsExerciseForm = z.infer<typeof stockOptionsExerciseFormSchema>

const DEFAULT_FORM_VALUES: StockOptionsExerciseForm = {
  title: '',
  text: '',
}

const ExerciseOptionsAdminDialog = ({
  open,
  handleDialogClose: onClose,

  exerciseId,
  intendedAction,
  note,
}: ExerciseOptionsAdminDialogProps) => {
  const queryClient = useQueryClient()
  const enqueueSnackbar = useEnqueueSnackbar()

  const tokenId = useOutletContext<string>()

  const intendedActionText = () => {
    switch (intendedAction) {
      case EXERCISE_MENU_INTENT.Approve:
        return 'Approve exercise request'
      case EXERCISE_MENU_INTENT.Reject:
        return 'Reject exercise request'

      case EXERCISE_MENU_INTENT.ViewNote:
        return 'View note'

      default:
        return 'Approve exercise request'
    }
  }

  const intendedActionStatus =
    intendedAction === EXERCISE_MENU_INTENT.Approve
      ? EXERCISE_MENU_RESULT.Accepted
      : EXERCISE_MENU_RESULT.Rejected

  const { mutate: exerciseIntent, isPending: isExerciseIntentPending } = useMutation<
    unknown,
    ErrorResponse,
    StockOptionsExerciseForm & { tokenId: string; exerciseId: number; status: ExerciseMenuResult }
  >({
    mutationFn: (data) =>
      baseApiClient.patch(API.token.exercise.request(tokenId, exerciseId), data),
    onSuccess: () => {
      enqueueSnackbar(`${intendedActionText()} - successful`, { variant: 'success' })
      queryClient.invalidateQueries({ queryKey: ['stock-option-holders'] })
      queryClient.invalidateQueries({ queryKey: ['stock-option-exercise-requests', `${tokenId}`] })
      queryClient.invalidateQueries({ queryKey: ['user-stock-option-exercise-requests'] })
      onClose()
    },
    onError: (error) => {
      if (error.detail) {
        enqueueSnackbar(error.detail, { variant: 'error' })
        return
      }

      enqueueSnackbar('Something went wrong. Please try Again.', { variant: 'error' })
    },
  })

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useForm({
    mode: 'onBlur',
    defaultValues: DEFAULT_FORM_VALUES,
    resolver: zodResolver(stockOptionsExerciseFormSchema),
  })

  const onSubmit = (data: StockOptionsExerciseForm) => {
    exerciseIntent({ ...data, status: intendedActionStatus, tokenId, exerciseId })
  }

  useEffect(() => {
    if (intendedAction === EXERCISE_MENU_INTENT.ViewNote && note) {
      setValue('title', note.title)
      setValue('text', note.text)
    }
  }, [intendedAction, note, setValue])

  return (
    <Dialog onClose={onClose} open={open} fullWidth={true}>
      <DialogTitle>
        {intendedActionText()}
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'text.primary',
            '& svg': {
              fontSize: 24,
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ px: 3, py: 1, '&.MuiDialogContent-root': { pt: 1 } }}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stack gap={2}>
            <Box pb={2}>
              {intendedAction !== EXERCISE_MENU_INTENT.ViewNote ? (
                <Typography variant='body2' color='textSecondary'>
                  Add note to exercise request
                </Typography>
              ) : null}
            </Box>
            <Controller
              name='title'
              control={control}
              render={({ field, fieldState: { error } }) => {
                const charCount = field.value?.length || 0

                const isNoteOverLimit = isOverLimit(charCount, 50)
                return (
                  <TextField
                    label='Title'
                    {...field}
                    fullWidth
                    required
                    disabled={intendedAction === EXERCISE_MENU_INTENT.ViewNote}
                    error={!!error || isNoteOverLimit}
                    helperText={`${charCount}/${50}`}
                    inputProps={{ maxLength: 50 }}
                    FormHelperTextProps={{
                      sx: { textAlign: 'right' },
                    }}
                    data-testid='notes-text-field'
                  />
                )
              }}
            />
            <Controller
              name='text'
              control={control}
              render={({ field, fieldState: { error } }) => {
                const charCount = field.value?.length || 0

                const isNoteOverLimit = isOverLimit(charCount, MAX_DESC_NOTES_LENGTH)
                return (
                  <TextField
                    label='Notes'
                    {...field}
                    multiline={true}
                    rows={4}
                    fullWidth
                    required
                    disabled={intendedAction === EXERCISE_MENU_INTENT.ViewNote}
                    error={!!error || isNoteOverLimit}
                    helperText={`${charCount}/${MAX_DESC_NOTES_LENGTH}`}
                    inputProps={{ maxLength: MAX_DESC_NOTES_LENGTH }}
                    FormHelperTextProps={{
                      sx: { textAlign: 'right' },
                    }}
                    data-testid='notes-text-field'
                  />
                )
              }}
            />

            <Stack direction='row' justifyContent='flex-end' gap={2} py={1}>
              <Button onClick={onClose}>
                {intendedAction !== EXERCISE_MENU_INTENT.ViewNote ? 'cancel' : 'close'}
              </Button>
              {intendedAction !== EXERCISE_MENU_INTENT.ViewNote && (
                <LoadingButton
                  variant='contained'
                  type='submit'
                  loading={isExerciseIntentPending}
                  disabled={!isValid || isExerciseIntentPending}
                  data-testid='exercise-options-admin-dialog-submit-button'
                >
                  {intendedAction === EXERCISE_MENU_INTENT.Approve ? 'Approve' : null}
                  {intendedAction === EXERCISE_MENU_INTENT.Reject ? 'Reject' : null}
                </LoadingButton>
              )}
            </Stack>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default ExerciseOptionsAdminDialog

import { PaginatedResponse, addressSchema, currencyOptionsSchema } from './common'
import { organizationThemeSchema, workspaceSchema } from './organization'

import { emailRegexExpression } from 'utils/validations'
import { tokenSchema } from './token/basicToken'
import { z } from 'zod'

export const USER_ROLE = {
  Admin: 'admin',
  Clerk: 'clerk',
  Member: 'member',
} as const

export const userRoleSchema = z.enum([USER_ROLE.Admin, USER_ROLE.Clerk, USER_ROLE.Member])

export type UserRole = z.infer<typeof userRoleSchema>

export const userJobSchema = z.object({
  start_dt: z.string(),
  end_dt: z.string().nullable(),
  employment_type: z.string(),
  company_tier: z.string(),
  current_multiplier: z.number(),
  is_undefined: z.boolean(),
})

export type UserJob = z.infer<typeof userJobSchema>

export const USER_STATUS = {
  Created: 'created',
  Invited: 'invited',
  Active: 'active',
  Inactive: 'inactive',
} as const

export const userStatusSchema = z.enum([
  USER_STATUS.Created,
  USER_STATUS.Invited,
  USER_STATUS.Active,
  USER_STATUS.Inactive,
])

export type UserStatus = z.infer<typeof userStatusSchema>

export const userTokenBalance = z.object({
  amount: z.number(),
  token: tokenSchema.pick({
    abbreviation: true,
    logo_thumbnail: true,
    name: true,
    uuid: true,
  }),
})

export type UserTokenBalance = z.infer<typeof userTokenBalance>

export const userSchema = z.object({
  avatar: z.string().nullable(),
  avatar_thumbnail: z.string().nullable(),
  email: z.string().regex(emailRegexExpression),
  first_name: z.string(),
  full_name: z.string(),
  is_active: z.boolean(),
  last_name: z.string(),
  log_user_out: z.boolean(),
  preferred_first_name: z.string(),
  multiplier: z.string(),
  role: userRoleSchema.nullable(),
  status: userStatusSchema,
  balances: userTokenBalance.array(),
  use_custom_multiplier: z.boolean(),
  user_job: userJobSchema,
  uuid: z.string(),
  user_address: addressSchema.nullable(),
})

export type User = z.infer<typeof userSchema>

export type PaginatedUserList = PaginatedResponse<User>

export const userProfileSchema = userSchema
  .omit({
    is_active: true,
    log_user_out: true,
    use_custom_multiplier: true,
    multiplier: true,
    balances: true,
    status: true,
  })
  .extend({
    organization: organizationThemeSchema,
    workspaces: z.array(workspaceSchema),
    onboarding_step_completed: z.number().optional().nullable(),
  })

export type UserProfile = z.infer<typeof userProfileSchema>

export const tokenHolderSchema = z.object({
  amount: z.number(),
  user: z.object({
    full_name: z.string(),
    email: z.string().email(),
    status: userStatusSchema,
    avatar_thumbnail: z.string().nullable(),
    user_job: userJobSchema,
  }),
})

export const profitShareHolderForDownloadSchema = z.object({
  amount: z.number(),
  company_tier: z.string(),
  email: z.string(),
  employment_type: z.string(),
  status: userStatusSchema,
})

export type TokenHolder = z.infer<typeof tokenHolderSchema>

export type PaginatedTokenHolders = PaginatedResponse<TokenHolder>

export const userTotalPayoutSchema = z.object({
  name: z.string(),
  logo: z.string().nullable(),
  value: z.number(),
  currency: currencyOptionsSchema,
})

export type UserTotalPayout = z.infer<typeof userTotalPayoutSchema>
export type ProfitShareHolderForDownload = z.infer<typeof profitShareHolderForDownloadSchema>

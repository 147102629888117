import { z } from 'zod'
import { PaginatedResponse } from './common'

export const noteSchema = z.object({
  id: z.number(),
  title: z.string(),
  text: z.string(),
  created_by: z.string(),
  created_at: z.string(),
  modified_by: z.string(),
  modified_at: z.string(),
  is_automatic: z.boolean(),
})

export type Note = z.infer<typeof noteSchema>
export type PaginatedNotes = PaginatedResponse<Note>

export const transactionNoteSchema = noteSchema.omit({
  title: true,
  modified_by: true,
  modified_at: true,
  is_automatic: true,
})

export type TransactionNote = z.infer<typeof transactionNoteSchema>

import { Button, Box, Typography } from '@mui/material'
import EmailIcon from 'assets/email-icon.svg'
import { useMutation } from '@tanstack/react-query'
import { baseApiClient } from 'services/axiosConfig'
import { API_ENDPOINTS as API } from 'services/endpoints'
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar'

interface VerifyYourEmailProps {
  changeRegistrationStep: (step: string) => void
  currentEmail: string
}

const VerifyYourEmail = ({ changeRegistrationStep, currentEmail }: VerifyYourEmailProps) => {
  const enqueueSnackbar = useEnqueueSnackbar()

  const { mutate: resendEmail } = useMutation<unknown, unknown, string>({
    mutationFn: (email) =>
      baseApiClient.post(API.auth.resendEmail(), { email }).then((response) => response.data),
    onSuccess: () => {
      enqueueSnackbar('Email resent successfully!')
    },
    onError: () => {
      enqueueSnackbar('Something went wrong. Please try Again.', { variant: 'error' })
    },
  })

  const handleTryAnotherEmail = () => {
    changeRegistrationStep('get-started')
  }

  return (
    <div data-testid='verify-your-email'>
      <Typography variant='h5' sx={{ pt: 2, pl: 2 }}>
        Verify your email
      </Typography>
      <Typography variant='body2' color='text.secondary' sx={{ pl: 2, pb: 0 }}>
        We've sent an email link to:
      </Typography>
      <Typography
        variant='body2'
        color='text.secondary'
        sx={{ pl: 2, pb: 2, fontWeight: 'bold' }}
        data-testid='user-email'
      >
        {currentEmail || 'No previous email'}
      </Typography>
      <Box width='82px' mb={1} pl={2}>
        <img src={EmailIcon} alt='email' style={{ width: '100%' }} />
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-evenly'
        alignItems='flex-start'
        flex={1}
        pl={1}
        height='96px'
      >
        <Button variant='text' onClick={() => resendEmail(currentEmail)} data-testid='resend-email'>
          Resend email
        </Button>
        <Button
          variant='text'
          onClick={handleTryAnotherEmail}
          sx={{ mb: 1 }}
          data-testid='try-another-email'
        >
          Try another email
        </Button>
      </Box>
    </div>
  )
}

export default VerifyYourEmail

import React from 'react'
import {
  OptionsObject as NotistackOptionsObject,
  SnackbarMessage,
  useSnackbar,
  SnackbarContent,
} from 'notistack'
import { Alert } from '@mui/material'

interface OptionsObject extends NotistackOptionsObject {
  persistent?: boolean
}

const useEnqueueSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const enqueueCustomSnackbar = React.useCallback(
    (message: SnackbarMessage, options?: OptionsObject) => {
      const severity = options?.variant === 'default' ? 'success' : options?.variant

      return enqueueSnackbar(message, {
        content: (key) => {
          return (
            <SnackbarContent>
              <Alert
                severity={severity}
                onClose={() => closeSnackbar(key)}
                sx={{ width: '100%', whiteSpace: 'pre-line' }}
              >
                {message}
              </Alert>
            </SnackbarContent>
          )
        },
        ...options,
      })
    },
    [closeSnackbar, enqueueSnackbar],
  )

  return enqueueCustomSnackbar
}

export default useEnqueueSnackbar

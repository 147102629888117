import { SvgIcon } from '@mui/material'
import { SvgIconProps } from '@mui/material'

const GrantOptionsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox='0 0 78 78'>
      <path
        d='M55.25 3.25H16.25C12.675 3.25 9.75 6.175 9.75 9.75V55.25H16.25V9.75H55.25V3.25ZM65 16.25H29.25C25.675 16.25 22.75 19.175 22.75 22.75V68.25C22.75 71.825 25.675 74.75 29.25 74.75H65C68.575 74.75 71.5 71.825 71.5 68.25V22.75C71.5 19.175 68.575 16.25 65 16.25ZM65 68.25H29.25V22.75H65V68.25Z'
        fill='currentColor'
      />
      <path
        d='M34.463 67.1056L43.4005 58.1533L49.3588 64.1116L62.0203 49.8712L59.92 47.7709L49.3588 59.6428L43.4005 53.6845L32.2286 64.8713L34.463 67.1056Z'
        fill='currentColor'
      />
      <path d='M59.3125 37.4997H34.9375V44.2497H59.3125V37.4997Z' fill='currentColor' />
      <path d='M59.3125 26H34.9375V32.75H59.3125V26Z' fill='currentColor' />
    </SvgIcon>
  )
}

export default GrantOptionsIcon

import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { ErrorResponse } from 'services/axiosConfig'
import useEnqueueSnackbar from './useEnqueueSnackbar'

const useErrorHandling = () => {
  const navigate = useNavigate()
  const enqueueSnackbar = useEnqueueSnackbar()

  const handleError = useCallback(
    (error: ErrorResponse) => {
      if (error.statusCode === 404) {
        navigate('/error')
      } else if (error.detail) {
        enqueueSnackbar(error.detail, {
          variant: 'error',
        })
      } else if (error.message) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        })
      } else {
        enqueueSnackbar('Something went wrong.', {
          variant: 'error',
        })
      }
    },
    [navigate, enqueueSnackbar],
  )

  return handleError
}

export default useErrorHandling

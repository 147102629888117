import { devtools, persist } from 'zustand/middleware'

import { UserProfile } from 'types/user'
import { create } from 'zustand'

export interface UserProfileStore extends Omit<UserProfile, 'organization' | 'workspaces'> {
  redirectUrl?: string
  actions: {
    setProfile: (profile: Omit<UserProfile, 'organization' | 'workspaces'>) => void
    setOnboardingStepCompleted: (step: number | null) => void
    setRedirectUrl: (redirectUrl?: string) => void
    clearProfile: () => void
  }
}

const useUserProfileStore = create<UserProfileStore>()(
  devtools(
    persist(
      (set) => ({
        uuid: '',
        email: '',
        first_name: '',
        preferred_first_name: '',
        last_name: '',
        full_name: '',
        role: null,
        avatar: null,
        avatar_thumbnail: null,
        user_address: null,
        user_job: {
          start_dt: '',
          end_dt: null,
          employment_type: '',
          company_tier: '',
          current_multiplier: 0,

          is_undefined: false,
        },
        onboarding_step_completed: null,
        redirectUrl: '',
        actions: {
          setProfile: (profile) => set(() => profile),
          setOnboardingStepCompleted: (step) =>
            set(() => ({
              onboarding_step_completed: step,
            })),
          setRedirectUrl: (redirectUrl) => set(() => ({ redirectUrl })),
          clearProfile: () => set(() => ({ uuid: undefined, redirectUrl: undefined })),
        },
      }),
      {
        name: 'profile-store',
        partialize: ({ uuid, redirectUrl }) => ({ uuid, redirectUrl }),
      },
    ),
  ),
)

export const useProfile = () => useUserProfileStore((state) => state)
export const useProfileFullName = () => useUserProfileStore((state) => state.full_name)
export const useProfileFirstName = () => useUserProfileStore((state) => state.first_name)
export const useProfileLastName = () => useUserProfileStore((state) => state.last_name)
export const useProfileEmail = () => useUserProfileStore((state) => state.email)
export const useOnboardingStepCompleted = () =>
  useUserProfileStore((state) => state.onboarding_step_completed)
export const useProfileRole = () => useUserProfileStore((state) => state.role)
export const useProfileUUID = () => useUserProfileStore((state) => state.uuid)
export const useProfileAvatar = () => useUserProfileStore((state) => state.avatar)
export const useProfileAvatarThumbnail = () =>
  useUserProfileStore((state) => state.avatar_thumbnail)
export const useProfileUserJob = () => useUserProfileStore((state) => state.user_job)

export const useUserProfileActions = () => useUserProfileStore((state) => state.actions)
export const useRedirectUrl = () => useUserProfileStore((state) => state.redirectUrl)

import { Button, Stack, Typography, Box, CircularProgress } from '@mui/material'
import Notes from 'assets/notes.svg'
import { NoteAddOutlined as NoteAddOutlineIcon } from '@mui/icons-material'
import NoteDialog from './dialogs/NoteDialog'
import { useEffect, useMemo, useState } from 'react'
import { Note, noteSchema } from 'types/note'
import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import { useMutation, useQueryClient, useInfiniteQuery } from '@tanstack/react-query'
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar'
import { API_ENDPOINTS as API } from 'services/endpoints'
import { formatDate } from 'utils/dates'
import { useInView } from 'react-intersection-observer'
import { validateSchema } from 'utils/zod'
import { useTheme } from '@mui/material/styles'

const PAGE_SIZE = 5

interface StockOptionHolderNotesProps {
  holderIsoId: number
}

const StockOptionHolderNotes = ({ holderIsoId }: StockOptionHolderNotesProps) => {
  const queryClient = useQueryClient()
  const enqueueSnackbar = useEnqueueSnackbar()
  const { ref, inView } = useInView()

  const [isNoteDialogOpen, setIsNoteDialogOpen] = useState<boolean>(false)
  const [selectedNote, setSelectedNote] = useState<Note | null>(null)
  const [hoveredNote, setHoveredNote] = useState<number | null>(null)

  const {
    palette: { mode: theme },
  } = useTheme()

  const {
    data,
    isLoading: areNotesLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['stock-option-holder-notes', holderIsoId],
    queryFn: async ({ pageParam }) => {
      const response = await baseApiClient.get(API.token.stockOptionHoldersNotes(holderIsoId), {
        params: {
          page: pageParam,
          page_size: PAGE_SIZE,
        },
      })
      const data = response.data
      return validateSchema(noteSchema.array(), data, 'useStockOptionHolderNotes')
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      const maxPages = Math.ceil(lastPage.count / PAGE_SIZE)
      const nextPage = allPages.length + 1
      return nextPage <= maxPages ? nextPage : undefined
    },
  })

  const { mutate: saveNote, isPending: isSaveNotePending } = useMutation<
    Note,
    ErrorResponse,
    Partial<Note>
  >({
    mutationFn: (note) => {
      if (selectedNote) {
        return baseApiClient
          .patch(API.token.stockOptionHoldersNote(holderIsoId, selectedNote.id), note)
          .then((response) => response.data)
      }

      return baseApiClient
        .post(API.token.stockOptionHoldersNotes(holderIsoId), note)
        .then((response) => response.data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['stock-option-holder-notes', holderIsoId] })

      if (selectedNote) {
        enqueueSnackbar('Note updated successfully.', { variant: 'success' })
      } else {
        enqueueSnackbar('Note added successfully.', { variant: 'success' })
      }

      closeNoteDialog()
    },
    onError: (error) => {
      if (error.detail) {
        enqueueSnackbar(error.detail, { variant: 'error' })
        return
      }
      enqueueSnackbar('Something went wrong. Please try Again.', { variant: 'error' })
    },
  })

  const { mutate: removeNote, isPending: isRemoveNotePending } = useMutation<
    number,
    ErrorResponse,
    number
  >({
    mutationFn: (noteId) =>
      baseApiClient
        .delete(API.token.stockOptionHoldersNote(holderIsoId, noteId))
        .then((response) => response.data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['stock-option-holder-notes', holderIsoId] })

      enqueueSnackbar('Note removed successfully', { variant: 'success' })

      closeNoteDialog()
    },
    onError: (error) => {
      if (error.detail) {
        enqueueSnackbar(error.detail, { variant: 'error' })
        return
      }
      enqueueSnackbar('Something went wrong. Please try Again.', { variant: 'error' })
    },
  })

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage()
    }
  }, [inView, hasNextPage, fetchNextPage])

  const openNoteDialog = (note: Note) => {
    setSelectedNote(note)
    setIsNoteDialogOpen(true)
  }

  const closeNoteDialog = () => {
    setSelectedNote(null)
    setIsNoteDialogOpen(false)
  }

  const noNotes = useMemo(() => {
    return (
      <Stack maxWidth='400px' width='100%' gap={1} alignItems='center' paddingY={6}>
        <Stack
          width={106}
          height={106}
          borderRadius='50%'
          sx={{ backgroundColor: 'grey.100' }}
          alignItems='center'
          justifyContent='center'
        >
          <img src={Notes} alt='No notes' style={{ display: 'block', width: 60, height: 'auto' }} />
        </Stack>
        <Typography variant='subtitle1'>No notes on this user yet</Typography>
        <Button startIcon={<NoteAddOutlineIcon />} onClick={() => setIsNoteDialogOpen(true)}>
          <Typography variant='buttonSmall'>Add note</Typography>
        </Button>
      </Stack>
    )
  }, [])

  if (areNotesLoading) {
    return (
      <Stack
        height='280px'
        maxWidth='400px'
        width='100%'
        alignItems='center'
        justifyContent='center'
      >
        <CircularProgress size={30} disableShrink />
      </Stack>
    )
  }

  return (
    <>
      <Box height='280px'>
        {data?.pages.length && data.pages[0].count > 0 ? (
          <Stack height='100%' gap={1} ml={-1}>
            <Box height='220px' overflow='auto' pr={1}>
              {data.pages.map((page) =>
                page.results.map((note: Note, index: number, notes: Note[]) => {
                  const isNoteHovered = hoveredNote === note.id
                  return (
                    <Box
                      key={note.id}
                      ref={notes.length == index + 1 ? ref : null}
                      onClick={() => openNoteDialog(note)}
                      p={1}
                      sx={{
                        cursor: 'pointer',
                        backgroundColor: isNoteHovered
                          ? theme === 'dark'
                            ? 'grey.800'
                            : 'grey.100'
                          : 'inherit',
                      }}
                      onMouseEnter={() => setHoveredNote(note.id)}
                      onMouseLeave={() => setHoveredNote(null)}
                    >
                      <Typography mb={0.5}>{note.title}</Typography>
                      <Stack direction='row' gap={1}>
                        <Typography variant='caption' color='text.secondary' whiteSpace='nowrap'>
                          {formatDate(
                            note.modified_by ? note.modified_at : note.created_at,
                            'MMM dd, yyyy',
                          )}
                        </Typography>

                        <Typography variant='body2' noWrap={true}>
                          {note.text}
                        </Typography>
                      </Stack>
                    </Box>
                  )
                }),
              )}

              {isFetchingNextPage && (
                <Box mt={1.5} ml={1.5}>
                  <CircularProgress size={24} disableShrink />
                </Box>
              )}
            </Box>

            <Button
              sx={{ mt: 'auto', mr: 'auto' }}
              startIcon={<NoteAddOutlineIcon />}
              onClick={() => setIsNoteDialogOpen(true)}
            >
              <Typography variant='buttonSmall'>Add note</Typography>
            </Button>
          </Stack>
        ) : (
          noNotes
        )}
      </Box>

      {isNoteDialogOpen ? (
        <NoteDialog
          open={true}
          handleClose={closeNoteDialog}
          note={selectedNote}
          isLoading={isSaveNotePending || isRemoveNotePending}
          saveNote={saveNote}
          removeNote={removeNote}
        />
      ) : null}
    </>
  )
}

export default StockOptionHolderNotes

import { Box, Paper, Stack, Typography, Divider, Link } from '@mui/material'

import Avatar from 'components/Avatar'
import KeeprFooterLogo from 'assets/keepr-footer-logo.svg'
import KeeprLogo from 'assets/keepr-logo.svg'
import { OrganizationTheme } from 'types/organization'

interface AuthFormLayoutProps {
  organization?: OrganizationTheme
  isDefaultSubdomain?: boolean
  children?: React.ReactNode
}

const AuthFormLayout = ({ organization, isDefaultSubdomain, children }: AuthFormLayoutProps) => {
  const renderLogo = () => {
    if (!isDefaultSubdomain) {
      return (
        <Avatar
          name={organization?.name || ''}
          src={organization?.logo_thumbnail || ''}
          size={80}
          sx={{
            fontSize: 20,
            fontWeight: 500,
          }}
        />
      )
    }

    return (
      <img
        src={KeeprLogo}
        alt='Keepr logo'
        style={{ display: 'block', height: 60, width: 'auto' }}
      />
    )
  }

  return (
    <>
      <Box mb={3}>{renderLogo()}</Box>
      <Paper
        sx={{
          width: '564px',
          display: 'flex',
          flexDirection: 'column',
          p: 2,
        }}
        elevation={1}
      >
        {children}
      </Paper>
      <Stack direction='row' mt={3} justifyContent='space-between'>
        <Stack
          direction='row'
          gap={0.5}
          divider={
            <Divider
              sx={{ height: '12px', alignSelf: 'center', borderColor: 'text.secondary' }}
              orientation='vertical'
              flexItem
            />
          }
        >
          <Link
            variant='caption'
            href='https://app.termly.io/document/privacy-policy/335b86fa-8d9b-4bb5-b972-dd1f42e0c993'
            target='_blank'
            rel='noopener'
            color='text.secondary'
            underline='hover'
          >
            Privacy Policy
          </Link>

          <Link
            variant='caption'
            href='https://app.termly.io/document/cookie-policy/0a73cf7a-56ce-4aef-b4b3-713500747606'
            target='_blank'
            rel='noopener'
            color='text.secondary'
            underline='hover'
          >
            Cookie Policy
          </Link>
        </Stack>

        {!isDefaultSubdomain ? (
          <Stack direction='row' alignItems='center' gap={1}>
            <Typography variant='caption' color='grey.500'>
              Powered by
            </Typography>
            <img
              src={KeeprFooterLogo}
              alt='Keepr footer logo'
              style={{ width: '70px', height: 'auto' }}
            />
          </Stack>
        ) : null}
      </Stack>
    </>
  )
}
export default AuthFormLayout

import { ChevronRight } from '@mui/icons-material'
import { Box, Button, IconButton, Stack, Typography } from '@mui/material'
import { ActiveDialog, ActiveDialogType } from './UserSettings'

interface AccountProps {
  handleClose: () => void
  onChangeDialogContent: (content: ActiveDialogType) => void
}

const Account = ({ handleClose, onChangeDialogContent }: AccountProps) => {
  return (
    <Stack gap={2}>
      <Typography color='text.secondary'>Security</Typography>

      <Box paddingLeft={1} display='flex' flexDirection='row' justifyContent='space-between'>
        <Typography color='text.primary'>Change password</Typography>
        <IconButton
          size='small'
          sx={{ color: 'text.secondary' }}
          onClick={() => onChangeDialogContent(ActiveDialog.ChangePassword)}
          data-testid='change-password'
        >
          <ChevronRight titleAccess='open change password' />
        </IconButton>
      </Box>
      <Stack direction='row' gap={2} justifyContent='flex-end' py={1}>
        <Button onClick={handleClose} data-testid='cancel-changes'>
          Cancel
        </Button>
      </Stack>
    </Stack>
  )
}

export default Account

import { useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { z } from 'zod'
import { Stack, Typography, Checkbox, FormControlLabel, Link } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { passwordSchema } from 'types/auth'
import AuthLayout from 'auth/components/AuthLayout'
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar'
import PasswordField from 'auth/components/PasswordField'
import { validateUUID } from 'utils/validations'
import { baseApiClient, ErrorResponse } from 'services/axiosConfig'
import { API_ENDPOINTS as API } from 'services/endpoints'
import { LoadingButton } from '@mui/lab'

export interface SetPasswordWithOTPProps {
  isResetPassword?: boolean
}

const setPasswordFormSchema = passwordSchema
  .extend({
    isResetPassword: z.boolean(),
    terms_accepted: z.boolean(),
  })
  .superRefine(({ isResetPassword, terms_accepted }, ctx) => {
    if (!isResetPassword && !terms_accepted) {
      ctx.addIssue({
        code: 'custom',
        path: ['accept_terms'],
        message: 'You must agree with the terms of service',
      })
    }
  })

const setPasswordSchema = passwordSchema.extend({
  pin: z.string(),
  terms_accepted: z.boolean().optional(),
})

type SetPasswordForm = z.infer<typeof setPasswordFormSchema>
type SetPassword = z.infer<typeof setPasswordSchema>

const FORM_DEFAULT_VALUES: SetPasswordForm = {
  password: '',
  isResetPassword: false,
  terms_accepted: false,
}

const SetPasswordWithOTP = ({ isResetPassword = false }: SetPasswordWithOTPProps) => {
  const { otp } = useParams()
  const navigate = useNavigate()
  const enqueueSnackbar = useEnqueueSnackbar()

  const [isPasswordSet, setIsPasswordSet] = useState(false)
  const [isOtpExpired, setIsOtpExpired] = useState(false)

  useEffect(() => {
    const isOtpValid = validateUUID(otp || '')
    if (!isOtpValid) {
      navigate('/error')
    }
  }, [otp, navigate])

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<SetPasswordForm>({
    mode: 'onBlur',
    resolver: zodResolver(setPasswordFormSchema),
    defaultValues: { ...FORM_DEFAULT_VALUES, isResetPassword },
  })

  const { mutate: setPassword, isPending: isSetPasswordPending } = useMutation<
    unknown,
    ErrorResponse & { password: string },
    SetPassword
  >({
    mutationFn: ({ terms_accepted, ...rest }) => {
      if (isResetPassword) {
        return baseApiClient
          .post(API.auth.passwordConfirm(), rest)
          .then((response) => response.data)
      }
      return baseApiClient
        .post(API.auth.passwordSet(), { ...rest, terms_accepted })
        .then((response) => response.data)
    },
    onSuccess: () => {
      setIsPasswordSet(true)
      isResetPassword
        ? enqueueSnackbar('Password reset successfully.', { variant: 'success' })
        : enqueueSnackbar('Password set successfully.', { variant: 'success' })
    },
    onError: (error) => {
      if (error.password) {
        enqueueSnackbar(error.password, { variant: 'error' })
        return
      }

      if (error.detail) {
        enqueueSnackbar(error.detail, { variant: 'error' })
        setIsOtpExpired(true)
        return
      }

      enqueueSnackbar('Something went wrong. Please try Again.', { variant: 'error' })
    },
  })

  if (isOtpExpired) {
    return <Navigate to='/verification-email' />
  }

  const onSubmit = (data: SetPasswordForm) => {
    setPassword({ password: data.password, pin: otp || '', terms_accepted: data.terms_accepted })
  }

  if (isPasswordSet) {
    return <Navigate to='/' />
  }

  return (
    <AuthLayout>
      <Typography variant='h5'>Set a password</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={3.5} mt={4}>
          <div>
            <Controller
              name='password'
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <PasswordField value={field.value} error={error} onChange={field.onChange} />
              )}
            />
            {!isResetPassword && (
              <FormControlLabel
                control={
                  <Controller
                    name='terms_accepted'
                    control={control}
                    render={({ field: props }) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
                label={
                  <Typography variant='body2' color='text.secondary'>
                    I agree to Keepr{' '}
                    <Link
                      variant='body2'
                      href='https://app.termly.io/document/terms-of-service/d25d19a9-1fe2-459b-8b1a-89e75eeecf56'
                      target='_blank'
                      rel='noopener'
                      color='primary.main'
                      underline='hover'
                    >
                      Terms of Service
                    </Link>
                  </Typography>
                }
                data-testid='toc-checkbox'
              />
            )}
          </div>
          <LoadingButton
            variant='contained'
            size='large'
            type='submit'
            disabled={!isValid}
            loading={isSetPasswordPending}
            data-testid='set-password-button'
          >
            Set password
          </LoadingButton>
        </Stack>
      </form>
    </AuthLayout>
  )
}

export default SetPasswordWithOTP

import { transactionNoteSchema } from 'types/note'
import { PaginatedResponse } from '../common'
import { tokenDistributionScheduleSchema } from './basicToken'
import { tokenTypeOptionsSchema, tokenSchema } from './basicToken'
import { z } from 'zod'

export const tokenDistributionInfoSchema = z.object({
  distribution_schedule: tokenDistributionScheduleSchema,
  recipient_tiers_count: z.number().positive(),
  total_dist_amount: z.number().positive(),
  token_tiers: z
    .object({
      company_tier: z.string(),
      value: z.number(),
    })
    .array(),
})

export const DISTRIBUTION_PROCESS_TYPES = {
  Automatic: 'automatic',
  Bulk: 'bulk',
  Manual: 'manual',
  Reverse: 'reverse',
} as const

export const processTypeSchema = z.enum([
  DISTRIBUTION_PROCESS_TYPES.Automatic,
  DISTRIBUTION_PROCESS_TYPES.Bulk,
  DISTRIBUTION_PROCESS_TYPES.Manual,
  DISTRIBUTION_PROCESS_TYPES.Reverse,
])

export const DISTRIBUTION_TRANSFER_TYPES = {
  Credit: 'credit',
  Debit: 'debit',
} as const

export const distributionTransferTypeSchema = z.enum([
  DISTRIBUTION_TRANSFER_TYPES.Credit,
  DISTRIBUTION_TRANSFER_TYPES.Debit,
])

export const tokenDistributionSchema = z.object({
  id: z.number().positive(),
  distribution_amount: z.number(),
  created_at: z.string(),
  payees_count: z.number(),
  token_type: tokenTypeOptionsSchema,
  process_type: processTypeSchema,
})

export const distributionTransactionSchema = z.object({
  amount: z.number(),
  created_at: z.string(),
  transaction_type: distributionTransferTypeSchema,
  user: z.string(),
  txn_date: z.string(),
  notes: z.array(transactionNoteSchema),
})

export const distributionDetailsDownloadSchema = z.object({
  id: z.number().positive(),
  process_type: processTypeSchema,
  token: tokenSchema.pick({
    uuid: true,
    name: true,
    description: true,
    token_type: true,
    logo_thumbnail: true,
    abbreviation: true,
    created_at: true,
  }),
  created_at: z.string(),
  transactions: distributionTransactionSchema.array(),
})

export type TokenDistributionInfo = z.infer<typeof tokenDistributionInfoSchema>

export type TokenDistributionTypes = z.infer<typeof tokenDistributionScheduleSchema>

export type TokenDistribution = z.infer<typeof tokenDistributionSchema>

export type PaginatedTokenDistributions = PaginatedResponse<TokenDistribution>

export type DistributionProcessTypes = z.infer<typeof processTypeSchema>

export type DistributionTransaction = z.infer<typeof distributionTransactionSchema>

export type PaginatedTransactionList = PaginatedResponse<DistributionTransaction>

export type DistributionDetailsDownload = z.infer<typeof distributionDetailsDownloadSchema>

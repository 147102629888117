import { Box, CircularProgress, Stack } from '@mui/material'
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import { API_ENDPOINTS as API } from 'services/endpoints'
import { enqueueSnackbar } from 'notistack'

const testKey =
  'pk_test_51KjlGFGeKwLvp9idetY9eXsAWuS66ahHPggK7HHlyNme4y4qmAMO0qlMOfbwar9DUFoEJGyVWtJ1fawUJ4rWwFvJ00sCqUKrgR'

const stripePromise = loadStripe(testKey)

interface EmbeddedStripeFormProps {
  hasSubscribed: boolean
}

const EmbeddedStripeForm = ({ hasSubscribed }: EmbeddedStripeFormProps) => {
  const [clientSecret, setClientSecret] = useState<string | null>(null)

  const { mutate: customerSubscribe, isPending: isCustomerSubscribePending } = useMutation<
    { client_secret: string },
    ErrorResponse
  >({
    mutationFn: () =>
      baseApiClient
        .post(API.stripe.subscribe(), {
          value: 'ui',
        })
        .then((response) => response.data),
    onSuccess: ({ client_secret }) => {
      setClientSecret(client_secret)
    },

    onError: (error) => {
      if (error.detail) {
        enqueueSnackbar(error.detail, { variant: 'error' })
        return
      }
    },
  })

  useEffect(() => {
    if (hasSubscribed) {
      return
    }

    customerSubscribe()
  }, [customerSubscribe, hasSubscribed])

  return (
    <Stack gap={2}>
      {isCustomerSubscribePending ? (
        <CircularProgress size={30} disableShrink />
      ) : (
        <Box key={clientSecret}>
          <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        </Box>
      )}
    </Stack>
  )
}

export default EmbeddedStripeForm

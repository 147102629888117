import { Grid, Stack, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { addMonths, differenceInMonths, parseISO } from 'date-fns'
import {
  StockOptionDistributionFrequency,
  StockOptionHolderSchedule,
} from 'types/token/stockOption'
import { formatDate } from 'utils/dates'

interface VestingScheduleProps {
  grantDate: string
  totalOptionsAmount: number
  vestingPeriod: number
  cliffPeriod: number | null
  distribution_frequency: StockOptionDistributionFrequency
  schedule: StockOptionHolderSchedule[]
}

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const getFullSchedule = ({ grantDate, vestingPeriod, schedule }: VestingScheduleProps) => {
  const gDate = parseISO(grantDate)

  const scheduleStart = new Date(gDate.getFullYear(), 0, gDate.getDate())
  const vestingEnd = addMonths(gDate, vestingPeriod)
  const scheduleEnd = new Date(vestingEnd.getFullYear(), 11, gDate.getDate())

  const monthsDiff = differenceInMonths(scheduleEnd, scheduleStart)
  const grantYear = gDate.getFullYear()

  return new Array(monthsDiff + 1).fill(null).reduce((acc, _, index) => {
    const year = grantYear + Math.floor(index / 12)
    let amount = -1
    const currentDate = addMonths(scheduleStart, scheduleStart.getMonth() + index)

    if (currentDate >= gDate && currentDate <= vestingEnd) {
      const scheduleKey = formatDate(currentDate, 'yyyy-MM-dd')
      amount = schedule.find((s) => s.date === scheduleKey)?.amount ?? 0
    }
    acc[year] = [...(acc[year] || []), amount]
    return acc
  }, {}) as Record<number, number[]>
}

const VestingSchedule = (props: VestingScheduleProps) => {
  const fullSchedule = getFullSchedule(props)

  return (
    <Stack direction='row'>
      <Stack gap={1}>
        <Stack sx={{ padding: '6px 16px' }}>&nbsp;</Stack>
        {Object.keys(fullSchedule).map((year) => (
          <Stack key={year} sx={{ padding: '6px 16px' }}>
            {year}
          </Stack>
        ))}
      </Stack>
      <Stack gap={1} sx={{ overflow: 'auto' }}>
        <Grid container direction='row' wrap='nowrap'>
          {months.map((month) => (
            <Grid item key={month} sx={{ minWidth: '70px', padding: '6px 16px' }}>
              {month}
            </Grid>
          ))}
        </Grid>
        {Object.keys(fullSchedule).map((year) => (
          <Grid container key={year} direction='row' wrap='nowrap'>
            {fullSchedule[Number(year)].map((month, i) => (
              <Grid
                item
                key={`${year}_${i}`}
                sx={{
                  minWidth: '70px',
                  padding: '6px 16px',
                  borderBottom: `1px solid ${grey[300]}`,
                }}
              >
                {month <= 0 ? (
                  <Typography variant='body2' color='text.secondary'>
                    N/A
                  </Typography>
                ) : (
                  month
                )}
              </Grid>
            ))}
          </Grid>
        ))}
      </Stack>
    </Stack>
  )
}

export default VestingSchedule

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { useForm, Controller } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { StockOptionHolder } from 'types/token/stockOption'
import { API_ENDPOINTS as API } from 'services/endpoints'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar'
import { MAX_DESC_NOTES_LENGTH } from 'constants/common'
import { isOverLimit } from 'utils/numbers'

interface DisqualifyStockOptionDialogProps {
  open: boolean
  handleDialogClose: () => void
  holder: StockOptionHolder
}

export const stockOptionsDisqualifyFormSchema = z.object({
  text: z.string().optional(),
})

type StockOptionsDisqualifyForm = z.infer<typeof stockOptionsDisqualifyFormSchema>

const DEFAULT_FORM_VALUES: StockOptionsDisqualifyForm = {
  text: '',
}

const DisqualifyStockOptionDialog = ({
  open,
  handleDialogClose: onClose,
  holder,
}: DisqualifyStockOptionDialogProps) => {
  const queryClient = useQueryClient()
  const enqueueSnackbar = useEnqueueSnackbar()

  const { id: user_iso, user_full_name: fullName } = holder

  const { mutate: disqualifyStockOption, isPending: isDisqualifyStockOptionPending } = useMutation<
    unknown,
    ErrorResponse,
    StockOptionsDisqualifyForm & { user_iso: number }
  >({
    mutationFn: (data) => baseApiClient.post(API.token.stockOptionDisqualify(), data),
    onSuccess: () => {
      enqueueSnackbar('Stock option disqualified successfully.', { variant: 'success' })
      queryClient.invalidateQueries({ queryKey: ['stock-option-holders'] })
      onClose()
    },
    onError: (error) => {
      if (error.detail) {
        enqueueSnackbar(error.detail, { variant: 'error' })
        return
      }

      enqueueSnackbar('Something went wrong. Please try Again.', { variant: 'error' })
    },
  })

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'onBlur',
    defaultValues: DEFAULT_FORM_VALUES,
    resolver: zodResolver(stockOptionsDisqualifyFormSchema),
  })

  const onSubmit = (data: StockOptionsDisqualifyForm) => {
    disqualifyStockOption({ ...data, user_iso })
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth={true}>
      <DialogTitle>
        Disqualify Contract
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'text.primary',
            '& svg': {
              fontSize: 24,
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ px: 3, py: 1, '&.MuiDialogContent-root': { pt: 1 } }}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stack gap={2}>
            <Typography variant='body2'>
              Enter the reason for disqualifying the options for <strong>{fullName}</strong>
            </Typography>

            <Controller
              name='text'
              control={control}
              render={({ field, fieldState: { error } }) => {
                const charCount = field.value?.length || 0
                // const isOverLimit = charCount >= NOTES_CHAR_LIMIT
                const isDisqualifyNotesOVerLimit = isOverLimit(charCount, MAX_DESC_NOTES_LENGTH)
                return (
                  <TextField
                    label='Additional Notes (Optional)'
                    {...field}
                    multiline={true}
                    rows={4}
                    fullWidth
                    error={!!error || isDisqualifyNotesOVerLimit}
                    helperText={`${charCount}/${MAX_DESC_NOTES_LENGTH}`}
                    inputProps={{ maxLength: MAX_DESC_NOTES_LENGTH }}
                    FormHelperTextProps={{
                      sx: { textAlign: 'right' },
                    }}
                    data-testid='notes-text-field'
                  />
                )
              }}
            />

            <Stack direction='row' justifyContent='flex-end' gap={2} py={1}>
              <Button onClick={onClose}>Cancel</Button>
              <LoadingButton
                variant='contained'
                type='submit'
                disabled={!isValid || isDisqualifyStockOptionPending}
                data-testid='Disqualify-grant'
              >
                Disqualify
              </LoadingButton>
            </Stack>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default DisqualifyStockOptionDialog

import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import CloseIcon from '@mui/icons-material/Close'
import { useTokenDistributionDetailsDownload as useTokenDistributionDetailsDownload } from 'services/api/tokenDistribution'
import { formatDate } from 'utils/dates'
import KeeprTable from 'components/KeeprTable'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { MRT_ColumnDef } from 'material-react-table'
import { NumericFormat } from 'react-number-format'
import { LoadingButton } from '@mui/lab'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { convertDataToCSV } from 'utils/csv'
import { downloadFile } from 'utils/file'
import {
  DISTRIBUTION_PROCESS_TYPES,
  DISTRIBUTION_TRANSFER_TYPES,
  DistributionDetailsDownload,
  DistributionTransaction,
  distributionTransactionSchema,
} from 'types/token/tokenDistribution'

import { baseApiClient } from 'services/axiosConfig'
import { API_ENDPOINTS as API } from 'services/endpoints'
import { useInfiniteQuery } from '@tanstack/react-query'
import useDebounce from 'hooks/useDebounce'
import { validateSchema } from 'utils/zod'
import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material'
import { TransactionNote } from 'types/note'

const INFINITE_SCROLL_PAGE_SIZE = 10

type CustomTokenDistributionDetailsDialogProps = {
  handleClose: () => void
  open: boolean
  tokenId: string
  distributionId: string
  payeesCount: number | null
}

const CustomTokenDistributionDetailsDialog = ({
  handleClose,
  open,
  tokenId,
  distributionId,
  payeesCount,
}: CustomTokenDistributionDetailsDialogProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('')

  const debouncedSearchTerm = useDebounce({ value: searchTerm || '' })

  const tableContainerRef = useRef<HTMLDivElement>(null) //we can get access to the underlying TableContainer element and re

  const rowVirtualizerInstanceRef = useRef(null) //we can get access to the underlying Virtualizer instance and call its scrollToIndex method

  const {
    data: infiniteScrollData,
    isFetching,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: [
      'token-distribution-details',
      `${tokenId}`,
      `${distributionId}`,
      `${debouncedSearchTerm}`,
    ],
    queryFn: async ({ pageParam }) => {
      const response = await baseApiClient.get(API.token.distribution(distributionId), {
        params: {
          page: pageParam,
          page_size: INFINITE_SCROLL_PAGE_SIZE,
          q: debouncedSearchTerm,
        },
      })
      const data = response.data
      return validateSchema(
        distributionTransactionSchema.array(),
        data,
        'useTokenDistributionDetailsList',
      )
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      const maxPages = Math.ceil(lastPage.count / INFINITE_SCROLL_PAGE_SIZE)
      const nextPage = allPages.length + 1
      return nextPage <= maxPages ? nextPage : undefined
    },
  })

  const flatData: DistributionTransaction[] = useMemo(
    () => infiniteScrollData?.pages?.flatMap((page) => page.results) ?? [],
    [infiniteScrollData],
  )

  const totalDBRowCount = infiniteScrollData?.pages?.[0]?.count ?? 0
  const totalFetched = flatData.length

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement
        //once the user has scrolled within 60px of the bottom of the table, fetch more data if we can
        if (
          scrollHeight - scrollTop - clientHeight < 60 &&
          !isFetching &&
          totalFetched < totalDBRowCount
        ) {
          fetchNextPage()
        }
      }
    },
    [isFetching, totalFetched, totalDBRowCount, fetchNextPage],
  )

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current)
  }, [fetchMoreOnBottomReached])

  //GET DATA FOR CSV DOWNLOAD
  const { data: tokenDistributionInfoDownload, isLoading } =
    useTokenDistributionDetailsDownload<DistributionDetailsDownload>({
      tokenId,
      distributionId,
    })

  const distributionDate =
    formatDate(tokenDistributionInfoDownload?.created_at ?? '', 'MMM dd, yyyy') || ''

  const isDistributionReversed =
    tokenDistributionInfoDownload?.process_type === DISTRIBUTION_PROCESS_TYPES.Reverse

  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'user',
          header: 'Payee',
          Header: (
            <Stack direction='row' alignItems='center' gap={0.5}>
              <Typography variant='subtitle2'>Payee ({payeesCount ? payeesCount : 0})</Typography>
              {isDistributionReversed && (
                <Tooltip title='No. of unique payees' placement='top' arrow>
                  <InfoOutlinedIcon
                    sx={{
                      fontSize: 16,
                      color: 'text.secondary',
                    }}
                  />
                </Tooltip>
              )}
            </Stack>
          ),
          id: 'payee',
          Cell: ({ row }) => {
            return <Typography variant='body2'>{row.original.user}</Typography>
          },
          size: 200,
        },
        {
          accessorKey: 'txn_date',
          header: 'Transaction date',
          enableSorting: false,
          muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
            align: 'right',
          },
          size: 140,
        },
        {
          header: 'Note',
          accessorKey: 'notes',
          size: 200,
          Cell: ({ cell }) => {
            const notes = cell.getValue<TransactionNote[]>()
            return notes.length ? (
              <Tooltip placement='top' title={notes[0].text}>
                <Typography variant='body2' noWrap={true}>
                  {notes[0].text}
                </Typography>
              </Tooltip>
            ) : null
          },
        },
        {
          accessorKey: 'amount',
          header: 'Amount',
          muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
            align: 'right',
          },

          Cell: ({ row }) => {
            return (
              <Typography
                variant='body2'
                color={
                  row.original.transaction_type === DISTRIBUTION_TRANSFER_TYPES.Credit &&
                  row.original.amount > 0
                    ? 'success.main'
                    : 'inherit'
                }
              >
                {row.original.transaction_type === DISTRIBUTION_TRANSFER_TYPES.Credit &&
                row.original.amount > 0
                  ? '+'
                  : row.original.transaction_type === DISTRIBUTION_TRANSFER_TYPES.Debit
                  ? '-'
                  : ''}
                <NumericFormat value={row.original.amount} displayType={'text'} thousandSeparator />{' '}
              </Typography>
            )
          },
        },
      ] as MRT_ColumnDef<DistributionTransaction>[],
    [isDistributionReversed, payeesCount],
  )

  const downloadDistributionDetailsCSV = () => {
    if (!tokenDistributionInfoDownload) {
      return
    }

    const csvContent =
      convertDataToCSV({
        data: tokenDistributionInfoDownload.transactions.map((transaction) => {
          let amount = transaction.amount
          if (transaction.transaction_type === DISTRIBUTION_TRANSFER_TYPES.Debit) {
            amount = -transaction.amount
          }
          return {
            ...transaction,
            amount,
          }
        }),
        columnNames: ['user', 'txn_date', 'amount'],
      }) || ''

    downloadFile({ file: csvContent, name: `${distributionDate} - details list` })
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth='md'>
      <DialogTitle sx={{ pl: 4 }}>
        {tokenDistributionInfoDownload ? (
          <>
            {distributionDate}
            <Typography variant='body2' color='text.secondary'>
              <Box component='span' textTransform='capitalize'>
                {tokenDistributionInfoDownload.process_type}
              </Box>{' '}
              distribution
            </Typography>
          </>
        ) : null}
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={() => {
          setTimeout(() => {
            setSearchTerm(''), 20
          })
          handleClose()
        }}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent sx={{ px: 0, pt: 0 }}>
        {flatData ? (
          <Stack gap={2}>
            <KeeprTable
              columns={columns}
              data={flatData}
              muiTableContainerProps={{
                ref: tableContainerRef, //get access to the table container element
                sx: { maxHeight: '290px', px: 2 }, //give the table a max height
                onScroll: (event) => fetchMoreOnBottomReached(event.target as HTMLDivElement), //add an event listener to the table container element
              }}
              muiTableHeadRowProps={{
                sx: {
                  boxShadow: 'none',
                },
              }}
              muiTablePaperProps={{ elevation: 0 }}
              enableRowVirtualization={true}
              rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //get access to the virtualizer instance
              rowVirtualizerOptions={{ overscan: 4 }}
              enablePagination={false}
              enableSorting={false}
              state={{
                globalFilter: searchTerm,
                showSkeletons: isFetching,
                showProgressBars: isFetching,
              }}
              globalFilterPlaceholder='Name, email...'
              onGlobalFilterChange={setSearchTerm}
              renderToolbarInternalActions={() => (
                <Stack
                  direction='row'
                  justifyContent={{ xs: 'center', sm: 'flex-end' }}
                  gap={2}
                  mt={2}
                  mx={3}
                >
                  <LoadingButton
                    loading={isLoading}
                    loadingPosition='start'
                    disabled={!tokenDistributionInfoDownload}
                    startIcon={<FileDownloadIcon />}
                    onClick={downloadDistributionDetailsCSV}
                  >
                    csv export
                  </LoadingButton>
                </Stack>
              )}
              renderBottomToolbarCustomActions={() => (
                <Typography
                  variant='body2'
                  sx={{
                    mt: 2,
                    ml: 2,
                  }}
                >
                  Showing {totalFetched} of {totalDBRowCount} total rows.
                </Typography>
              )}
              muiBottomToolbarProps={{
                sx: {
                  justifyContent: 'flex-end',
                  px: 2,
                  border: 'none',
                  boxShadow: 'none',
                },
              }}
              muiTableBodyProps={{
                title: 'distribution details table',
              }}
            />
          </Stack>
        ) : (
          <Stack gap={2} py={2} justifyContent='center' alignItems='center' height={200}>
            <CircularProgress size={30} disableShrink />
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default CustomTokenDistributionDetailsDialog

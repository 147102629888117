import { Box, Button, Stack, Typography } from '@mui/material'
import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import { Tier, TierForm } from 'types/organization'
import { useMemo, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { API_ENDPOINTS as API } from 'services/endpoints'
import { AddOutlined as AddOutlinedIcon } from '@mui/icons-material'
import AddTierDialog from './AddTierDialog'
import ConfirmDialog from 'components/ConfirmDialog'
import DraggableTiers from './DraggableTiers'
import { LoadingButton } from '@mui/lab'
import ReassignTierDialog from './ReassignTierDialog'
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar'
import { useTiers } from 'services/api/organization'

const MAX_TIERS_NUMBER = 6

interface SetTiersProps {
  onBoarding?: boolean
  handleStepCompleted?: () => void
}

const SetTiers = ({ onBoarding = true, handleStepCompleted }: SetTiersProps) => {
  const queryClient = useQueryClient()
  const enqueueSnackbar = useEnqueueSnackbar()

  const [isAddTierDialogOpen, setIsAddTierDialogOpen] = useState<boolean>(false)
  const [isRemoveTierDialogOpen, setIsRemoveTierDialogOpen] = useState<boolean>(false)
  const [isReassignTierDialogOpen, setIsReassignTierDialogOpen] = useState<boolean>(false)

  const [selectedTier, setSelectedTier] = useState<number | null>(null)

  const { data: tiers = [] } = useTiers({
    options: {
      select: (tiers) =>
        tiers.filter((tier) => !tier.is_default).sort((a, b) => a.position - b.position),
    },
  })

  const { mutate: addTier, isPending: isAddTierPending } = useMutation<
    Tier,
    ErrorResponse,
    Partial<TierForm>
  >({
    mutationFn: (tier) => {
      if (selectedTier) {
        return baseApiClient
          .patch(API.org.tier(selectedTier), tier)
          .then((response) => response.data)
      }

      return baseApiClient.post(API.org.tierList(), tier).then((response) => response.data)
    },
    onSuccess: (addedTier) => {
      queryClient.setQueryData<Tier[] | undefined>(['tiers'], (tiers) => {
        if (!tiers) return undefined
        if (selectedTier) {
          return tiers.map((tier) => {
            if (tier.id === selectedTier) {
              return addedTier
            }
            return tier
          })
        }
        return [...tiers, addedTier]
      })

      if (selectedTier) {
        enqueueSnackbar('Tier updated successfully.', { variant: 'success' })
      } else {
        enqueueSnackbar('Tier added successfully.', { variant: 'success' })
      }

      closeEditTierDialog()
    },
    onError: (error) => {
      if (error.detail) {
        enqueueSnackbar(error.detail, { variant: 'error' })
        return
      }
      enqueueSnackbar('Something went wrong. Please try Again.', { variant: 'error' })
    },
  })

  const { mutate: removeTier, isPending: isRemoveTierPending } = useMutation<
    number,
    ErrorResponse,
    number
  >({
    mutationFn: (tierForDeleteId) =>
      baseApiClient.delete(API.org.tier(tierForDeleteId)).then((response) => response.data),
    onSuccess: (_, tierForDeleteId) => {
      queryClient.setQueryData<Tier[] | undefined>(
        ['tiers'],
        (tiers) => tiers?.filter((tier) => tier.id !== tierForDeleteId),
      )

      let successMessage = 'Tier removed successfully.'
      if (!onBoarding) {
        successMessage = 'Tier removed successfully.\nPlease review all automatic distributions.'
      }

      enqueueSnackbar(successMessage, { variant: 'success' })

      closeRemoveTierDialog()
    },
    onError: (error) => {
      if (error.detail) {
        enqueueSnackbar(error.detail, { variant: 'error' })
        return
      }
      enqueueSnackbar('Something went wrong. Please try Again.', { variant: 'error' })
    },
  })

  const { mutate: saveTiersOrder } = useMutation<Tier[], ErrorResponse, Tier[]>({
    mutationFn: async (tiers) => {
      const data = tiers.map((tier, index) => {
        return { obj_id: tier.id, position: index + 1 }
      })

      const response = await baseApiClient.patch(API.org.tierOrder(), { order: data })
      return response.data
    },
    onMutate: async (newOrderedTiers) => {
      await queryClient.cancelQueries({ queryKey: ['tiers'] })

      const previousTiers = queryClient.getQueryData(['tiers'])

      queryClient.setQueryData(['tiers'], () => newOrderedTiers)

      return { previousTiers }
    },
    onError: (error) => {
      if (error.detail) {
        enqueueSnackbar(error.detail, { variant: 'error' })
        return
      }
      enqueueSnackbar('Something went wrong. Please try Again.', { variant: 'error' })
    },
  })

  const onReorderTiers = (tiers: Tier[]) => {
    saveTiersOrder(tiers)
  }

  const onAddTier = (tier: Partial<TierForm>) => {
    addTier(tier)
  }

  const openEditTierDialog = (tierId: number) => {
    setSelectedTier(tierId)
    setIsAddTierDialogOpen(true)
  }

  const closeEditTierDialog = () => {
    setSelectedTier(null)
    setIsAddTierDialogOpen(false)
  }

  const openRemoveTierDialog = (tierId: number) => {
    setSelectedTier(tierId)
    setIsRemoveTierDialogOpen(true)
  }

  const closeRemoveTierDialog = () => {
    setSelectedTier(null)
    setIsRemoveTierDialogOpen(false)
  }

  const tier = useMemo(() => {
    return tiers.find((tier) => tier.id === selectedTier) || null
  }, [tiers, selectedTier])

  const onRemoveTier = () => {
    if (!tier) {
      return
    }

    if (tier.jobs_count) {
      setIsReassignTierDialogOpen(true)
      return
    }

    removeTier(tier.id)
  }

  return (
    <>
      <Box data-testid='set-tiers'>
        {onBoarding ? <Typography variant='h6'>Set your organization tiers</Typography> : null}
        <Typography variant='subtitle2' color='text.secondary'>
          Tiers define different levels within your team and help ensure a fair distribution of
          profits. Add or remove tiers below.
        </Typography>

        <Box mt={3} mb={2}>
          <Stack
            direction='row'
            height='56px'
            alignItems='center'
            justifyContent='space-between'
            pl={9.5}
            pr={2}
          >
            <Typography variant='subtitle2'>Name</Typography>
            <Typography variant='subtitle2'>Actions</Typography>
          </Stack>

          <DraggableTiers
            tiers={tiers}
            handleReorderTiers={onReorderTiers}
            openRemoveTierDialog={openRemoveTierDialog}
            openEditTierDialog={openEditTierDialog}
          />
        </Box>

        <Stack direction='row' alignItems='center' gap={2}>
          <Button
            variant='outlined'
            startIcon={<AddOutlinedIcon />}
            onClick={() => setIsAddTierDialogOpen(true)}
            disabled={tiers.length >= MAX_TIERS_NUMBER}
            data-testid='add-tier'
          >
            Add tier
          </Button>

          {tiers.length >= MAX_TIERS_NUMBER ? (
            <Typography variant='caption' color='text.secondary'>
              Max number of organization tiers can't be more than 6.
            </Typography>
          ) : null}
        </Stack>

        {onBoarding ? (
          <Box textAlign='right' mt={3}>
            <LoadingButton
              variant='contained'
              type='submit'
              onClick={handleStepCompleted}
              data-testid='update-tiers'
              disabled={!tiers.length}
            >
              Continue
            </LoadingButton>
          </Box>
        ) : null}
      </Box>

      {isAddTierDialogOpen ? (
        <AddTierDialog
          open={isAddTierDialogOpen}
          handleClose={closeEditTierDialog}
          handleAddTier={onAddTier}
          isLoading={isAddTierPending}
          tier={tier}
        />
      ) : null}

      {isRemoveTierDialogOpen ? (
        <ConfirmDialog
          dataTestId='remove-tiers'
          isOpen={isRemoveTierDialogOpen}
          handleClose={closeRemoveTierDialog}
          title={`Remove ${tier?.name} employment type`}
          content={
            tier?.jobs_count
              ? `The ${tier.name} tier will be removed from all distributions and ongoing distribution amounts will be updated. You will need to reassign users to an alternative tier before the tier is removed.`
              : 'Are you sure you wish to remove this tier?'
          }
          onCancel={closeRemoveTierDialog}
          cancelText='Cancel'
          data-testid='cancel-button'
          onConfirm={onRemoveTier}
          confirmText={tier?.jobs_count ? `Reassign users (${tier.jobs_count})` : 'Remove tier'}
          confirmType={tier?.jobs_count ? `primary` : 'error'}
          isLoading={isRemoveTierPending}
        />
      ) : null}

      {isReassignTierDialogOpen ? (
        <ReassignTierDialog
          open={isReassignTierDialogOpen}
          handleClose={() => setIsReassignTierDialogOpen(false)}
          tier={tier}
        />
      ) : null}
    </>
  )
}
export default SetTiers

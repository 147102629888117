import { Stack, Typography, Button, Link } from '@mui/material'
import CookieIcon from '@mui/icons-material/Cookie'
import PolicyIcon from '@mui/icons-material/Policy'
import GavelIcon from '@mui/icons-material/Gavel'

interface LegalProps {
  handleClose: () => void
}

const Legal = ({ handleClose }: LegalProps) => {
  return (
    <Stack gap={2}>
      <Typography color='text.secondary' variant='h6'>
        Keepr platform
      </Typography>

      <Stack paddingLeft={1} justifyContent='space-between' gap={2}>
        <Stack direction='row' alignItems='center' gap={1}>
          <PolicyIcon fontSize='inherit' />
          <Link
            href='https://app.termly.io/document/privacy-policy/335b86fa-8d9b-4bb5-b972-dd1f42e0c993'
            target='_blank'
            rel='noopener'
            color='text.secondary'
            underline='hover'
            data-testid='privacy-policy'
          >
            Privacy Policy
          </Link>
        </Stack>
        <Stack direction='row' alignItems='center' gap={1}>
          <CookieIcon fontSize='inherit' />
          <Link
            href='https://app.termly.io/document/cookie-policy/0a73cf7a-56ce-4aef-b4b3-713500747606'
            target='_blank'
            rel='noopener'
            color='text.secondary'
            underline='hover'
            data-testid='cookie-policy'
          >
            Cookie Policy
          </Link>
        </Stack>
        <Stack direction='row' alignItems='center' gap={1}>
          <GavelIcon fontSize='inherit' />
          <Link
            href='https://app.termly.io/document/terms-of-service/d25d19a9-1fe2-459b-8b1a-89e75eeecf56'
            target='_blank'
            rel='noopener'
            color='text.secondary'
            underline='hover'
            data-testid='terms-of-service'
          >
            Terms Of Service
          </Link>
        </Stack>
      </Stack>
      <Stack direction='row' gap={2} justifyContent='flex-end' py={1}>
        <Button onClick={handleClose} data-testid='cancel-changes'>
          Cancel
        </Button>
      </Stack>
    </Stack>
  )
}

export default Legal

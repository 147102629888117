import AuthLayout from 'auth/components/AuthLayout'
import { LoadingButton } from '@mui/lab'
import { Stack, Typography, TextField } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar'
import { useMutation } from '@tanstack/react-query'
import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import { API_ENDPOINTS as API } from 'services/endpoints'
import { z } from 'zod'
import { emailRegexExpression } from 'utils/validations'

const verificationEmailFormSchema = z.object({
  email: z.string().regex(emailRegexExpression, {
    message: 'Email is invalid.',
  }),
})

type VerificationEmailForm = z.infer<typeof verificationEmailFormSchema>

const VerificationEmailExpired = () => {
  const enqueueSnackbar = useEnqueueSnackbar()

  const { mutate: registerUser, isPending: isRegisterUserPending } = useMutation<
    unknown,
    ErrorResponse,
    string
  >({
    mutationFn: (email) =>
      baseApiClient.post(API.auth.resendEmail(), { email }).then((response) => response.data),
    onSuccess: () => {
      enqueueSnackbar('Email resent successfully, check your inbox', { variant: 'success' })
      reset()
    },
    onError: (error) => {
      enqueueSnackbar(error.detail, { variant: 'error' })
    },
  })

  const {
    handleSubmit,
    control,
    formState: { isValid },
    reset,
  } = useForm<VerificationEmailForm>({
    mode: 'onBlur',
    resolver: zodResolver(verificationEmailFormSchema),
    defaultValues: {
      email: '',
    },
  })

  const onSubmit = (data: VerificationEmailForm) => {
    registerUser(data.email)
  }

  return (
    <AuthLayout>
      <Typography variant='h5'>Verification email expired</Typography>
      <Typography variant='body2' color='text.secondary'>
        Confirm your email address and we’ll resend you an email
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack gap={3.5} mt={4}>
          <Controller
            name='email'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                label='Work email'
                type='email'
                variant='outlined'
                {...field}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />

          <LoadingButton
            variant='contained'
            size='large'
            type='submit'
            disabled={!isValid}
            loading={isRegisterUserPending}
          >
            Resend verification email
          </LoadingButton>
        </Stack>
      </form>
    </AuthLayout>
  )
}

export default VerificationEmailExpired

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { grey } from '@mui/material/colors'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { useCustomTokenUser } from 'services/api/customToken'
import { NumericFormat } from 'react-number-format'
import { useTokenDetails } from 'services/api/token'
import { CURRENCY_SYMBOL } from 'types/common'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { CUSTOM_TOKEN_TYPE_OPTIONS } from 'types/token/basicToken'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ErrorResponse, baseApiClient } from 'services/axiosConfig'
import { API_ENDPOINTS as API } from 'services/endpoints'
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar'

interface CreateIndividualPayoutDialogProps {
  tokenId: string
  userId: number
  open: boolean
  handleClose: () => void
}

const individualPayoutFormSchema = z.object({
  custom_token_user: z.number().or(z.literal('')),
  token_amount: z.number().min(1, 'Has to be greater than 0.'),
  token_value: z.number().min(1, 'Has to be greater than 0.'),
})

type IndividualPayoutFormValuesType = z.infer<typeof individualPayoutFormSchema>

const INDIVIDUAL_PAYOUT_DEFAULT_VALUES: IndividualPayoutFormValuesType = {
  custom_token_user: '',
  token_amount: 0,
  token_value: 0,
}

const CreateIndividualPayoutDialog = ({
  tokenId,
  userId,
  open,
  handleClose,
}: CreateIndividualPayoutDialogProps) => {
  const { data: customTokenUser, isLoading: isCustomTokenUserLoading } = useCustomTokenUser({
    tokenId,
    userId,
  })

  const enqueueSnackbar = useEnqueueSnackbar()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { data: customToken, isLoading: isCustomTokenLoading } = useTokenDetails({
    tokenId,
  })

  const remainingAssetAmount = customTokenUser?.balance || 0

  const { mutate: createPayout, isPending: isCreatePayoutPending } = useMutation<
    unknown,
    ErrorResponse,
    IndividualPayoutFormValuesType
  >({
    mutationFn: async (formData) => {
      return await baseApiClient.post(API.token.customTokenSinglePayout(tokenId), formData)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['custom-token-user-list', tokenId] })
      queryClient.invalidateQueries({ queryKey: ['custom-token-user', tokenId, userId] })
      queryClient.invalidateQueries({ queryKey: ['custom-token-payout-list', tokenId] })
      enqueueSnackbar('Payout successfully created.', { variant: 'success' })
      handleClose()
      navigate(`/assets/custom-asset/${tokenId}/payouts`)
    },
    onError: (error) => {
      if (error.detail) {
        enqueueSnackbar(error.detail, { variant: 'error' })
        return
      }
      enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' })
    },
  })

  const {
    handleSubmit,
    setValue,
    control,
    clearErrors,
    setError,
    formState: { isValid },
  } = useForm<IndividualPayoutFormValuesType>({
    mode: 'onBlur',
    resolver: zodResolver(individualPayoutFormSchema),
    defaultValues: INDIVIDUAL_PAYOUT_DEFAULT_VALUES,
  })

  const [tokenAmountWatch, tokenValueWatch] = useWatch({
    control,
    name: ['token_amount', 'token_value'],
  })

  const isOverBalance = tokenAmountWatch > remainingAssetAmount

  useEffect(() => {
    if (!customToken || !customTokenUser) return

    setValue('custom_token_user', customTokenUser.id, {
      shouldDirty: true,
    })

    if (customToken.custom_token_type === CUSTOM_TOKEN_TYPE_OPTIONS.IndividualRedemption) {
      setValue('token_value', Number(customToken.custom_unit_value), {
        shouldDirty: true,
      })
    }

    if (
      customToken.custom_token_type === CUSTOM_TOKEN_TYPE_OPTIONS.FixedPool &&
      Number(customToken.custom_unit_value) > 0
    ) {
      setValue('token_value', Number(customToken.custom_unit_value), {
        shouldDirty: true,
      })
    }
  }, [customTokenUser, customToken, setValue])

  useEffect(() => {
    if (isOverBalance) {
      setError('token_amount', {
        message: 'Insufficient assets.',
      })
    } else {
      clearErrors('token_amount')
    }
  }, [isOverBalance, clearErrors, setError])

  const onSubmit = (data: IndividualPayoutFormValuesType) => {
    createPayout(data)
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='Create individual payout'
      open={open}
      fullWidth={true}
      maxWidth='xs'
    >
      <DialogTitle>Individual payout</DialogTitle>

      <IconButton
        aria-label='close'
        onClick={() => {
          handleClose()
        }}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      {isCustomTokenUserLoading || isCustomTokenLoading ? (
        <DialogContent>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='200px'
            width='100%'
          >
            <CircularProgress size={30} disableShrink />
          </Box>
        </DialogContent>
      ) : (
        <DialogContent
          sx={{
            px: 3,
            py: 1,
            '&.MuiDialogContent-root': { pt: 1 },
          }}
        >
          <form noValidate>
            <Stack gap={2}>
              <Controller
                name='custom_token_user'
                control={control}
                render={({ field: { value, ...rest }, fieldState: { error } }) => {
                  return (
                    <TextField
                      fullWidth
                      value={value || ''}
                      {...rest}
                      select
                      label='User'
                      error={!!error}
                      helperText={error?.message}
                      data-testid='initial-payout-user'
                      disabled
                    >
                      <MenuItem key={customTokenUser?.id} value={customTokenUser?.id}>
                        <Stack gap={0.5}>
                          <Typography variant='body1' color='text.primary'>
                            {customTokenUser?.full_name}
                          </Typography>
                          {customTokenUser?.balance ? (
                            <Typography variant='caption' color='text.secondary'>
                              {customTokenUser?.balance}{' '}
                              {customTokenUser?.balance > 1 ? 'assets' : 'asset'}
                            </Typography>
                          ) : null}
                        </Stack>
                      </MenuItem>
                    </TextField>
                  )
                }}
              />
              <Stack direction='row' gap={2}>
                <Controller
                  control={control}
                  name='token_amount'
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <NumericFormat
                        customInput={TextField}
                        label='Assets to payout'
                        value={value}
                        onValueChange={(value) => onChange(value.floatValue || 0)}
                        onBlur={onBlur}
                        error={!!error || isOverBalance}
                        helperText={error?.message || `Assets available: ${remainingAssetAmount}`}
                        thousandSeparator
                        allowNegative={false}
                        fullWidth
                        required
                        data-testid='individual-payout-assets-to-payout'
                      />
                    )
                  }}
                />

                <Controller
                  control={control}
                  name='token_value'
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <NumericFormat
                      customInput={TextField}
                      label='Asset value'
                      value={value}
                      onValueChange={(value) => onChange(value.floatValue || '')}
                      onBlur={onBlur}
                      error={!!error}
                      helperText={
                        customToken?.custom_token_type ===
                        CUSTOM_TOKEN_TYPE_OPTIONS.IndividualRedemption
                          ? 'Assigned at asset creation.'
                          : error?.message
                      }
                      disabled={
                        customToken?.custom_token_type ===
                        CUSTOM_TOKEN_TYPE_OPTIONS.IndividualRedemption
                      }
                      thousandSeparator
                      allowNegative={false}
                      decimalScale={2}
                      fullWidth
                      required
                      prefix={customToken ? CURRENCY_SYMBOL[customToken.currency] : ''}
                      data-testid='individual-payout-asset-value'
                    />
                  )}
                />
              </Stack>
            </Stack>
          </form>
          <Stack direction='row' gap={2} py={3} justifyContent='space-between'>
            <Typography variant='h6' color='text.secondary'>
              Total payout
            </Typography>
            <Typography variant='h6' color='text.secondary'>
              <Box
                component={'span'}
                sx={{
                  width: '180px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'inline-block',

                  textAlign: 'right',
                }}
              >
                {' '}
                {customToken ? CURRENCY_SYMBOL[customToken.currency] : ''}
                <NumericFormat
                  value={isOverBalance ? 0 : tokenAmountWatch * tokenValueWatch}
                  displayType='text'
                  thousandSeparator
                  decimalScale={2}
                />
              </Box>
            </Typography>
          </Stack>
        </DialogContent>
      )}

      <DialogActions>
        <Stack direction='row' gap={2} px={2} py={1}>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            variant='contained'
            disabled={!isValid || isOverBalance}
            loading={isCreatePayoutPending}
            onClick={handleSubmit(onSubmit)}
          >
            Create payout
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default CreateIndividualPayoutDialog
